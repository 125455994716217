import i18n from "constants/locales/i18n";

const dbAdmin = {
  rule: {
    R: {
      label: i18n.t("common.root_admin"),
      value: "R",
      key: "R",
    },
    A: {
      label: i18n.t("common.admin"),
      value: "A",
      key: "A",
    },
  },
  allow_ip_yn: {
    Y: {
      label: i18n.t("common.use_y"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.use_n"),
      value: "N",
      key: "N",
    },
  },
  state: {
    N: {
      label: i18n.t("common.normal"),
      value: "N",
      key: "N",
    },
    B: {
      label: i18n.t("common.block"),
      value: "B",
      key: "B",
    },
  },
};

export { dbAdmin };
