import useFormData from "core/hooks/useFormData";
import useStateData from "core/hooks/useStateData";
import { EachCheckForm, InputForm, NoLabelCheckForm, SelectForm, TextareaForm } from "components/common/CommonForm";
import { useCommonState } from "core/contexts/useCommonState";
import { useAlert, usePopup } from "core/contexts/useWindow";
import api from "core/services/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, FilterBox, FormBox, Row } from "styles/custom/useCustomLayout";
import { MainBtn, RedBtn, WhiteBtn } from "styles/useButtonStyles";
import { IconLink, PositionBox } from "styles/useLayoutStyles";
import { TextBox } from "styles/useTextStyles";
import useTableData from "core/hooks/useTableData";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import { FilterTitle } from "styles/custom/useCustomText";
import { useCountPerPage, userState1 } from "constants/select/useValue";
import UserLevelFilter from "components/common/UserLevelFilter";
import { checkboxYNEnum } from "constants/enum/useCommonEnum";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import AgentNameTab from "components/common/AgentNameTab";
import UserState2Hover from "components/common/UserState2Hover";
import UserNameTab from "components/common/UserNameTab";
import { dbUser } from "constants/enum/db/useUserEnum";
import UserLevelTab from "components/common/UserLevelTab";
import { getFormatDatetime } from "utils/date/DateUtil";
import NoDataTable from "components/common/NoDataTable";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";

const PointManagementSelectUserForm = ({ isPayment, getTableItem }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();
  const [selectedUserList, setSelectedUserList] = useState([]);
  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showPointPaymentOrRetrievalFail(item.msg.code);
      }
    });
  }, [useStated.errors]);


  const showPointPaymentOrRetrievalFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showPointAlert = (isSuccess) => {
    if (isPayment) {
      openAlert({
        title: isSuccess ? t("point.payment_complete") : t("point.payment_fail"),
        message: isSuccess ? t("point.payment_complete_msg") : t("point.payment_fail_msg"),
        iconURL: isSuccess ? false : true,
      });
    } else {
      openAlert({
        title: isSuccess ? t("point.retrieval_complete") : t("point.retrieval_fail"),
        message: isSuccess ? t("point.retrieval_complete_msg") : t("point.retrieval_fail_msg"),
        iconURL: isSuccess ? false : true,
      });
    }
  };

  /**
   * 포인트 (지급 / 회수)
   */
  const pointPaymentOrRetrieval = () => {
    if (selectedUserList.length === 0) {
      openAlert({
        title: t("point.no_seleted_user"),
        iconURL: true,
        mainBtn: t("common.check"),
      });
      return;
    }

    setLoading(true);

    let apiUrl = isPayment ? `/v1/inout/point-paid/payment` : "/v1/inout/point-paid/retrieval";
    api
      .put(apiUrl, {
        user_no_arr: selectedUserList.map((item) => item.no),
        point: useForm.formValue.point,
        memo: useForm.formValue.memo,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showPointAlert(true);
        closePopup();
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showPointAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTableItem2 = (isRefresh = false) => {
    const apiUrl = "/v1/cs-management/send-message";

    api
      .get(apiUrl, {
        params: {
          ...useSearchForm.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        if (isRefresh) {
          useTG.setData(res.data.content)
        } else {
          useTG.setData(res.data.content
            .map((item) => { return { ...item, isChecked: selectedUserList.some((user) => user.no === item.no), } })
          );
        }
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);

        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({
    point: 0,
    memo: "",
    receiver_user_list: [],
    receiver_nickname_list_to_string: [],
  });

  const useTG = useTableData(true);

  const useSearchForm = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_state1: "",

      search_manage_user_yn: "Y",
    },
    getTableItem2,
    useTG
  );

  const updatePoint = (amount) => {
    let currentPoint = useForm.formValue.point;

    if (typeof currentPoint !== 'number') {
      currentPoint = parseInt(currentPoint, 10);

      if (isNaN(currentPoint)) {
        currentPoint = 0;
      }
    }

    const newPoint = isPayment ? currentPoint + amount : currentPoint - amount;

    useForm.setFormValue({
      ...useForm.formValue,
      point: newPoint,
    });
  };

  const resetPoint = () => {
    useForm.setFormValue({
      ...useForm.formValue,
      point: 0,
    });
  };

  const selectUser = () => {
    if (useTG.data == null) {
      return;
    }

    // 현재 페이지에서 체크된 항목들
    const checkedList = useTG.data.filter((item) => item.isChecked);

    setSelectedUserList((prev) => {
      const notInCurrentPage = prev.filter(prevItem =>
        !useTG.data.some(dataItem => dataItem.no === prevItem.no)
      );

      return [...notInCurrentPage, ...checkedList].sort((a, b) => {
        return Number(a.no) - Number(b.no);
      });
    });
  };

  // 테이블 데이터 갱신 시, Checked 항목 처리
  useEffect(() => {
    selectUser();
  }, [useTG.data]);

  return (
    <Row $gap="12px">
      <Column $width="800px">
        <FilterBox $pad="12px">
          <Column $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearchForm} useStated={useStated} $mar="0 24px 0 0 " />

            <Row $gap="8px">
              <Column $gap="4px">
                <FilterTitle>{t("sendMessage.user")}</FilterTitle>
                <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearchForm} />
              </Column>
              <Column $gap="4px">
                <FilterTitle>{t("sendMessage.user_status")}</FilterTitle>
                <SelectForm
                  name="search_user_state1"
                  placeholder={t("common.all_state")}
                  options={userState1}
                  {...useSearchForm}
                  {...useStated}
                />
              </Column>
              <UserLevelFilter useSearch={useSearchForm} useStated={useStated} />
              <Row $pad="28px 0 0 0">
                <EachCheckForm
                  $size="var(--s-caption)"
                  name="search_manage_user_yn"
                  labelFor="search_manage_user_yn"
                  options={checkboxYNEnum}
                  labelName={t("filter.include_manage_user")}
                  {...useSearchForm}
                />
              </Row>
            </Row>
          </Column>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearchForm.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearchForm.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
          <Row $gap="10px" $align="center">
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" $width="66px"
              onClick={() => {
                setSelectedUserList([])
                getTableItem2(true)
              }}>
              {t("filter.refresh")}
            </RedBtn>
          </Row>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>

        <Table className="table-box">
          <thead>
            <tr className="table-title">
              <TableHeader $width="34px">
                <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
              </TableHeader>
              <TableHeader width="160px">{t("sendMessage.agent")}</TableHeader>
              <TableHeader width="160px">{t("sendMessage.user_id")}</TableHeader>
              <TableHeader width="60px">{t("sendMessage.user_level")}</TableHeader>
              <TableHeader width="100px">{t("sendMessage.user_status")}</TableHeader>
              <TableHeader width="180px">{t("sendMessage.last_login_date")}</TableHeader>
            </tr>
          </thead>
          <tbody>
            {useTG.data &&
              useTG.data.map((item, index) => (
                <TableRow key={item.no} className="table-content">
                  <TableDeps>
                    <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                  </TableDeps>
                  <TableDeps>
                    <AgentNameTab
                      no={item.agent_no}
                      code={item.agent_code}
                      name={item.agent_name}
                      type_name={item.type_name}
                    />
                  </TableDeps>
                  <TableDeps>
                    <Row $gap="6px" $align="center">
                      <UserState2Hover state2List={item.state2_arr} />
                      <UserNameTab no={item.no} nickname={item.nickname} />
                    </Row>
                  </TableDeps>

                  <TableDeps>
                    <UserLevelTab item={item} />
                  </TableDeps>
                  <TableDeps>
                    <TextBox
                      className={
                        item.state1 === dbUser.state1.S.value
                          ? "complete"
                          : item.state1 === dbUser.state1.B.value
                            ? "warn"
                            : item.state1 === dbUser.state1.O.value
                              ? "withdraw"
                              : item.state1 === dbUser.state1.W.value
                                ? "normal"
                                : item.state1 === dbUser.state1.T.value
                                  ? "test"
                                  : ""
                      }
                    >
                      {dbUser.state1[item.state1]?.label || "-"}
                    </TextBox>
                  </TableDeps>
                  <TableDeps>{getFormatDatetime(item?.last_login_at)}</TableDeps>
                </TableRow>
              ))}
          </tbody>
        </Table>
        {useTG && useTG.data?.length === 0 && <NoDataTable />}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" $width="66px"
              onClick={() => {
                setSelectedUserList([])
              }}>
              {t("filter.refresh")}
            </RedBtn>
          </Row>
          <PositionBox $position="absolute" $left="30%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              top="auto"
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Column>
      <Column className="area-box" $gap="20px" $width="450px">
        <FilterBox $pad="12px">
          <FormBox $align="flex-start">
            <IconLink
              $url={!isPayment ? "/assets/svg/warnicon.svg" : "/assets/svg/infoicon.svg"}
              $width="24px"
              $height="24px"
            ></IconLink>
            {selectedUserList.length > 0 ? (
              <TextBox>
                {isPayment
                  // 지급
                  ? t("point.point_payment_single_confirm", { nickName: selectedUserList.map((item) => `${item.nickname}(${item.no})`).join(", ") })
                  : t("point.point_retrieval_single_confirm", { nickName: selectedUserList.map((item) => `${item.nickname}(${item.no})`).join(", ") })}
              </TextBox>
            ) : (
              <TextBox>
                {t("point.no_seleted_user")}
              </TextBox>
            )}
          </FormBox>
        </FilterBox>

        <FormBox $align="flex-start">
          <InputForm
            name="point"
            type="number"
            tAlign="right"
            width="100%"
            maxWidth="100%"
            placeholder={`${isPayment ? t("point.payment") : t("point.retrieval")} ${t("point.point")}`}
            {...useForm}
            {...useStated}
          />
        </FormBox>

        <Row $gap="8px" $jus="flex-end" $width="100%">
          <WhiteBtn $height="36px" onClick={() => updatePoint(1000)}>
            {`${isPayment ? "+" : "-"} 1,000(P)`}
          </WhiteBtn>
          <WhiteBtn $height="36px" onClick={() => updatePoint(5000)}>
            {`${isPayment ? "+" : "-"} 5,000(P)`}
          </WhiteBtn>
          <WhiteBtn $height="36px" onClick={() => updatePoint(10000)}>
            {`${isPayment ? "+" : "-"} 10,000(P)`}
          </WhiteBtn>
          <WhiteBtn $height="36px" onClick={() => updatePoint(50000)}>
            {`${isPayment ? "+" : "-"} 50,000(P)`}
          </WhiteBtn>
          <WhiteBtn $height="36px" onClick={() => resetPoint()}>
            <IconLink $url='/assets/svg/reset_icon.svg' $width='20px' $height='20px'></IconLink>
          </WhiteBtn>
        </Row>

        <FormBox $align="flex-start">
          <Column $width="100%" $gap="4px">
            <TextareaForm
              type="text"
              name="memo"
              placeholder={`${isPayment ? t("point.payment") : t("point.retrieval")} ${t("point.reason_input")}`}
              height="200px"
              {...useForm}
              {...useStated}
            />
          </Column>
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={pointPaymentOrRetrieval}>
              {t("common.check")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </Row>
  );
};

export default PointManagementSelectUserForm;
