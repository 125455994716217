import useFormData from "core/hooks/useFormData";
import { TextareaForm } from "components/common/CommonForm";
import React, { useEffect } from "react";
import { Column, Row } from "styles/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { PositionBox } from "styles/useLayoutStyles";
import { TextBox } from "styles/useTextStyles";

import api from "core/services/api";
import { useAlert, usePopup } from "core/contexts/useWindow";
import useStateData from "core/hooks/useStateData";
import { useCommonState } from "core/contexts/useCommonState";
import { useTranslation } from "react-i18next";

const BlockUserAgentChangeForm = ({ isCheckBox, userArr, getTableItem }) => {
  const { setLoading } = useCommonState();

  const useStated = useStateData();
  const { openAlert } = useAlert();

  const { closePopup } = usePopup();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const updateAgentBlockState = () => {
    setLoading(true);
    const apiUrl = `v1/user-agent-change/block`;
    api
      .put(apiUrl, {
        ...useForm.formValue,
        no_arr: userArr.map((item) => item.no),
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showBlockStateSuccessAlert();
        closePopup();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          showBlockStateFailAlert(true);
          useStated.setErrorData(err.response.data.errors, true);
        } else {
          showBlockStateFailAlert();
        }
      })
      .finally(() => {
        setLoading(false);
        getTableItem();
      });
  };

  const showBlockStateSuccessAlert = () => {
    openAlert({
      title: t("user.block_user_agent_complete_msg"),
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showBlockStateFailAlert = (value = false) => {
    openAlert({
      title: t("user.block_user_agent_fail_msg"),
      message: value ? t("user.enter_block_reason_plh") : t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const useForm = useFormData({
    memo: "",
  });

  return (
    <>
      <Column className="area-box" $gap="20px">
        <TextBox>
          {isCheckBox
            ? t("user.agent_change_block_title_arr", { count: userArr.length })
            : t("user.agent_change_block_title_single", { id: userArr[0]?.nickname })}
        </TextBox>
        <TextBox>{t("user.block_memo")}</TextBox>
        <Column $width="100%" $gap="4px">
          {!useForm.formValue.block_reason ? (
            <TextareaForm
              type="text"
              name="memo"
              placeholder={t("user.enter_block_reason_plh")}
              height="200px"
              {...useForm}
              {...useStated}
            />
          ) : (
            ""
          )}
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={updateAgentBlockState}>
              {t("common.check")}
            </MainBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default BlockUserAgentChangeForm;
