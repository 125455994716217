import i18n from "constants/locales/i18n";

const dbWithdrawal = {
  reject_type: {
    0: {
      label: i18n.t("withdrawal.none"),
      value: "",
      key: 0,
    },
    1: {
      label: i18n.t("withdrawal.withdrawal_reject_type_lack_bet"),
      value: i18n.t("withdrawal.withdrawal_reject_type_lack_bet"),
      key: 1,
    },
    2: {
      label: i18n.t("withdrawal.withdrawal_reject_type_suspicious"),
      value: i18n.t("withdrawal.withdrawal_reject_type_suspicious"),
      key: 2,
    },
    3: {
      label: i18n.t("withdrawal.withdrawal_reject_type_lack_bet_count"),
      value: i18n.t("withdrawal.withdrawal_reject_type_lack_bet_count"),
      key: 3,
    },
    4: {
      label: i18n.t("withdrawal.withdrawal_reject_type_invalid_account"),
      value: i18n.t("withdrawal.withdrawal_reject_type_invalid_account"),
      key: 4,
    },
    5: {
      label: i18n.t("withdrawal.other"),
      value: i18n.t("withdrawal.other"),
      key: 5,
    },
  },
};

export { dbWithdrawal };
