import React, { useState, useEffect } from "react";

import Pagination from "utils/Pagination";
import api from "core/services/api";
import useStyle from "styles/useStyle";
import { useNavigate } from "react-router-dom";

const InquiryPage = () => {
  const navigate = useNavigate();
  const {
    FlexBox,
    TextBox,
    Table,
    TableHeader,
    TableDeps,
    InputTextBox,
    MainBtn,
    RedBtn,
  } = useStyle();
  const [inquiryList, setInquiryList] = useState([]);
  const [pagerHelper, setPagerHelper] = useState();
  const [searchUserNo, setSearchUserNo] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  let getParameter = (key) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  };

  const userNo = getParameter("userNo");

  const getUserInfoNo = (e) => {
    const value = e.target.value;
    const onlyNumbers = /^\d*$/; // 숫자만 허용하는 정규식

    if (onlyNumbers.test(value) && value.length <= 10) {
      setSearchUserNo(value);
    }
  };

  const submitDeleteAnswer = (inquiryNo) => {
    const deleteInquiryUrl = `/v1/api/admin/inquiry/delete-answer/${inquiryNo}`;
    api
      .put(deleteInquiryUrl)
      .then((res) => {
        const updatedList = inquiryList.map((inquiry) => {
          if (inquiry.inquiryNo === inquiryNo) {
            return {
              ...inquiry,
            };
          }
          return inquiry;
        });

        setInquiryList(updatedList);

        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    api
      .get("/v1/api/admin/inquiry/list", {
        params: {
          userNo: userNo,
          page: currentPage,
        },
      })
      .then((res) => {
        setInquiryList(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
      })
      .catch((error) => {
        alert("문의사항 정보를 불러오지 못했습니다.");
        console.error(error);
      });
  }, [currentPage, userNo]);

  const handleInquiryList = (page) => {
    api
      .get("/v1/api/admin/inquiry/list", {
        params: {
          userNo: searchUserNo || userNo,
          page: page,
          countPerPage: pagerHelper.countPerPage,
        },
      })

      .then((res) => {
        setInquiryList(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
        localStorage.setItem("currentPage", page);
      })

      .catch((error) => {
        alert("문의사항 정보를 불러오지 못했습니다.");
        console.error(error);
      });
  };

  return (
    <>
      <FlexBox $direct="column" $gap="12px">
        <FlexBox $jus="space-between" $align="flex-start">
          <TextBox>문의사항 리스트</TextBox>
          <FlexBox $gap="12px">
            <InputTextBox
              placeholder="유저 번호"
              value={searchUserNo}
              onChange={getUserInfoNo}
              $maxWidth="160px"
              $height="40px"
            />
            <MainBtn
              onClick={() => handleInquiryList(1)}
              $height="40px"
              $width="60px"
            >
              검색
            </MainBtn>
          </FlexBox>
        </FlexBox>
        {/* 검색 후 텍스트 hide 테이블 show */}
        <Table className="table-box">
          <thead>
            {/* WIDTH는 고정하지 않은 상태 아직 텍스트 관련 내용없음. */}
            <tr className="table-title">
              <TableHeader>유저번호</TableHeader>
              <TableHeader>질문번호</TableHeader>
              <TableHeader>문의내용</TableHeader>
              <TableHeader>문의시간</TableHeader>
              <TableHeader>답변내용</TableHeader>
              <TableHeader>답변시간</TableHeader>
              <TableHeader $width="160px">작성</TableHeader>
            </tr>
          </thead>
          <tbody>
            {/* tr 맵 돌리기 */}
            {inquiryList.map((inquiry) => {
              const toggleAnswer = () => {
                if (inquiry.answer) {
                  navigate(`/inquiry/modify/${inquiry.inquiryNo}`);
                } else {
                  navigate(`/inquiry/write/${inquiry.inquiryNo}`);
                }
              };
              return (
                <tr key={inquiry.inquiryNo} className="table-content">
                  <TableDeps>{inquiry.userNo}</TableDeps>
                  <TableDeps>{inquiry.inquiryNo}</TableDeps>
                  <TableDeps>
                    {inquiry.inquiry
                      ? inquiry.inquiry.slice(0, 10) + "..."
                      : ""}
                  </TableDeps>
                  <TableDeps>{inquiry.inquiryAtFormat}</TableDeps>
                  <TableDeps>
                    {inquiry.answer ? inquiry.answer.slice(0, 30) + "..." : ""}
                  </TableDeps>
                  <TableDeps>{inquiry.answerAtFormat}</TableDeps>
                  <TableDeps>
                    <FlexBox $jus="center" $align="center" $gap="12px">
                      <MainBtn $width="60px" onClick={toggleAnswer}>
                        {inquiry.answer ? "수정하기" : "답변하기"}
                      </MainBtn>

                      {inquiry.answer && (
                        <RedBtn
                          $width="60px"
                          onClick={() => submitDeleteAnswer(inquiry.inquiryNo)}
                        >
                          답변삭제
                        </RedBtn>
                      )}
                    </FlexBox>
                  </TableDeps>
                </tr>
              );
            })}
          </tbody>
        </Table>
        {pagerHelper && (
          <FlexBox $align="center" $jus="center" $width="100%">
            <Pagination
              pagerHelper={pagerHelper}
              onPageChange={handleInquiryList}
            />
          </FlexBox>
        )}
      </FlexBox>
    </>
  );
};

export default InquiryPage;
