import useFormData from 'core/hooks/useFormData';
import useStateData from 'core/hooks/useStateData';
import useTableData from 'core/hooks/useTableData';
import AgentNameTab from 'components/common/AgentNameTab';
import { InputForm, NoLabelCheckForm, SelectForm } from 'components/common/CommonForm';
import NoDataTable from 'components/common/NoDataTable';
import { BlockBtn, ModifyBtn } from 'components/common/TableStatus';
import { useCountPerPage } from 'constants/select/useValue';
import { useCommonState } from 'core/contexts/useCommonState';
import { dbSiteEventMng } from 'constants/enum/db/useSiteEnum';
import { dbUserHistoryAccount } from 'constants/enum/db/useUserEnum';
import { t } from 'i18next';
import api from 'core/services/api';
import React from 'react'
import { AreaHeader, Column, FilterBox, Row } from 'styles/custom/useCustomLayout';
import { FilterTitle, } from 'styles/custom/useCustomText';
import { MainBtn, RedBtn, WhiteBtn } from 'styles/useButtonStyles';
import { IconLink, PositionBox, } from 'styles/useLayoutStyles';
import { Table, TableDeps, TableHeader, TableRow } from 'styles/useTableStyles';
import { AreaTitle, SubText, TextBox } from 'styles/useTextStyles';
import InsertEventForm from './InsertEventForm';
import { useAlert, useConfirm, usePopup } from 'core/contexts/useWindow';
import Tooltip from 'components/common/Tooltip';
import { isEmpty } from 'lodash';
import Pagination from 'utils/Pagination';
import ImgPopup from './ImgPopup';


const NomalEvent = () => {
    const useStated = useStateData();
    const { setLoading } = useCommonState();
    const { openAlert } = useAlert();
    const { openConfirm } = useConfirm();
    // 데이터 가지고 오기
    const getTableItem = () => {
        setLoading(true);
        console.log({ ...useSearch.searchValue });
        const apiType = api.get;
        const apiUrl = "/v1/event-management";
        apiType(apiUrl, {
            params: {
                ...useSearch.searchValue,
                ...useTG.extendData.pagingData,
                sortNm: useTG.extendData.sortNm,
                type : 'N'
            },
        })
            .then((res) => {
                useTG.setData(res.data.content.map((data) => { return { ...data, bonus_arr: JSON.parse(data.bonus_array) } }));
                if (res.data.pageHelperDto) {
                    useTG.setPagingInfo(res.data.pageHelperDto);
                }
                setLoading(false);
                useTG.setError("");
            })
            .catch((err) => {
                console.error(err);
                useTG.setError(true);
                setLoading(false);
                useTG.setData([]);
                if (err.response?.status === 400) {
                    useStateData.setErrorData(err.response.data.errors, true);
                }
            });
    };


    const { openPopup } = usePopup();
    /**
     * 신규 이벤트 등록 팝업
     */
    const showNewEventPopup = (rowData, state) => {
        openPopup({
            title: state === "insert" ? t("event.event_insert") : t("event.event_update"),
            content: InsertEventForm,
            props: {
                rowData: rowData ? { ...rowData, bonus_arr: JSON.parse(rowData.bonus_array), popup: rowData.dispay_region[0], event_notice: rowData.dispay_region[1], main_notice: rowData.dispay_region[2]} : null,
                state,
                type : 'N',
                getTableItem,
            },
        });
    };

    // 체크 Event_No 추출
    const getCheckedHistoryArr = () => {
        return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.no, state: item.state }));
    };

    // 이벤트 활성화
    const eventActive = (state) => {
        const eventListArray = getCheckedHistoryArr();

        const eventNoList = eventListArray.map((item) => item.no);

        setLoading(true);
        const apiUrl = `/v1/event-management/activeEvent`;
        api
            .put(apiUrl, {
                eventList: eventNoList,
                active: state ? "Y" : "N",
            })
            .then((res) => {
                useStated.setErrorData([], true);
                showEventOnAlert(true);
                getTableItem();
            })
            .catch((err) => {
                if (err.response?.status === 400) {
                    useStated.setErrorData(err.response.data.errors, true);
                }
                showEventOnAlert(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    // 이벤트 삭제
    const eventDelete = () => {
        const eventListArray = getCheckedHistoryArr();

        const params = new URLSearchParams();
        const eventNoList = eventListArray.map((item) => item.no);
        eventNoList.forEach((no) => params.append("list", no));
        setLoading(true);
        const apiUrl = `/v1/event-management/deleteEvent`;
        api
            .delete(`${apiUrl}?${params.toString()}`, {
                eventList: eventNoList,
            })
            .then((res) => {
                showEventOnAlert(true);
                getTableItem();
            })
            .catch((err) => {
                showEventOnAlert(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    const showEventConfirm = (state) => {
        const eventListArray = getCheckedHistoryArr();
        if (eventListArray.length === 0) {
            return;
        }

        openConfirm({
            title: state == 'active' ? t("event.event_on")
                :
                state == 'inactive' ? t("event.event_off")
                    :
                    t("event.event_delete"),
            message: state == 'active' ? t("event.event_on_confirm")
                :
                state == 'inactive' ? t("event.event_off_confirm")
                    :
                    t("event.event_delete_confirm"),
            iconURL: false,
            mainBtn: t("common.check"),
            Func: () =>
                state == 'active' ? eventActive(true)
                    :
                    state == 'inactive' ? eventActive(false)
                        :
                        eventDelete(),
        });
    };


    const showEventOnAlert = (isSuccess) => {
        openAlert({
            title: isSuccess ? t("event.event_update_success") : t("event.event_update_fail"),
            message: isSuccess ? t("event.event_success_msg") : t("event.event_fail_msg"),
            iconURL: isSuccess ? false : true,
        });
    };

    const useTG = useTableData(true);
    const useSearch = useFormData(
        {
            search_title: "",
            search_active: "",

        },
        getTableItem,
        useTG
    );

    return (
        <>
            <AreaHeader>
                <SubText>{t("menu.event_manager")}</SubText>
                <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
                <AreaTitle>{t("menu.charge_event_manager")}</AreaTitle>
            </AreaHeader>
            <Column $gap="8px" >
                <FilterBox>
                    {/* 활성화 상태 */}
                    <Row $gap="4px">
                        <Column $gap="4px">
                            <FilterTitle>{t("event.event_active")}</FilterTitle >
                            <SelectForm
                                {...useSearch}
                                {...useStated}
                                name="search_active"
                                placeholder={dbSiteEventMng.active_yn.all.label}
                                size={"var(--s-table)"}
                                line={"var(--l-table)"}
                                options={dbSiteEventMng.active_yn}
                            />
                        </Column>

                        {/* 검색 창 */}
                        <Column $gap="4px">
                            <FilterTitle>{t("event.name")}</FilterTitle >
                            <InputForm type="text" name="search_title" placeholder={t("common.input")} {...useSearch} />
                        </Column>
                    </Row>

                    <Row $gap="4px">
                        {/* 검색 버튼*/}
                        <MainBtn $width="80px" $height="34px" onClick={() => {
                            useSearch.formSearch();
                        }}>
                            {t("filter.search")}
                        </MainBtn>
                        {/* 초기화 */}
                        <WhiteBtn $width="80px" $height="34px" onClick={() => {
                            useSearch.resetForm();
                        }}>
                            {t("filter.refresh")}
                        </WhiteBtn>
                    </Row>

                </FilterBox>
                <Row $jus="space-between"  >
                    <Row $gap="8px" $align="flex-end">
                        {/* 이벤트 활성화 */}
                        <MainBtn $width="150px" $height="34px" onClick={() => { showEventConfirm("active") }}>
                            {t("event.event_on")}
                        </MainBtn>
                        {/* 이벤트 비활성화 */}
                        <RedBtn $size="var(--s-table)" $line="var(--l-table)" $width="150px" $height="34px" onClick={() => { showEventConfirm("inactive") }}>
                            {t("event.event_off")}
                        </RedBtn>
                        {/* 이벤트 삭제 */}
                        <RedBtn $size="var(--s-table)" $line="var(--l-table)" $width="150px" $height="34px" onClick={() => { showEventConfirm("delete") }}>
                            {t("event.event_delete")}
                        </RedBtn>
                    </Row>

                    <Row $gap="8px" $align="flex-end">


                        {/* 이벤트 등록 */}
                        <MainBtn $width="150px" $height="34px" onClick={() => {
                            showNewEventPopup(null, "insert")
                        }}>
                            {t("event.event_insert")}
                        </MainBtn>

                        {/* 검색 개수 */}
                        <SelectForm
                            {...useTG}
                            name="countPerPage"
                            placeholder={useCountPerPage[0].label}
                            size={"var(--s-table)"}
                            line={"var(--l-table)"}
                            options={useCountPerPage}
                            formValue={useTG.value}
                        />
                    </Row>
                </Row>

                {/* 테이블 ROW  더미 */}
                {useTG.error ? (
                    <span>error</span>
                ) : (
                    <>
                        <Table className="table-box" >
                            <thead>
                                <tr className="table-title">
                                    <TableHeader rowSpan={2} >
                                        <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                                    </TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.no")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.name")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.img")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.startDate")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.endDate")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.detail")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.minMax_Price")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.max_payment")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.content")}</TableHeader>
                                    <TableHeader colSpan={4}>{t("event.rolling_condition")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.bonus_type")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.bonus_detail")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.active_yn")}</TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.memo")}</TableHeader>
                                    <TableHeader rowSpan={2}>
                                        <Tooltip text={t("event.display_region")}>
                                            <TextBox $size="var(--s-table)" $weight="bold">{t("event.notice_yn")}</TextBox>
                                        </Tooltip>
                                    </TableHeader>
                                    <TableHeader rowSpan={2}>{t("event.manage")}</TableHeader>
                                </tr>
                                <tr>
                                    <TableHeader>{t("event.casino")}</TableHeader>
                                    <TableHeader>{t("event.slot")}</TableHeader>
                                    <TableHeader>{t("event.mini_game")}</TableHeader>
                                    <TableHeader>{t("event.sports")}</TableHeader>
                                </tr>
                            </thead>
                            {useTG && useTG.data?.length > 0 && (
                                <tbody>
                                    {useTG.data &&
                                        useTG.data.map((item, index) => {
                                            const number = useTG.pagingInfo.offset - index;
                                            return (
                                                <TableRow key={number} className="table-content" $height="100%">
                                                    <TableDeps>
                                                        <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                                                    </TableDeps>
                                                    <TableDeps $width="60px">{number || "-"}</TableDeps>
                                                    <TableDeps $tAlign="left">{item.title || "-"}</TableDeps>
                                                    <TableDeps $tAlign="center">

                                                        {item.img ?
                                                            <Tooltip text={<img src={process.env.REACT_APP_IMG_URL + item.img} height="150px" />}>
                                                                <TextBox $color="var(--c-blue)" $size="var(--s-table)" $weight="bold"
                                                                    onClick={() => {
                                                                        openPopup({
                                                                            title: t("event.img"),
                                                                            content: ImgPopup,
                                                                            props: {
                                                                                src: process.env.REACT_APP_IMG_URL + item.img
                                                                            }
                                                                        });
                                                                    }}
                                                                >{"보기"}</TextBox>
                                                            </Tooltip>
                                                            :
                                                            null
                                                        }
                                                    </TableDeps>
                                                    <TableDeps $tAlign="center">{item.start_datetime ? item.start_datetime.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : "-"}</TableDeps>
                                                    <TableDeps $tAlign="center">{item.end_datetime ? item.end_datetime.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') : "-"}</TableDeps>
                                                    <TableDeps $tAlign="center">
                                                        <Tooltip text={
                                                            <Column $width="200px">
                                                                {item.condition_day_YN == 'Y' && (
                                                                    <Row $jus="space-between">
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">{t("event.day") + " : "}</TextBox>
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                                                                            {item.condition_day.split('').map((day, index) => (
                                                                                <>
                                                                                    {day == 'Y' ? dbSiteEventMng.day[index]?.label : null}
                                                                                </>
                                                                            ))}
                                                                        </TextBox>
                                                                    </Row>
                                                                )}

                                                                {item.condition_time_YN == 'Y' && (
                                                                    <Row $jus="space-between">
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">{t("event.time") + " : "}</TextBox>
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                                                                            {item.condition_endless == "Y" ?
                                                                                t("event.no_end_time")
                                                                                :
                                                                                `${item.condition_start_time} ~ ${item.condition_end_time}`
                                                                            }
                                                                        </TextBox>
                                                                    </Row>
                                                                )}

                                                                {item.condition_deposit_YN == 'Y' && (
                                                                    <Row $jus="space-between">
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">{t("event.accumulate_deposit") + " : "}</TextBox>
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                                                                            {item.condition_deposit_nolimit == "Y" ?
                                                                                t("event.no_limit")
                                                                                :
                                                                                `${item.condition_min_deposit.toLocaleString()} ~ ${item.condition_max_deposit.toLocaleString()}`
                                                                            }
                                                                        </TextBox>
                                                                    </Row>
                                                                )}

                                                                {item.condition_withdrawal_YN == 'Y' && (
                                                                    <Row $jus="space-between">
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">{t("event.accumulate_withdrawal") + " : "}</TextBox>
                                                                        <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                                                                            {item.condition_withdrawal_nolimit == "Y" ?
                                                                                t("event.no_limit")
                                                                                :
                                                                                `${item.condition_min_withdrawal.toLocaleString()} ~ ${item.condition_max_withdrawal.toLocaleString()}`
                                                                            }
                                                                        </TextBox>
                                                                    </Row>
                                                                )}

                                                            </Column>

                                                        }>
                                                            {
                                                                item.condition_day_YN == 'Y'
                                                                    || item.condition_time_YN == 'Y'
                                                                    || item.condition_deposit_YN == 'Y'
                                                                    || item.condition_withdrawal_YN == 'Y'
                                                                    ?
                                                                    <TextBox $color="var(--c-blue)" $size="var(--s-table)" $weight="bold">{"보기"}</TextBox>
                                                                    :
                                                                    null
                                                            }
                                                        </Tooltip>
                                                    </TableDeps>
                                                    <TableDeps $tAlign="right">{item.min_money.toLocaleString() || "-"}</TableDeps>
                                                    <TableDeps $tAlign="right">{item.max_money.toLocaleString() || "-"}</TableDeps>
                                                    <TableDeps $tAlign="center">
                                                        {
                                                            item.content ? <Tooltip text={item.content}>
                                                                <TextBox $color="var(--c-blue)" $size="var(--s-table)" $weight="bold">{"보기"}</TextBox>
                                                            </Tooltip> : null
                                                        }

                                                    </TableDeps>
                                                    <TableDeps $tAlign="right">{item.rolling_casion + "%"}</TableDeps>
                                                    <TableDeps $tAlign="right">{item.rolling_slot + "%"}</TableDeps>
                                                    <TableDeps $tAlign="right">{item.rolling_sports + "%"}</TableDeps>
                                                    <TableDeps $tAlign="right">{item.rolling_minigame + "%"}</TableDeps>
                                                    <TableDeps $tAlign="center">{dbSiteEventMng.bonus_payment_type[item.bonus_payment_type]?.label || "-"}</TableDeps>
                                                    <TableDeps $tAlign="center">
                                                        <Tooltip text={
                                                            item.bonus_arr && (
                                                                item.bonus_arr.map((bonus, index) => (
                                                                    <Row key={index}>
                                                                        {bonus.payment_type == 'C' ?
                                                                            <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                                                                                {`${bonus.condition_value} ${t("event.deposit_amount")} ${bonus.payment_value} ${t("event.bonus_amount")}`}
                                                                            </TextBox>
                                                                            :
                                                                            <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                                                                                {`${bonus.payment_value} ${t("event.bonus_percent")}`}
                                                                            </TextBox>
                                                                        }
                                                                    </Row>
                                                                ))
                                                            )
                                                        }>
                                                            {item.bonus_arr ? <TextBox $color="var(--c-blue)" $size="var(--s-table)" $weight="bold">{"보기"}</TextBox> : null}
                                                        </Tooltip>
                                                    </TableDeps>
                                                    <TableDeps $color={"white"} $backColor={item.active === "Y" ? "var(--c-blue)" : "var(--c-red)"} $tAlign="center">{item.active}</TableDeps>
                                                    <TableDeps $tAlign="center">
                                                        {
                                                            item.admin_memo ? <Tooltip text={item.admin_memo}>
                                                                <TextBox $color="var(--c-blue)" $size="var(--s-table)" $weight="bold">{"보기"}</TextBox>
                                                            </Tooltip> : null
                                                        }

                                                    </TableDeps>
                                                    <TableDeps $tAlign="center">{item.dispay_region}</TableDeps>
                                                    {/* <TableDeps $tAlign="left">{item.create_at}</TableDeps>
                                                    <TableDeps $tAlign="left">{item.create_user}</TableDeps>
                                                    <TableDeps $tAlign="left">{item.modify_at}</TableDeps>
                                                    <TableDeps $tAlign="left">{item.modify_user}</TableDeps> */}
                                                    <TableDeps $tAlign="center">
                                                    <Row $width="100%" $jus="center" $gap="6px">
                                                        <ModifyBtn 
                                                        name={t("event.manage")}
                                                        eventProps={() =>
                                                            showNewEventPopup(item, "update")
                                                        }/>
                                                    </Row>
                                                    </TableDeps>
                                                </TableRow>
                                            );
                                        })}
                                </tbody>
                            )}
                        </Table>
                        {useTG && useTG.data?.length === 0 && <NoDataTable />}
                    </>
                )}
                <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
                    <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                        {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
                    </PositionBox>

                    {/* 검색 개수 */}
                    <SelectForm
                        {...useTG}
                        name="countPerPage"
                        placeholder={useCountPerPage[0].label}
                        size={"var(--s-table)"}
                        line={"var(--l-table)"}
                        options={useCountPerPage}
                        formValue={useTG.value}
                    />
                </Row>

                <Row $jus="space-between" >
                    <Row $gap="8px" $align="flex-end">
                        {/* 이벤트 활성화 */}
                        <MainBtn $width="150px" $height="34px" onClick={() => { showEventConfirm("active") }}>
                            {t("event.event_on")}
                        </MainBtn>
                        {/* 이벤트 비활성화 */}
                        <RedBtn $size="var(--s-table)" $line="var(--l-table)" $width="150px" $height="34px" onClick={() => { showEventConfirm("inactive") }}>
                            {t("event.event_off")}
                        </RedBtn>
                        {/* 이벤트 삭제 */}
                        <RedBtn $size="var(--s-table)" $line="var(--l-table)" $width="150px" $height="34px" onClick={() => { showEventConfirm("delete") }}>
                            {t("event.event_delete")}
                        </RedBtn>
                    </Row>

                </Row>

            </Column>
        </>
    );
};

export default NomalEvent;
