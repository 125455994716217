import { useEffect } from "react";
import api from "core/services/api";
import { useCommonState } from "core/contexts/useCommonState";

const AlarmConfigSetter = () => {
  const { setAlarmConfig } = useCommonState();

  useEffect(() => {
    let url = `${process.env.REACT_APP_BASE_URL}/v1/common/alarm-config`;
    
    api.get(url)
    .then((res) => {
      setAlarmConfig(res.data?.content);
    })
    .catch(() => {});
  }, [setAlarmConfig]);

  return null;
};

export default AlarmConfigSetter;
