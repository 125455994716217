import axios from "axios";
import { t } from "i18next";
import { SwalAlertCallBack } from "utils/SwalAlert"

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true
});

api.interceptors.request.use((config) => {
  const authAdmin = localStorage.getItem('auth-admin');
  if (authAdmin) config.headers['Authorization'] = `Bearer ${authAdmin}`;

  return config;
});

api.interceptors.response.use((response) => response,
  (error) => {
    const { response } = error; 
    // Seesion 만료 및 권한 부족
    if (response.status === 401 || response.status === 403) {
      localStorage.clear();
      return window.location.href = `${window.location.origin}/`
    }
    // 중복 로그인
    if (response.status === 409) { 
      localStorage.clear();
      return SwalAlertCallBack('error', t("accessUser.logout"), `<p>${t("duplicate_login")}</p>`, () => {
        window.location.href = `${window.location.origin}/`
      });
    }

    return Promise.reject(error);
  }
);

export default api;
