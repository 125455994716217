/** @format */
import { useEffect, useRef, useState } from "react";

// 공용
import api from "core/services/api";
import Pagination from "utils/Pagination";

import useFormData from "core/hooks/useFormData";
import useTableData from "core/hooks/useTableData";

import { useAlert, usePopup } from "core/contexts/useWindow";
// 셀렉트 벨류 constant
import { useCountPerPage, userState1 } from "constants/select/useValue";
// 공용 팝업
// style import
import { InputForm, NoLabelCheckForm, SelectForm, SortForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "styles/custom/useCustomLayout";
import { FilterTitle, HoverTableText } from "styles/custom/useCustomText";
import { MainBtn, RedBtn, WhiteBtn } from "styles/useButtonStyles";
import { IconLink, InfoTabBox, InfoTabList, LineSection, PositionBox } from "styles/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { AreaTitle, SubText, TextBox } from "styles/useTextStyles";

import useStateData from "core/hooks/useStateData";
import UserInfo from "components/admin/user/info/UserInfo";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import { BlockBtn, DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import { useCommonState } from "core/contexts/useCommonState";
import { dbUser } from "constants/enum/db/useUserEnum";
import { infoTabEnum, validationTypeEnum } from "constants/enum/useCommonEnum";
import { t } from "i18next";
import PointLog from "./PointLog";
import PointManagementForm from "./PointManagementForm";
import UserLevelTab from "components/common/UserLevelTab";
import UserLevelFilter from "components/common/UserLevelFilter";

const PointManagement = () => {
  const useStated = useStateData();

  const { setLoading, isAdmin } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();

  const [todayPointPaymentCount, setTodayPointPaymentCount] = useState(0);
  const [todayPointRetrievalCount, setTodayPointRetrievalCount] = useState(0);

  const [userNm, setUserNm] = useState("");
  const [openUserInfoTab, setOpenUserInfoTab] = useState(false);
  const [openAgentInfoTab, setOpenAgentInfoTab] = useState(false);
  const userTabRef = useRef(null);
  const agentTabRef = useRef(null);

  const infoTabToggle = (tabType, Nm) => {
    if (tabType === infoTabEnum.tabType.u.value) {
      setUserNm(Nm);
      setOpenUserInfoTab(!openUserInfoTab);
    } else if (tabType === infoTabEnum.tabType.a.value) {
      setUserNm(Nm);
      setOpenAgentInfoTab(!openAgentInfoTab);
    }
  };

  useEffect(() => {
    const onBlurEvent = (event) => {
      if (userTabRef.current && !userTabRef.current.contains(event.target)) {
        setOpenUserInfoTab(false);
      }
      if (agentTabRef.current && !agentTabRef.current.contains(event.target)) {
        setOpenAgentInfoTab(false);
      }
    };

    document.addEventListener("mousedown", onBlurEvent);
    return () => {
      document.removeEventListener("mousedown", onBlurEvent);
    };
  }, []);

  const showConditionFailAlert = () => {
    openAlert({
      title: t("user.user_not_select"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  /**
   * 포인트 지급 팝업
   */
  const showPointPaymentPopup = () => {
    const userNoArr = getCheckedUserNoArr();

    if (!userNoArr.length) {
      showConditionFailAlert(true);
      return;
    }
    
    const isPayment = true;
    const checkedUserNos = userNoArr.map((item) => item.no);
    openPopup({
      title: t("point.approve_btn"),
      content: PointManagementForm,
      props: {
        isPayment,
        checkedUserNos,
        nickName : userNoArr[0].no,
        userNo : userNoArr[0].no,
        getTableItem,
      },
    });
  };
  const showPointPaymentSinglePopup = (userNo, nickName) => {
    const isPayment = true;
    const checkedUserNos = [userNo];
    openPopup({
      title: t("point.approve_btn"),
      content: PointManagementForm,
      props: {
        isPayment,
        checkedUserNos,
        nickName,
        userNo,
        getTableItem,
      },
    });
  };

  /**
   * 포인트 회수 팝업
   */
  const showPointRetrievalPopup = () => {
    const userNoArr = getCheckedUserNoArr();

    if (!userNoArr.length) {
      showConditionFailAlert(true);
      return;
    }

    const isPayment = false;
    const checkedUserNos = userNoArr.map((item) => item.no);
    openPopup({
      title: t("point.reject_btn"),
      content: PointManagementForm,
      props: {
        isPayment,
        checkedUserNos,
        nickName : userNoArr[0].no,
        userNo : userNoArr[0].no,
        getTableItem,
      },
    });
  };
  const showPointRetrievalSinglePopup = (userNo, nickName) => {
    const isPayment = false;
    const checkedUserNos = [userNo];
    openPopup({
      title: t("point.reject_btn"),
      content: PointManagementForm,
      props: {
        isPayment,
        checkedUserNos,
        nickName,
        userNo,
        getTableItem,
      },
    });
  };

  const showPointLogPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("point.detail_list")} (${nickName})` : t("point.detail_list"),
      content: PointLog,
      props: {
        userNo,
      },
    });
  };

  const getCheckedUserNoArr = () => {
    return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.no }));
  };

  const showUserInfoPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("user.tab_user_info")} (${nickName})` : t("user.tab_user_info"),
      content: UserInfo,
      props: {
        userNo,
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/inout/point-paid/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        setTodayPointPaymentCount(res.data.todayPointPaymentCount);
        setTodayPointRetrievalCount(res.data.todayPointRetrievalCount);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_agent_category: "",
      search_agent_val: "",
      search_sub_agent_yn: "Y",
      search_agent_name: "",

      search_user_lv: "",
      search_user_nickname: "",
      search_user_no: "",
      search_user_state1: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.deposit_and_withdrawal_manage")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.point_manage")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated}  $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle>
              <InputForm validationType={validationTypeEnum.NUMBER} name="search_user_no" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.search_user_state")}</FilterTitle>
              <SelectForm
                name="search_user_state1"
                placeholder={t("common.all")}
                options={userState1}
                {...useSearch}
                {...useStated}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("point.today_point_payment_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{todayPointPaymentCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>

            <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

            <Row className="total-money" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("point.today_point_retrieval_count")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG.error ? <span>error</span> : <>{todayPointRetrievalCount?.toLocaleString() || "0"}</>}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>
        </Row>

        <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointPaymentPopup()}>
              {t("point.approve_btn")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointRetrievalPopup()}>
              {t("point.reject_btn")}
            </RedBtn>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>
        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="34px">
                    <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                  </TableHeader>
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("inout.parent_agent")}</TableHeader>
                  <TableHeader>{t("user.agent_code")}</TableHeader>
                  <TableHeader>{t("user.agent")}</TableHeader>
                  <TableHeader $width="300px">
                    <Row $gap="2px" $jus="center" $align="center">
                      <TextBox $font="PreSB">{t("user.user")}</TextBox>
                      <SortForm colNm="user_nickname" sortNm={useTG.extendData.sortNm} onSortClick={useTG.onSortClick} />
                    </Row>
                  </TableHeader>
                  <TableHeader $width="130px">{t("user.user_level")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_state")}</TableHeader>
                  <TableHeader>{t("user.point")}</TableHeader>
                  <TableHeader>{t("common.manage")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={item.no} className="table-content">
                          <TableDeps>
                            <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                          </TableDeps>
                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.parent_code || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>

                          <TableDeps $tAlign="left" onClick={() => infoTabToggle(infoTabEnum.tabType.u.value, item.no)}>
                            <HoverTableText>
                              {`${item.user_nickname || "-"} (${item.no || "-"})`}
                              {openUserInfoTab && item.no === userNm && (
                                <InfoTabBox ref={userTabRef}>
                                  <InfoTabList onClick={() => showUserInfoPopup(item.no, item.user_nickname)}>
                                    {t("user.tab_user_info")}
                                  </InfoTabList>
                                </InfoTabBox>
                              )}
                            </HoverTableText>
                          </TableDeps>

                          <TableDeps>
                            <UserLevelTab item={item} />
                          </TableDeps>

                          <TableDeps>{dbUser.state1[item.state1]?.label || "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.point?.toLocaleString() || "0" || "0"}</TableDeps>
                          <TableDeps $width="120px">
                            <Row $width="100%" $jus="center" $gap="6px">
                              {item.no ? (
                                <>
                                  {isAdmin && (
                                    <>
                                      <ModifyBtn
                                        name={t("point.approve_btn")}
                                        eventProps={() => showPointPaymentSinglePopup(item.no, item.user_nickname)}
                                      />
                                      <DeleteBtn
                                        name={t("point.reject_btn")}
                                        eventProps={() => showPointRetrievalSinglePopup(item.no, item.user_nickname)}
                                      />
                                    </>
                                  )}
                                  <BlockBtn name={t("inout.detail_list")} eventProps={() => showPointLogPopup(item.no, item.user_nickname)} />
                                </>
                              ) : (
                                "-"
                              )}
                            </Row>
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointPaymentPopup()}>
              {t("point.approve_btn")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointRetrievalPopup()}>
              {t("point.reject_btn")}
            </RedBtn>
          </Row>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default PointManagement;
