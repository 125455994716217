import useFormData from "core/hooks/useFormData";
import { Column, Row } from "styles/custom/useCustomLayout";
import { InputForm, SelectForm, TextareaForm } from "components/common/CommonForm";
import { AreaTitle } from "styles/useTextStyles";
import api from "core/services/api";
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { dbUserMessage } from "constants/enum/db/useUserEnum";
import { useAlert } from "core/contexts/useWindow";
import { ErrorMsgForm } from "utils/FormStateUtil";
import { t } from "i18next";

const SendUserMessage = ({ userNo , nickName }) => {
  return (
    <>
      <SendMessageTab userNo = { userNo } nickName = { nickName } />
    </>
  );
};

const SendMessageTab = ({ userNo, nickName }) => {
  // const { setLoading } = useCommonState();
  // const useStated = useStateData();
  const { openAlert } = useAlert();

  const sendMessage = () => {
    api
      .post(`/v1/cs-management/send-message`, { ...useForm.formValue })
      .then((res) => {
        resetForm();
      })
      .catch((error) => {
        const errArr = error.response?.data.errors;
        openAlert({
          title: t("sendMessage.send_fail"),
          message:
            errArr === null || errArr === undefined ? null : (
              <Column>
                {errArr.map((err, index) => (
                  <ErrorMsgForm errorJson={err} key={index} />
                ))}
              </Column>
            ),
          iconURL: true,
        });
        console.error(error);
      });
  };

  const resetForm = () => {
    useForm.resetForm();
  };

  const useForm = useFormData({
    title: "",
    contents: "",
    state: dbUserMessage.state.E.value,
    receiver_user_list: [{"no": userNo, "nickname": nickName}],
    receiver_nickname_list_to_string: [nickName],
  });

  return (
    <Row>
      <Column $gap="20px" $pad="20px">
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.message_title")} : </AreaTitle>
          </Row>
          <Row $width="600px">
            <InputForm width="600px" maxWidth="600px" name="title" {...useForm} />
          </Row>
        </Row>
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.receivers")} : </AreaTitle>
          </Row>
          <Row $width="600px">
            <Column $gap="12px">
              <InputForm disabled={true} width="600px" maxWidth="600px" name="receiver_nickname_list_to_string" {...useForm} />
            </Column>
          </Row>
        </Row>
        <Row $gap="12px">
          <Row $width="160px">
            <AreaTitle>{t("sendMessage.message_content")} : </AreaTitle>
          </Row>
          <Row $width="600px">
            <TextareaForm width="600px" maxWidth="600px" name="contents" {...useForm} />
          </Row>
        </Row>
        <Row $gap="12px" $jus="flex-end">
          <MainBtn onClick={sendMessage}>{t("sendMessage.send")}</MainBtn>
          <WhiteBtn onClick={resetForm}>{t("sendMessage.reset_content")}</WhiteBtn>
        </Row>
      </Column>
    </Row>
  );
};

export default SendUserMessage;