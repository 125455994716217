/** @format */

// 공용
import api from "core/services/api";
import Pagination from "utils/Pagination";

import useFormData from "core/hooks/useFormData";
import useTableData from "core/hooks/useTableData";

import { useAlert, usePopup } from "core/contexts/useWindow";
// 셀렉트 벨류 constant
import { useCountPerPage, userState1 } from "constants/select/useValue";
// 공용 팝업
// style import
import { InputForm, SelectForm, SortForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "styles/custom/useCustomLayout";
import { FilterTitle } from "styles/custom/useCustomText";
import { MainBtn, RedBtn, WhiteBtn } from "styles/useButtonStyles";
import { IconLink, PositionBox } from "styles/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { AreaTitle, SubText, TextBox } from "styles/useTextStyles";

import useStateData from "core/hooks/useStateData";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import { useCommonState } from "core/contexts/useCommonState";
import { validationTypeEnum } from "constants/enum/useCommonEnum";
import { t } from "i18next";
import UserLevelFilter from "components/common/UserLevelFilter";
import AgentNameTab from "components/common/AgentNameTab";
import { dbUser, dbUserHistoryPoint } from "constants/enum/db/useUserEnum";
import UserLevelTab from "components/common/UserLevelTab";
import UserState2Hover from "components/common/UserState2Hover";
import UserNameTab from "components/common/UserNameTab";
import { getFormatDateMin } from "utils/date/DateUtil";
import { valuePointLog } from "constants/enum/user/useUserValueEnum";
import PointManagementSelectUserForm from "./PointManagementSelectUserForm";
import Tooltip from "components/common/Tooltip";


const PointManagement = () => {
  const useStated = useStateData();
  const { setLoading, isAdmin } = useCommonState();
  const { openPopup } = usePopup();


  /**
   * 포인트 지급 팝업
   */
  const showPointPaymentPopup = () => {

    const isPayment = true;
    openPopup({
      title: t("point.approve_btn"),
      content: PointManagementSelectUserForm,
      props: {
        isPayment,
        checkedUserNos: [],
        nickName: "", //userNoArr[0].no,
        userNo: "", //userNoArr[0].no,
        getTableItem,
      },
    });
  };


  /**
   * 포인트 회수 팝업
   */
  const showPointRetrievalPopup = () => {

    const isPayment = false;
    openPopup({
      title: t("point.reject_btn"),
      content: PointManagementSelectUserForm,
      props: {
        isPayment,
        checkedUserNos: [],
        nickName: "", //userNoArr[0].no,
        userNo: "", //userNoArr[0].no,
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/inout/point-paid/paid-list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_agent_category: "",
      search_agent_val: "",
      search_sub_agent_yn: "Y",
      search_agent_name: "",
      search_user_lv: "",
      search_user_nickname: "",
      search_user_no: "",
      search_user_state1: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.deposit_and_withdrawal_manage")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.point_manage_list")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle>
              <InputForm validationType={validationTypeEnum.NUMBER} name="search_user_no" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.search_user_state")}</FilterTitle>
              <SelectForm
                name="search_user_state1"
                placeholder={t("common.all")}
                options={userState1}
                {...useSearch}
                {...useStated}
              />
            </Column>

            <UserLevelFilter useSearch={useSearch} useStated={useStated} />
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $gap="8px" $pad="14px 0 14px" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointPaymentPopup()}>
              {t("point.approve_btn")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointRetrievalPopup()}>
              {t("point.reject_btn")}
            </RedBtn>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>
        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="100px">{t("inout.parent_agent")}</TableHeader>
                  <TableHeader $width="100px">{t("user.agent_code")}</TableHeader>
                  <TableHeader $width="100px">{t("user.agent")}</TableHeader>
                  <TableHeader $width="140px">
                    <Row $gap="2px" $jus="center" $align="center">
                      <TextBox $font="PreSB">{t("user.user")}</TextBox>
                      <SortForm colNm="user_nickname" sortNm={useTG.extendData.sortNm} onSortClick={useTG.onSortClick} />
                    </Row>
                  </TableHeader>
                  <TableHeader $width="80px">{t("user.user_level")}</TableHeader>
                  <TableHeader $width="100px">{t("user.user_state")}</TableHeader>
                  <TableHeader $width="100px">{t("paidList.state")}</TableHeader>
                  <TableHeader $width="100px">{t("common.state")}</TableHeader>
                  <TableHeader $width="80px">{t("paidList.date")}</TableHeader>
                  <TableHeader $width="130px">{t("paidList.before_money")}</TableHeader>
                  <TableHeader $width="130px">{t("paidList.request_money")}</TableHeader>
                  <TableHeader $width="130px">{t("paidList.after_money")}</TableHeader>
                  <TableHeader $width="80px">{t("paidList.manager")}</TableHeader>
                  <TableHeader $width="100px">{t("paidList.memo")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={index} className="table-content">
                          <TableDeps >{number || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.parent_code || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>

                          <TableDeps $tAlign="left">
                            <Row $gap="6px" $align="center">
                              <UserState2Hover state2List={item.state2_arr} />
                              <UserNameTab no={item.no} nickname={item.user_nickname} refresh={getTableItem} />
                            </Row>
                          </TableDeps>

                          <TableDeps>
                            <UserLevelTab item={item} />
                          </TableDeps>
                          <TableDeps>{dbUser.state1[item.state1]?.label || "-"}</TableDeps>
                          <TableDeps $tAlign="center">{valuePointLog.type[item.inout]?.label ?? "-"}</TableDeps>
                          <TableDeps >{
                            // 롤링 루징 경우
                            item.point_sub_type == 'L' || item.point_sub_type == 'R' ? `${dbUserHistoryPoint.type[item.point_type].label}(${dbUserHistoryPoint.point_sub_type[item.point_sub_type]?.label} ${item.persent}%)`
                              // 이벤트 포인트 경우
                              : item.point_sub_type == 'E' ? `${dbUserHistoryPoint.type[item.point_type].label}(${t("event.event_charge_bonus")})`
                                // 일반
                                :
                                (dbUserHistoryPoint.type[item.point_type]?.label)
                                || "-"}</TableDeps>
                          <TableDeps $tAlign="center">{getFormatDateMin(item?.process_at) ?? "-"}</TableDeps>
                          <TableDeps $tAlign="right">{item.before_point?.toLocaleString() || "0" || "0"}</TableDeps>
                          <TableDeps $tAlign="right">{item.request_point?.toLocaleString() || "0" || "0"}</TableDeps>
                          <TableDeps $tAlign="right">{item.after_point?.toLocaleString() || "0" || "0"}</TableDeps>
                          <TableDeps $tAlign="left">{item.process_no || "-"}</TableDeps>
                          <TableDeps $tAlign="left" $maxWidth="100px" $overflow="hidden" $white="nowrap" $textOverflow="ellipsis">
                            <Tooltip text={item.memo || ""}>
                              <TextBox $size="var(--s-table)">{item.memo || ""}</TextBox>
                            </Tooltip>
                          </TableDeps>
                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointPaymentPopup()}>
              {t("point.approve_btn")}
            </MainBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPointRetrievalPopup()}>
              {t("point.reject_btn")}
            </RedBtn>
          </Row>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <SelectForm
            top="top"
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            {...useTG}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useCountPerPage.value}
          />
        </Row>
      </Column>
    </>
  );
};

export default PointManagement;
