import * as XLSX from "xlsx-js-style";
import { saveAs } from "file-saver";
import { t } from "i18next";

/**
 * 엑셀 다운로드 함수
 * @param {Array} data - 적용 데이터
 * @param {JSON} headerArray - 엑셀 헤더 배열 (데이터 헤더 배열 정보가 없으면 다운로드에 포함하지 안음) { name: 'string', label: 'string', align: 'string', width: 'number', dataType: 'string', enum: 'JSON' }
 * @param {string} fileName - 다운로드할 파일 이름
 */
const downloadExcel = (data, headerArray, fileName = "exportToExcel.xlsx") => {


    // enum 값 매핑 처리를 위한 함수
    const getEnumValue = (value, enumObj) => {
        if (!enumObj || !value) return value;
        // enumObj의 모든 항목을 순회하며 value가 일치하는 항목의 label 반환
        const matchedItem = Object.values(enumObj).find(item => item.value === value);
        return matchedItem ? matchedItem.label : value;
    };

    const filteredData = data.map(item => {
        const newItem = {};
        headerArray.forEach(header => {
            if (header.name) {
                const value = item[header.name];
                newItem[header.name] = header.enum ? getEnumValue(value, header.enum) : value;
            }
        });
        return newItem;
    });

    // 워크시트 생성
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const range = XLSX.utils.decode_range(worksheet['!ref']);

    // 스타일 정의
    const borderStyle = {
        style: 'thin',
        color: { rgb: '000000' }
    };

    // headerArray를 기반으로 열 너비 설정
    worksheet['!cols'] = headerArray.map(header => ({
        wch: header.width || 20
    }));

    // 모든 셀에 스타일 적용
    for (let R = range.s.r; R <= range.e.r; R++) {
        for (let C = range.s.c; C <= range.e.c; C++) {
            const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
            const header = headerArray[C];

            // 기존 셀 값 보존
            const cellValue = worksheet[cellRef] ? worksheet[cellRef].v : '';

            // 데이터 타입별 기본 정렬 설정
            const getDefaultAlignment = (dataType = 'string') => {
                switch (dataType) {
                    case 'number':
                    case 'currency':
                    case 'percent':
                    case 'decimal':
                        return 'right';
                    case 'date':
                    case 'datetime':
                        return 'center';
                    default:
                        return 'left';
                }
            };

            // 기본 셀 스타일 (header의 align이 있으면 그것을 사용, 없으면 데이터 타입별 기본값 사용)
            const cellStyle = {
                border: {
                    top: borderStyle,
                    bottom: borderStyle,
                    left: borderStyle,
                    right: borderStyle
                },
                alignment: {
                    // header에 align이 있으면 그 값을 사용, 없으면 데이터 타입별 기본 정렬 사용
                    horizontal: header?.align || getDefaultAlignment(header?.dataType),
                    vertical: 'center',
                    wrapText: true
                }
            };

            // 데이터 타입에 따른 셀 형식 지정
            let cellType = 's';
            let formattedValue = cellValue;

            if (R > 0 && header?.dataType) {
                switch (header.dataType) {
                    case 'number':
                        cellType = 'n';
                        formattedValue = Number(cellValue);
                        cellStyle.numFmt = '#,##0';
                        break;
                    case 'currency':
                        cellType = 'n';
                        formattedValue = Number(cellValue);
                        cellStyle.numFmt = '#,##0원';
                        break;
                    case 'percent':
                        cellType = 'n';
                        formattedValue = Number(cellValue);
                        cellStyle.numFmt = '0.00%';
                        break;
                    case 'decimal':
                        cellType = 'n';
                        formattedValue = Number(cellValue);
                        cellStyle.numFmt = '0.00';
                        break;
                    case 'date':
                        if (cellValue) {
                            const date = new Date(cellValue);
                            formattedValue = 25569 + date.getTime() / (24 * 60 * 60 * 1000);
                            cellType = 'n';  
                            cellStyle.numFmt = 'yyyy-mm-dd';
                        }
                        break;
                    case 'datetime':
                        if (cellValue) {
                            const date = new Date(cellValue);
                            formattedValue = 25569 + date.getTime() / (24 * 60 * 60 * 1000);
                            cellType = 'n';  
                            cellStyle.numFmt = 'yyyy-mm-dd hh:mm:ss';
                        }
                        break;
                }
            }

            // 헤더 행 스타일 강화
            if (R === 0) {
                formattedValue = header?.label || cellValue;

                Object.assign(cellStyle, {
                    fill: {
                        patternType: 'solid',
                        fgColor: { rgb: 'E2E2E2' }
                    },
                    font: {
                        sz: 10,
                        name: '맑은 고딕'
                    },
                    alignment: {
                        horizontal: 'center',
                        vertical: 'center',
                        wrapText: true
                    },
                    // 셀 높이 조정을 위한 설정 추가
                    border: {
                        ...cellStyle.border
                    }
                });
            }

            // 첫 번째 행(헤더)의 높이 설정
            if (R === 0) {
                if (!worksheet['!rows']) worksheet['!rows'] = [];
                worksheet['!rows'][R] = { hpt: 30 }; // 높이를 30포인트로 설정 (조절 가능)
            }

            // 셀 업데이트
            worksheet[cellRef] = {
                v: formattedValue,
                t: cellType,
                s: cellStyle
            };
        }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
        cellStyles: true
    });

    const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });


    const excelFileName = fileName.toLowerCase().endsWith('.xlsx') 
    ? fileName 
    : `${fileName}.xlsx`;

    saveAs(excelBlob, excelFileName);
}

export { downloadExcel }