import React, { useEffect } from "react";

import useFormData from "core/hooks/useFormData";
import { AreaTitle } from "styles/useTextStyles";
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { TextBox } from "styles/useTextStyles";
import { LineSection, PositionBox } from "styles/useLayoutStyles";
import { InputForm } from "components/common/CommonForm";
import { Column, OptionBox, Row, SubTitleBox } from "styles/custom/useCustomLayout";
import api from "core/services/api";
import { useAlert, usePopup } from "core/contexts/useWindow";
import useStateData from "core/hooks/useStateData";
import { useCommonState } from "core/contexts/useCommonState";
import { useTranslation } from "react-i18next";
import { validationTypeEnum } from "constants/enum/useCommonEnum";

const AgentChargeForm = ({ getTableItem }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();
  const { openAlert } = useAlert();
  const { closePopup } = usePopup();
  const { t } = useTranslation();
  const useForm = useFormData({});

  useEffect(() => {
    useStated.setErrorData([], true);

    getChargeInfo();
  }, []);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const getChargeInfo = () => {
    setLoading(true);

    const apiUrl = `v1/agent/info/money`;
    api
      .get(apiUrl, {})
      .then((res) => {
        useForm.setFormValue({
          ...res.data.content,
          demand_amount: res.data.content.demand_amount || 0,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showCompeteStateSuccessAlert = () => {
    openAlert({
      title: t("charge.update_state_complete"),
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showCompleteStateFailAlert = () => {
    openAlert({
      title: t("charge.update_state_fail"),
      message: t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showCheckPriceAlert = () => {
    openAlert({
      title: t("charge.update_state_fail"),
      message: t("charge.check_price"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showChargeWaitAlert = () => {
    openAlert({
      title: t("charge.update_state_fail"),
      message: t("charge.charge_wait"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const insertCharge = () => {
    if (useForm.formValue.demand_amount == 0) {
      showCheckPriceAlert()
      return
    }

    if(useForm.formValue.isChargeWait !== 0) {
      showChargeWaitAlert()
      return
    }
      


    setLoading(true);
    const apiUrl = `v1/agent/cash-charge`;
    api
      .post(apiUrl, {
        ...useForm.formValue,
      })
      .then((res) => {
        showCompeteStateSuccessAlert();
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showCompleteStateFailAlert();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const clickChangeDemandAmountVal = (value) => {
    useForm.setFormValue((prev) => ({
      ...prev,
      demand_amount: value ? value : useForm.formValue.money,
    }));
  };

  return (
    <>
      <Column $gap="48px" $width="1500px">
        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.code")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.code}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.parent_agent")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.parent_code}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.name")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.name}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.type")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.type_name}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("common.phone")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.phone}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.join_code")}
              </TextBox>

              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.join_code}
              </TextBox>
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("charge.hold_apply_money")} </AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.money")}
              </TextBox>

              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {(useForm.formValue.money || 0).toLocaleString()} (P)
              </TextBox>
            </OptionBox>
          </Row>
        </Column>

        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("charge.available_apply_money")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <InputForm
                maxWidth="100%"
                validationType={validationTypeEnum.NUMBER}
                name="demand_amount"
                tAlign="right"
                {...useForm}
                {...useStated}
                disabled={useForm.formValue.isChargeWait !== 0}
              />
              <MainBtn $width="100px" $size="var(--s-table)" $line="var(--l-table)" onClick={() => {
                if (useForm.formValue.isChargeWait !== 0) {
                  showChargeWaitAlert()
                } else {
                  clickChangeDemandAmountVal()
                }
              }}>
                {t("common.full_amount")}
              </MainBtn>
              <MainBtn
                $width="100px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => {
                  if (useForm.formValue.isChargeWait !== 0) {
                    showChargeWaitAlert()
                  } else {
                    clickChangeDemandAmountVal(1000000)
                  }
                }
                }

              >
                100{t("common.ten_thousand")}
              </MainBtn>
              <MainBtn
                $width="100px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => {
                  if (useForm.formValue.isChargeWait !== 0) {
                    showChargeWaitAlert()
                  } else {
                    clickChangeDemandAmountVal(100000)
                  }
                }}
              >
                10{t("common.ten_thousand")}
              </MainBtn>
              <MainBtn
                $width="100px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => {
                  if (useForm.formValue.isChargeWait !== 0) {
                    showChargeWaitAlert()
                  } else {
                    clickChangeDemandAmountVal(50000)
                  }
                }}
              >
                5{t("common.ten_thousand")}
              </MainBtn>
              <MainBtn
                $width="100px"
                $size="var(--s-table)"
                $line="var(--l-table)"
                onClick={() => {
                  if (useForm.formValue.isChargeWait !== 0) {
                    showChargeWaitAlert()
                  } else {
                    clickChangeDemandAmountVal(10000)
                  }
                }}
              >
                1{t("common.ten_thousand")}
              </MainBtn>
            </OptionBox>
          </Row>
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <MainBtn $width="100px" $size="var(--s-table)" $line="var(--l-table)" onClick={insertCharge}>
              {t("charge.apply")}
            </MainBtn>
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column >
    </>
  );
};

export default AgentChargeForm;
