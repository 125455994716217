import React from "react";
import styled from "styled-components";
import { TextBox } from "styles/useTextStyles";
import { t } from "i18next";

const Normal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-blue);

  min-width: 33px;
  text-align: center;
  width: fit-content;
  border-radius: 999px;
`;

const Warn = styled.div`
  display: flex;

  background-color: var(--c-brown);

  min-width: 33px;
  text-align: center;
  width: fit-content;
  border-radius: 999px;
`;

const Danger = styled.div`
  align-items: center;

  background-color: var(--c-red);

  min-width: 33px;
  text-align: center;
  width: fit-content;
  border-radius: 999px;
`;

const Block = styled.div`
  justify-content: center;

  background-color: var(--c-gray-700);

  height: 22px;
  min-width: 33px;
  text-align: center;
  width: fit-content;
  border-radius: 999px;
`;

const Success = styled.div`
  display: flex;

  background-color: var(--c-mint-600);

  min-width: 33px;
  text-align: center;
  width: fit-content;
  border-radius: 999px;
`;

const Cancel = styled.div`
  display: flex;

  background-color: var(--c-mint-650);

  min-width: 33px;
  text-align: center;
  width: fit-content;
  border-radius: 999px;
`;

const ModifyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);

  width: auto;
  height: 22px;
  min-width: 33px;
  text-align: center;
`;
const DeleteButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-white);
  border: 1px solid var(--c-gray-300);

  width: auto;
  height: 22px;
  min-width: 33px;
  text-align: center;
`;

const BlockButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--c-gray-600);

  width: auto;
  height: 22px;
  min-width: 33px;
  text-align: center;
`;

const NormalStatus = ({ name }) => (
  <Normal>
    <TextBox
      $white="nowrap"
      $color="var(--c-white)"
      $size="var(--s-caption)"
      $line="var(--l-caption)"
      $font="var(--f-captionB)"
      $pad="2px 6px"
    >
      {name || "정상"}
    </TextBox>
  </Normal>
);

const WarnStatus = ({ name }) => (
  <Warn>
    <TextBox
      $color="var(--c-white)"
      $size="var(--s-caption)"
      $line="var(--l-caption)"
      $font="var(--f-captionB)"
      $pad="2px 6px"
    >
      {name || t("common.warn")}
    </TextBox>
  </Warn>
);

const DangerStatus = ({ name }) => (
  <Danger>
    <TextBox
      $color="var(--c-white)"
      $size="var(--s-caption)"
      $line="var(--l-caption)"
      $font="var(--f-captionB)"
      $pad="2px 6px"
    >
      {name || t("common.danger")}
    </TextBox>
  </Danger>
);

const BlockStatus = ({ name }) => (
  <Block>
    <TextBox
      $color="var(--c-white)"
      $size="var(--s-caption)"
      $line="var(--l-caption)"
      $font="var(--f-captionB)"
      $pad="2px 6px"
    >
      {name || t("common.block")}
    </TextBox>
  </Block>
);

const SuccessStatus = ({ name }) => (
  <Success>
    <TextBox
      $color="var(--c-white)"
      $size="var(--s-caption)"
      $line="var(--l-caption)"
      $font="var(--f-captionB)"
      $pad="2px 6px"
    >
      {name || ""}
    </TextBox>
  </Success>
);

const CancelStatus = ({ name }) => (
  <Cancel>
    <TextBox
      $color="var(--c-white)"
      $size="var(--s-caption)"
      $line="var(--l-caption)"
      $font="var(--f-captionB)"
      $pad="2px 6px"
    >
      {name || ""}
    </TextBox>
  </Cancel>
);

const ModifyBtn = ({ name, eventProps }) => (
  <ModifyButton onClick={eventProps}>
    <TextBox $pad="2px 6px" $size="var(--s-caption)" $line="var(--l-caption)">
      {name || t("common.modify")}
    </TextBox>
  </ModifyButton>
);

const DeleteBtn = ({ name, eventProps }) => (
  <DeleteButton onClick={eventProps}>
    <TextBox $pad="2px 6px" $color="var(--c-red)" $size="var(--s-caption)" $line="var(--l-caption)">
      {name || t("common.delete")}
    </TextBox>
  </DeleteButton>
);

const BlockBtn = ({ name, eventProps }) => (
  <BlockButton onClick={eventProps}>
    <TextBox $pad="2px 6px" $color="var(--c-white)" $size="var(--s-caption)" $line="var(--l-caption)">
      {name || t("common.block")}
    </TextBox>
  </BlockButton>
);

export { NormalStatus, WarnStatus, DangerStatus, BlockStatus, ModifyBtn, DeleteBtn, BlockBtn, SuccessStatus, CancelStatus };
