import useFormData from "core/hooks/useFormData";
import useStateData from "core/hooks/useStateData";
import useTableData from "core/hooks/useTableData";
import GameSearchFilter from "components/common/GameSearchFilter";
import { NoLabelCheckForm, RadioForm } from "components/common/CommonForm";
import { useCommonState } from "core/contexts/useCommonState";
import { Column, Row, FilterBox } from "styles/custom/useCustomLayout";
import { PositionBox } from "styles/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { TextBox } from "styles/useTextStyles";
import api from "core/services/api";
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { FilterTitle } from "styles/custom/useCustomText";
import { SelectForm } from "components/common/CommonForm";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import NoDataTable from "components/common/NoDataTable";
import { useCountPerPage } from "constants/select/useValue";
import styled from "styled-components";
import { t } from "i18next";

const CategoryTableRow = styled(TableRow)`
  &:nth-child(even) {
    background-color: var(--c-white);
  }
  ${(props) => props.$isSelect && `background-color: var(--c-tableDeps) !important;`}
`;

const GameAddPopup = ({ categoryInfo }) => {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const showConfirm = (registed, item) => {
    let itemList;

    if (item) {
      if (registed && item.registed === "Y") {
        setLoading(false);
        return;
      } else if (!registed && item.registed === "N") {
        setLoading(false);
        return;
      }
      itemList = [item];
    } else {
      const checkedList = useTG.data.filter((item) => item.isChecked);
      if (checkedList.length === 0) {
        setLoading(false);
        return;
      }

      const filteredList = checkedList.filter(item => 
        registed ? item.registed !== 'Y' : item.registed !== 'N'
      );

      if (filteredList.length === 0) {
        setLoading(false);
        return;
      }

      itemList = filteredList;
    }
    updateGameStatus(registed, itemList);
  };

  const getGameList = () => {
    setLoading(true);
    const apiUrl = `/v1/site/menu-category/game-list`;
    api
      .get(apiUrl, {
        params: {
          menu_code: categoryInfo.menu_code,
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateGameStatus = (registed, itemList) => {
    setLoading(true);

    const handleSuccess = () => {
      getGameList();
      setLoading(false);
    };

    const handleError = (error) => {
      alert("error : ", error);
      console.error(error);
      setLoading(false);
    };

    const registerGame = (itemList) => {
      api
        .post(`/v1/site/menu-category/register`, { menu_code: categoryInfo.menu_code, game_list: itemList })
        .then(handleSuccess)
        .catch(handleError);
    };

    const unregisterGame = (itemList) => {
      api
        .delete(`/v1/site/menu-category/unregister`, {
          params: {
            menu_code: categoryInfo.menu_code,
            game_list: itemList,
          },
        })
        .then(handleSuccess)
        .catch(handleError);
    };

    registed ? registerGame(itemList) : unregisterGame(itemList);
  };

  const useSearch = useFormData(
    {
      search_registed: searchStateEnum.all.value,
    },
    getGameList,
    useTG
  );

  return (
    <Column>
      <FilterBox $pad="12px">
        <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
          <Column $gap="4px">
            <FilterTitle>{t("menuCategory.search_filter")}</FilterTitle>
            <Row $gap="12px" $align="center">
              <RadioForm uniqueKey={"popup"} name="search_registed" options={searchStateEnum} {...useSearch} {...useStated} />
              <GameSearchFilter useSearch={useSearch} useStated={useStated} />
            </Row>
          </Column>
        </Row>
        <Row $gap="8px">
          <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
            {t("filter.search")}
          </MainBtn>
          <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
          </WhiteBtn>
        </Row>
      </FilterBox>

      <Row $jus="space-between" $width="100%" $align="center">
        <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
          <TextBox $font="var(--f-subB)">{t("menuCategory.total_game_count")}</TextBox>
          <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
            {useTG.error ? (
              <span>error</span>
            ) : (
              <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
            )}
          </TextBox>
          <TextBox $font="var(--f-subB)">{t("menuCategory.unit_count")}</TextBox>
        </Row>
      </Row>

      <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
        <Row $gap="10px" $align="center">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showConfirm(true, null)}>
              {t("menuCategory.register_game_button")}
            </MainBtn>
            <WhiteBtn onClick={() => showConfirm(false, null)}>{t("menuCategory.unregister")}</WhiteBtn>
          </Row>
        </Row>
        <SelectForm
          {...useTG}
          name="countPerPage"
          placeholder={useCountPerPage[0].label}
          size={"var(--s-table)"}
          line={"var(--l-table)"}
          options={useCountPerPage}
          formValue={useTG.extendData}
        />
      </Row>

      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          {
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="34px">
                    <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                  </TableHeader>
                  <TableHeader $minWidth="60px" $width="60px">
                    <TextBox>{t("menuCategory.no")}</TextBox>
                  </TableHeader>
                  <TableHeader $minWidth="60px" $width="60px">
                    {t("menuCategory.registration_status")}
                  </TableHeader>
                  <TableHeader $minWidth="130px" $width="130px">
                    {t("menuCategory.vendor")}
                  </TableHeader>
                  <TableHeader $minWidth="80px" $width="80px">
                    {t("menuCategory.type")}
                  </TableHeader>
                  <TableHeader $minWidth="280px">{t("menuCategory.game_name")}</TableHeader>
                  <TableHeader $minWidth="280px" $width="280px">
                    {t("menuCategory.registration_category")}
                  </TableHeader>
                  <TableHeader $minWidth="130px" $width="130px">
                    {t("menuCategory.management")}
                  </TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo?.offset - index;
                      return (
                        <CategoryTableRow $isSelect={item.registed === "N"} key={number} className="table-content">
                          <TableDeps>
                            <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                          </TableDeps>
                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps $width="60px">
                            {item.registed === "Y" ? t("menuCategory.registered") : t("menuCategory.not_registered")}
                          </TableDeps>
                          <TableDeps $width="130px" $tAlign="left">
                            {item.vendor}
                          </TableDeps>
                          <TableDeps $width="80px">{item?.type}</TableDeps>
                          <TableDeps $width="280px" $tAlign="left">
                            {item?.title}
                          </TableDeps>
                          <TableDeps $width="280px">
                            <Column>
                              {item?.menu_paths
                                ?.split(",")
                                .map((menu_path, index) => <TextBox key={index}>{"(" + menu_path + ")"}</TextBox>) ?? "-"}
                            </Column>
                          </TableDeps>
                          <TableDeps $width="100px">
                            <Row $gap="8px">
                              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showConfirm(true, item)}>
                                {t("menuCategory.register_game_button")}
                              </MainBtn>
                              <WhiteBtn onClick={() => showConfirm(false, item)}>{t("menuCategory.unregister")}</WhiteBtn>
                            </Row>
                          </TableDeps>
                        </CategoryTableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
          }
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
        <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
          <Row $gap="10px" $align="center">
            <Row $gap="8px">
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showConfirm(true, null)}>
                {t("menuCategory.register_game_button")}
              </MainBtn>
              <WhiteBtn onClick={() => showConfirm(false, null)}>{t("menuCategory.unregister")}</WhiteBtn>
            </Row>
          </Row>
        </Row>

        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row $gap="8px">
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            top="top"
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>
    </Column>
  );
};

const searchStateEnum = {
  all: {
    label: t("menuCategory.all_games"),
    value: "all",
    key: 0,
  },
  registed: {
    label: t("menuCategory.registered_games"),
    value: "registed",
    key: 1,
  },
  unregisted: {
    label: t("menuCategory.not_registered_games"),
    value: "unregisted",
    key: 2,
  },
};

export default GameAddPopup;
