import AgentInfoTab from "components/agent/info/AgentInfoTab";
import { useCommonState } from "core/contexts/useCommonState";
import { usePopup } from "core/contexts/useWindow";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { Column, Row } from "styles/custom/useCustomLayout";
import { HoverTableText } from "styles/custom/useCustomText";
import { AgentInfoBox, HoverCon, IconLink, InfoTabBox, InfoTabList } from "styles/useLayoutStyles";
import { TextBox } from "styles/useTextStyles";
import api from "core/services/api";

const AgentNameTab = ({ no, code, name, type_name, refresh, tabList, popupRef }) => {
  const [openInfoTab, setOpenInfoTab] = useState(false);
  const [openInfoAgent, setOpenInfoAgent] = useState(false);
  const [parentAgent, setParentAgent] = useState(null);

  const tabBoxRef = useRef(null);
  const infoBoxRef = useRef(null);
  const { isAdmin } = useCommonState();
  const { openPopup } = usePopup();
  const infoTabRef = useRef();
  const agentInfoBoxRef = useRef(); // AgentInfoBox의 참조 추가

  const infoTabToggle = () => {
    setOpenInfoTab((prevState) => !prevState);
  };

  const infoAgentToggle = () => {
    getParentInfo();
    setOpenInfoAgent((prevState) => !prevState);
  };

  useEffect(() => {
    if (openInfoTab && tabBoxRef.current && infoTabRef.current) {
      const tabBoxRect = tabBoxRef.current.getBoundingClientRect();
      const infoTabRect = infoTabRef.current.getBoundingClientRect();

      const height = popupRef ? popupRef.current.getBoundingClientRect().bottom : window.innerHeight;
      const spaceBelow = height - tabBoxRect.bottom;

      const spaceAbove = tabBoxRect.bottom;

      if (spaceBelow < infoTabRect.height && spaceAbove > infoTabRect.height) {
        infoTabRef.current.style.top = `auto`;
        infoTabRef.current.style.bottom = `100%`;
      } else {
        infoTabRef.current.style.top = "100%";
      }
    }
  }, [openInfoTab]);

  useEffect(() => {
    if (openInfoAgent && infoBoxRef.current && agentInfoBoxRef.current) {
      const agentInfoBoxRect = agentInfoBoxRef.current.getBoundingClientRect();

      const height = popupRef ? popupRef.current.getBoundingClientRect().bottom : window.innerHeight;

      const spaceBelow = height - agentInfoBoxRect.bottom;

      if (spaceBelow < agentInfoBoxRect.height) {
        agentInfoBoxRef.current.style.top = `auto`;
        agentInfoBoxRef.current.style.bottom = `100%`;
      } else {
        agentInfoBoxRef.current.style.top = "100%";
      }
    }
  }, [openInfoAgent]);

  const showAgentInfoPopup = (agentNo, code, tabNm) => {
    openPopup({
      title: `${t("agent.agent")} (${code})`,
      content: AgentInfoTab,
      props: {
        agentNo,
        tabNm,
        refresh,
      },
    });
  };

  const getParentInfo = () => {
    const apiUrl = `/v1/agent/info/parent-info/${no}`;
    api
      .get(apiUrl)
      .then((res) => {
        setParentAgent(res.data.content);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tabBoxRef.current && !tabBoxRef.current.contains(event.target)) {
        setOpenInfoTab(false);
      }

      if (infoBoxRef.current && !infoBoxRef.current.contains(event.target)) {
        setOpenInfoAgent(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const copyToClipboard = (val) => {
    navigator.clipboard.writeText(val);
  };

  const TabList = () => {
    if (tabList) {
      return <>{tabList}</>;
    } else {
      if (isAdmin) {
        return (
          <>
            <InfoTabList onClick={() => showAgentInfoPopup(no, code, 1)}>{t("menu.agent_info")}</InfoTabList>
            <InfoTabList onClick={() => showAgentInfoPopup(no, code, 3)}>{t("menu.payment_history")}</InfoTabList>
            <InfoTabList onClick={() => showAgentInfoPopup(no, code, 4)}>{t("menu.user_list")}</InfoTabList>
            <InfoTabList onClick={() => showAgentInfoPopup(no, code, 5)}>{t("menu.qna_history")}</InfoTabList>
          </>
        );
      } else {
        return (
          <>
            <InfoTabList onClick={() => showAgentInfoPopup(no, code, 1)}>{t("menu.agent_info")}</InfoTabList>
            <InfoTabList onClick={() => showAgentInfoPopup(no, code, 3)}>{t("menu.user_list")}</InfoTabList>
          </>
        );
      }
    }
  };

  return (
    <Row $gap="6px" $align="center">
      <HoverCon ref={infoBoxRef} onClick={infoAgentToggle}>
        <IconLink className="h-a-box" $url="/assets/svg/info.svg" $width="16px" $height="16px" />
        {openInfoAgent && (
          <AgentInfoBox ref={agentInfoBoxRef}>
            <Column $gap="6px">
              {parentAgent && (
                <Column>
                  <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                    {t("agent.parent_agent")}:
                  </TextBox>
                  <TextBox $color="var(--c-white)" $font="var(--f-tableB)" $size="var(--s-table)" $line="var(--l-table)">
                    {`${parentAgent?.name}(${
                      parentAgent?.type_name === "agent.head_agent" ? t("agent.head_agent") : parentAgent?.type_name
                    })`}
                  </TextBox>
                </Column>
              )}
              <Row $gap="6px" $align="center">
                <TextBox $color="var(--c-white)" $size="var(--s-table)" $line="var(--l-table)">
                  {t("common.code")}: {code}
                </TextBox>
                <IconLink
                  className="h-a-box"
                  $url="/assets/svg/copy.svg"
                  $width="16px"
                  $height="16px"
                  onClick={() => copyToClipboard(code)}
                />
              </Row>
              <Row $gap="6px" $align="center">
                <TextBox $color="var(--c-white)" $size="var(--s-table)" $line="var(--l-table)">
                  {t("common.order_number")}: {no}
                </TextBox>
                <IconLink
                  className="h-a-box"
                  $url="/assets/svg/copy.svg"
                  $width="16px"
                  $height="16px"
                  onClick={() => copyToClipboard(no)}
                />
              </Row>
            </Column>
          </AgentInfoBox>
        )}
      </HoverCon>

      <HoverTableText onClick={infoTabToggle} ref={tabBoxRef}>
        {`${name}(${type_name})`}
        {openInfoTab && (
          <InfoTabBox ref={infoTabRef}>
            <TabList />
          </InfoTabBox>
        )}
      </HoverTableText>
    </Row>
  );
};

export default AgentNameTab;
