import React, { useState, useEffect } from "react";
import Pagination from "utils/Pagination";
import api from "core/services/api";
import useStyle from "styles/useStyle";

const UsersBettingPage = () => {
  const newDate = new Date();
  const { Table, FlexBox, TextBox, TableDeps, TableHeader } = useStyle();

  const [usersBettingPageApi, setUsersBettingPageApi] = useState([]);
  const [pagerHelper, setPagerHelper] = useState();
  const [currentDate, setCurrentDate] = useState(newDate);

  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");

  const yyyyMM = `${year}-${month}`;

  const calculateMonth = (offSet) => {
    const getDate = new Date(currentDate);
    getDate.setMonth(getDate.getMonth() + offSet);
    setCurrentDate(getDate);
  };

  useEffect(() => {
    const usersBettingPageApi = "/v1/api/game-admin/users-balance-statistics";
    api
      .get(usersBettingPageApi, {
        params: {
          createAt: yyyyMM,
          page: 1,
        },
      })
      .then((res) => {
        setUsersBettingPageApi(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
      })
      .catch((error) => {
        alert("유저 월별 금액 내역 정보를 불러오지 못했습니다.");
        console.error(error);
      });
  }, [currentDate, yyyyMM]);

  const getUserInfosBettingPage = (page) => {
    const usersBettingPageApi = "/v1/api/game-admin/users-balance-statistics";
    api
      .get(usersBettingPageApi, {
        params: {
          createAt: yyyyMM,
          page: page,
          countPerPage: pagerHelper.countPerPage,
        },
      })
      .then((res) => {
        setUsersBettingPageApi(res.data.content);
        setPagerHelper(res.data.pageHelperDto);
      })
      .catch((error) => {
        alert("유저 월별 금액 내역 정보를 불러오지 못했습니다.");
        console.error(error);
      });
  };

  return (
    <>
      <FlexBox
        $direct="column"
        $gap="14px"
      >
        <FlexBox
          $gap="8px"
          $pad="0 0 12px"
          $align="center"
          $jus="space-between"
        >
          <TextBox as="p">유저 월별 금액 내역</TextBox>
          <FlexBox
            $align="center"
            $gap="4px"
          >
            {/* 좌측 화살표 */}
            <TextBox
              as="button"
              onClick={() => calculateMonth(-1)}
            >
              {"<"}
            </TextBox>
            {/* 현재 날짜 표시 */}
            <TextBox>{yyyyMM}</TextBox>
            {/* 우측 화살표 */}
            <TextBox
              as="button"
              onClick={() => calculateMonth(1)}
            >
              {">"}
            </TextBox>
          </FlexBox>
        </FlexBox>

        {/* 검색 전 or 검색 결과 x 일때 텍스트 노출 */}
        {/* 검색 후 텍스트 hide 테이블 show */}
        <Table className="table-box">
          <thead>
            {/* WIDTH는 고정하지 않은 상태 아직 텍스트 관련 내용없음. */}
            <tr className="table-title">
              <TableHeader>유저 번호</TableHeader>
              <TableHeader>닉네임</TableHeader>
              <TableHeader>이메일</TableHeader>
              <TableHeader>베팅 금액</TableHeader>
              <TableHeader>획득 금액</TableHeader>
              <TableHeader>충전 금액</TableHeader>
            </tr>
          </thead>
          <tbody>
            {/* tr 맵 돌리기 */}
            {usersBettingPageApi.map((userStats) => (
              <tr
                key={userStats.userNo}
                className="table-content"
              >
                <TableDeps>{userStats.userNo}</TableDeps>
                <TableDeps>
                  {userStats.nickname ? userStats.nickname : "없음"}
                </TableDeps>
                <TableDeps>{userStats.email}</TableDeps>
                <TableDeps>{userStats.betAmount?.toLocaleString()}</TableDeps>
                <TableDeps>{userStats.winAmount?.toLocaleString()}</TableDeps>
                <TableDeps>{userStats.addAmount?.toLocaleString()}</TableDeps>
              </tr>
            ))}
          </tbody>
        </Table>
        {pagerHelper && (
          <FlexBox
            $align="center"
            $jus="center"
            $width="100%"
          >
            <Pagination
              pagerHelper={pagerHelper}
              onPageChange={getUserInfosBettingPage}
            />
          </FlexBox>
        )}
      </FlexBox>
    </>
  );
};

export default UsersBettingPage;
