/** @format */
import { useState } from "react";

// 공용
import api from "core/services/api";
import Pagination from "utils/Pagination";

import useFormData from "core/hooks/useFormData";
import useTableData from "core/hooks/useTableData";

import { useAlert, useConfirm, usePopup } from "core/contexts/useWindow";
// 셀렉트 벨류 constant
import { useCountPerPage, userState1 } from "constants/select/useValue";
// 공용 팝업
// style import
import { InputForm, NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row } from "styles/custom/useCustomLayout";
import { FilterTitle } from "styles/custom/useCustomText";
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { IconLink, InfoTabList, LineSection, PositionBox } from "styles/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { AreaTitle, SubText, TextBox } from "styles/useTextStyles";

import useStateData from "core/hooks/useStateData";
import AgentNameTab from "components/common/AgentNameTab";
import AgentSearchFilter from "components/common/AgentSearchFilter";
import UserNameTab from "components/common/UserNameTab";
import { useCommonState } from "core/contexts/useCommonState";
import { dbUser, dbUserState2 } from "constants/enum/db/useUserEnum";
import { validationTypeEnum } from "constants/enum/useCommonEnum";
import { t } from "i18next";
import { addAllFromEnum } from "utils/common/EnumUtil";
import { getFormatDatetime } from "utils/date/DateUtil";
import UserInfo from "../info/UserInfo";
import UserLevelTab from "components/common/UserLevelTab";

const ManagedUser = () => {
  const useStated = useStateData();
  const { setLoading, isAdmin } = useCommonState();

  const { openPopup } = usePopup();
  const { openAlert } = useAlert();
  const { openConfirm } = useConfirm();

  const [userTotalCount, setUserTotalCount] = useState(0);
  const [blockUserTotalCount, setBlockUserTotalCount] = useState(0);

  const showNoSelectionAlert = () => {
    openAlert({
      title: t("userManaged.unblock"),
      message: t("user.user_not_select"),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showUnblockManagedUserAlert = (isSuccess) => {
    openAlert({
      title: isSuccess ? t("userManaged.unblock_complete") : t("userManaged.unblock_fail"),
      message: isSuccess ? t("userManaged.unblock_complete_msg") : t("userManaged.unblock_fail_msg"),
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 차단 해제처리 Confirm
   */
  const showUnblockManagedUserConfirm = () => {
    const userArr = getCheckedUserArr();

    if (!userArr.length) {
      showNoSelectionAlert();
      return;
    }

    const invalidItems = userArr.filter((item) => item.state1 !== dbUser.state1.B.value);

    if (invalidItems.length) {
      openAlert({
        title: t("userManaged.unblock"),
        message: t("userManaged.invalid_alert_msg1"),
        message2: t("userManaged.invalid_alert_msg2"),
        iconURL: true,
        mainBtn: t("common.check"),
      });
    } else {
      openConfirm({
        title: t("userManaged.unblock"),
        message: t("userManaged.unblock_confirm", { number: userArr.length }),
        iconURL: false,
        mainBtn: t("common.check"),
        Func: () => unblockManagedUser(userArr.map((item) => item.no)),
      });
    }
  };
  const showUnblocSingleManagedUserConfirm = (userNo, nickName, state1) => {
    if (state1 !== dbUser.state1.B.value) {
      openAlert({
        title: t("userManaged.unblock"),
        message: t("userManaged.single_invalid_alert_msg1", { nickName: nickName || "-", userNo: userNo || "-" }),
        message2: t("userManaged.single_invalid_alert_msg2"),
        iconURL: true,
        mainBtn: t("common.check"),
      });
    } else {
      openConfirm({
        title: t("userManaged.unblock"),
        message: t("userManaged.unblock_single_confirm", { nickName: nickName || "-", userNo: userNo || "-" }),
        iconURL: false,
        mainBtn: t("common.check"),
        Func: () => unblockManagedUser([userNo]),
      });
    }
  };

  /**
   * 차단 해제처리
   */
  const unblockManagedUser = (checkedUserNo) => {
    setLoading(true);
    const apiUrl = `/v1/user/managed/unblock`;
    api
      .put(apiUrl, {
        user_arr: checkedUserNo,
      })
      .then((res) => {
        showUnblockManagedUserAlert(true);
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showUnblockManagedUserAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCheckedUserArr = () => {
    return useTG.data.filter((item) => item.isChecked).map((item) => ({ no: item.no, state1: item.state1 }));
  };

  const showUserInfoPopup = (userNo, nickName) => {
    openPopup({
      title: nickName ? `${t("user.tab_user_info")} (${nickName})` : t("user.tab_user_info"),
      content: UserInfo,
      props: {
        userNo,
        getTableItem,
      },
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/user/managed/list";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        setUserTotalCount(res.data.userTotalCount);
        setBlockUserTotalCount(res.data.blockUserTotalCount);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      search_sub_agent_yn: "Y",
      search_agent_category: "",
      search_agent_val: "",
      search_agent_name: "",

      search_user_nickname: "",
      search_user_refer_nickname: "",
      search_user_no: "",
      search_user_refer_no: "",
      search_user_state1: "",
      search_user_state2: "",
    },
    getTableItem,
    useTG
  );

  /**
   * tabList
   * @param {*} item
   * @returns
   */
  const customTabList = (item) => {
    return (
      <>
        <InfoTabList onClick={() => showUserInfoPopup(item.no, item.user_nickname)}>{t("user.tab_user_info")}</InfoTabList>
        <InfoTabList onClick={() => showUnblocSingleManagedUserConfirm(item.no, item.user_nickname, item.state1)}>{t("userManaged.unblock")}</InfoTabList>
      </>
    );
  };

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.user_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.user_managed")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <AgentSearchFilter useSearch={useSearch} useStated={useStated} $mar="0 24px 0 0 " />

            <Column $gap="4px">
              <FilterTitle>{t("user.user")}</FilterTitle>
              <InputForm type="text" name="search_user_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.refer")}</FilterTitle>
              <InputForm type="text" name="search_user_refer_nickname" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.user_no")}</FilterTitle>
              <InputForm validationType={validationTypeEnum.NUMBER} name="search_user_no" placeholder={t("common.input")} {...useSearch} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("user.refer_no")}</FilterTitle>
              <InputForm
                validationType={validationTypeEnum.NUMBER}
                name="search_user_refer_no"
                placeholder={t("common.all")}
                {...useSearch}
              />
            </Column>


            <Column $gap="4px">
              <FilterTitle>{t("user.search_user_state")}</FilterTitle>
              <SelectForm name="search_user_state1" placeholder={t("common.all")} options={userState1} {...useSearch} {...useStated} />
            </Column>

            <Column $gap="4px">
              <FilterTitle>{t("userManaged.user_state2")}</FilterTitle>
              <Row $gap="4px">
                <SelectForm
                  name="search_user_state2"
                  placeholder={t("common.all")}
                  options={addAllFromEnum(dbUserState2.state2)}
                  {...useSearch}
                  {...useStated}
                />
              </Row>
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        {isAdmin ? (
          <>
            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-money" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("user.total_user_count")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG.error ? <span>error</span> : <>{userTotalCount?.toLocaleString() || "0"}</>}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                </Row>

                <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

                <Row $gap="10px" $align="center">
                  <Row className="total-partner" $gap="4px">
                    <TextBox $font="var(--f-subB)">{t("userManaged.total_blocked_users")}</TextBox>
                    <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                      {useTG.error ? <span>error</span> : <>{blockUserTotalCount?.toLocaleString() || "0"}</>}
                    </TextBox>
                    <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                  </Row>
                </Row>
              </Row>
            </Row>
            <Row $gap="8px" $pad="0 0 14px" $jus="space-between" $width="100%">
              <Row $gap="8px">
                <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showUnblockManagedUserConfirm()}>
                  {t("userManaged.unblock")}
                </MainBtn>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>
          </>
        ) : (
          <>
            <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
              <Row $gap="10px" $align="center">
                <Row className="total-money" $gap="4px">
                  <TextBox $font="var(--f-subB)">{t("user.total_user_count")}</TextBox>
                  <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                    {useTG.error ? <span>error</span> : <>{userTotalCount?.toLocaleString() || "0"}</>}
                  </TextBox>
                  <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                </Row>

                <LineSection $height="12px" $borL="1px solid var(--c-gray-300)"></LineSection>

                <Row $gap="10px" $align="center">
                  <Row className="total-partner" $gap="4px">
                    <TextBox $font="var(--f-subB)">{t("userManaged.total_blocked_users")}</TextBox>
                    <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                      {useTG.error ? <span>error</span> : <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>}
                    </TextBox>
                    <TextBox $font="var(--f-subB)">{t("common.user_unit")}</TextBox>
                  </Row>
                </Row>
              </Row>

              <Row $gap="8px">
                <SelectForm
                  {...useTG}
                  name="countPerPage"
                  placeholder={useCountPerPage[0].label}
                  size={"var(--s-table)"}
                  line={"var(--l-table)"}
                  options={useCountPerPage}
                  formValue={useCountPerPage.value}
                />
              </Row>
            </Row>
          </>
        )}

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  {isAdmin ? (
                    <TableHeader $width="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                  ) : null}

                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader>{t("user.agent_code")}</TableHeader>
                  <TableHeader>{t("user.agent")}</TableHeader>
                  <TableHeader>{t("user.user")}</TableHeader>
                  <TableHeader>{t("user.user_level")}</TableHeader>
                  <TableHeader $width="130px">{t("user.user_state")}</TableHeader>
                  <TableHeader $width="60px">{t("user.join_code")}</TableHeader>
                  <TableHeader $width="130px">{t("user.last_login_at")}</TableHeader>
                  <TableHeader>{t("userManaged.user_state2")}</TableHeader>
                  <TableHeader $width="130px">{t("userManaged.managed_at")}</TableHeader>
                  <TableHeader>{t("user.processor")}</TableHeader>
                </tr>
              </thead>
              {useTG && useTG.data?.length > 0 && (
                <tbody>
                  {useTG.data &&
                    useTG.data.map((item, index) => {
                      const number = useTG.pagingInfo.offset - index;
                      return (
                        <TableRow key={item.no} className="table-content">
                          {isAdmin ? (
                            <TableDeps>
                              <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                            </TableDeps>
                          ) : null}

                          <TableDeps $width="60px">{number || "-"}</TableDeps>
                          <TableDeps $tAlign="left">{item.code}</TableDeps>

                          <TableDeps $tAlign="left">
                            <AgentNameTab
                              no={item.agent_no}
                              code={item.code}
                              name={item.name}
                              type_name={item.type_name}
                            />
                          </TableDeps>

                          <TableDeps $tAlign="left">
                            <UserNameTab
                              no={item.no}
                              nickname={item.user_nickname}
                              refresh={getTableItem}
                              tabList={customTabList(item)}
                            />
                          </TableDeps>

                          <TableDeps>
                            <UserLevelTab item={item} />
                          </TableDeps>

                          <TableDeps>
                            <TextBox
                              className={
                                item.state1 === dbUser.state1.S.value
                                  ? "complete"
                                  : item.state1 === dbUser.state1.B.value
                                    ? "warn"
                                    : item.state1 === dbUser.state1.O.value
                                      ? "withdraw"
                                      : item.state1 === dbUser.state1.W.value
                                        ? "normal"
                                        : item.state1 === dbUser.state1.T.value
                                          ? "test"
                                          : ""
                              }
                            >
                              {dbUser.state1[item.state1]?.label || "-"}
                            </TextBox>
                          </TableDeps>

                          <TableDeps>{item.join_code || "-"}</TableDeps>
                          <TableDeps>{getFormatDatetime(item.last_login_at) || "-"}</TableDeps>

                          <TableDeps>
                            <Column>
                              {(() => {

                                const state2List = item.state2_list?.split(',');
                                return (
                                  <>
                                    {state2List?.map((state2, index) => (
                                      <TextBox key={index}>{dbUserState2.state2[state2]?.label}</TextBox>
                                    )) || "-"}
                                  </>
                                );
                              })()}
                            </Column>
                          </TableDeps>

                          <TableDeps>
                            <Column>
                              {(() => {
                                const createAtList = item.create_at_list?.split(',');
                                return (
                                  <>
                                    {createAtList?.map((create_at, index) => (
                                      <TextBox key={index}>{getFormatDatetime(create_at)}</TextBox>
                                    )) || "-"}
                                  </>
                                );
                              })()}
                            </Column>
                          </TableDeps>

                          <TableDeps>
                            <Column>
                              {(() => {
                                const createNoList = item.create_no_list?.split(',');
                                const createNicknameList = item.create_nickname_list?.split(',');

                                return (
                                  <>
                                    {createNoList?.map((create_no, index) => (
                                      <TextBox key={index}>{`${createNicknameList[index]} (${create_no})`}</TextBox>
                                    )) || "-"}
                                  </>
                                );
                              })()}
                            </Column>
                          </TableDeps>

                        </TableRow>
                      );
                    })}
                </tbody>
              )}
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        {isAdmin ? (
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showUnblockManagedUserConfirm()}>
              {t("userManaged.unblock")}
            </MainBtn>
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>
            <SelectForm
              top="top"
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              {...useTG}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        ) : (
          <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
            <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
              {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
            </PositionBox>

            <Row $gap="8px">
              <SelectForm
                {...useTG}
                name="countPerPage"
                placeholder={useCountPerPage[0].label}
                size={"var(--s-table)"}
                line={"var(--l-table)"}
                options={useCountPerPage}
                formValue={useCountPerPage.value}
                top="top"
              />
            </Row>
          </Row>
        )}
      </Column >
    </>
  );
};

export default ManagedUser;
