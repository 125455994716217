import i18n from "constants/locales/i18n";

const agentUserFilterCondition = {
  ALL: {
    label: i18n.t("common.all"),
    value: "",
    key: "",
  },
  DIRECT: {
    label: i18n.t("agent.direct_user"),
    value: "DIRECT",
    key: "DIRECT",
  },
  SUB: {
    label: i18n.t("agent.sub_user"),
    value: "SUB",
    key: "SUB",
  },
};

const agentFilterCondition = {
  AGENT_CODE: {
    label: i18n.t("agent.agent_select_form_code"),
    value: "agent_code",
    key: "agent_code",
  },
  AGENT_NO: {
    label: i18n.t("agent.agent_select_form_no"),
    value: "agent_no",
    key: "agent_no",
  },
};

export { agentUserFilterCondition, agentFilterCondition };
