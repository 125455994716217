import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// 공용
import Pagination from "utils/Pagination";
import api from "core/services/api";

import useTableData from "core/hooks/useTableData";

import { useConfirm, usePopup } from "core/contexts/useWindow";
import { NoLabelCheckForm, InputForm, SelectForm, TextareaForm, ImageForm, ToggleButtonForm } from "components/common/CommonForm";
import { dateFormat, getFormatDatetime, getFormatStringToDate } from "utils/date/DateUtil";
// 셀렉트 벨류 constant
import { useCountPerPage } from "constants/select/useValue";
// 공용 팝업
// style import
import { MainBtn, RedBtn, WhiteBtn } from "styles/useButtonStyles";
import { IconLink, PositionBox } from "styles/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "styles/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { AreaHeader, Column, FilterBox, FormBox, Row } from "styles/custom/useCustomLayout";
import { isEmpty } from "lodash";
import { FilterTitle } from "styles/custom/useCustomText";
import useFormData from "core/hooks/useFormData";
import NoDataTable from "components/common/NoDataTable";
import { useCommonState } from "core/contexts/useCommonState";
import useStateData from "core/hooks/useStateData";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import { dbBoardEvent, dbBoardNotice } from "constants/enum/db/useBoardEnum";
import CalendarFilter from "components/common/CalendarFilter";
import styled from "styled-components";

// Wrapper component to handle disabled state
const DisabledWrapper = styled.div`
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

const EventBoard = () => {
  const { t } = useTranslation();
  const useTG = useTableData(true);
  const useStated = useStateData();

  const { openPopup } = usePopup();
  const { setLoading } = useCommonState();

  const showPopup = (item, isModify) => {
    openPopup({
      title: isModify ? t("eventBoard.manage_post") : t("eventBoard.register_event"),
      content: EventPopup,
      props: {
        item,
        isModify,
        getTableItem,
      },
    });
  };

  const isActiveEvent = (item) => {
    if (!item) return false;
    const currentDate = new Date();
    return (
      item.state === dbBoardNotice.type.O.value ||
      (item.state === dbBoardNotice.type.A.value && item.end_date > getFormatStringToDate(currentDate) > item.start_date)
    );
  };

  const getTableItem = () => {
    setLoading(true);
    api
      .get("/v1/cs-management/event", {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useStated.setSuccessData([res.data.content]);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 400) {
          useStated.setErrorData(error.response.data.errors);
        }
        console.error(error);
      });
  };

  const deleteEvent = (no) => {
    const checkedList = useTG.data.filter((item) => item.isChecked);
    const no_list = no ? [no] : checkedList.map((item) => item.no);
    api
      .delete(`/v1/cs-management/event`, { params: { no_list: no_list } })
      .then((res) => {
        getTableItem();
      })
      .catch((error) => {
        alert(t("eventBoard.error") + error);
        console.error(error);
      });
  };

  // 게시 중단
  const stopEvent = () => {
    const checkedList = useTG.data.filter((item) => item.isChecked);
    const no_list = checkedList.map((item) => item.no);
    const allInactiveEvent = checkedList.every((item) => !isActiveEvent(item));

    if (no_list.length === 0 || allInactiveEvent) return;

    api
      .put(`/v1/cs-management/event/stop`, { no_list: no_list })
      .then((res) => {
        getTableItem();
      })
      .catch((error) => {
        alert(t("eventBoard.error") + error);
        console.error(error);
      });
  };

  const useSearch = useFormData(
    {
      search_post_state: "",
      search_title: "",

      search_date_state: "",
      date_period: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.operate_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("eventBoard.event_board")}</AreaTitle>
      </AreaHeader>

      <Column>
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
            <Column $gap="4px">
              <FilterTitle>{t("eventBoard.post_state")}</FilterTitle>
              <SelectForm
                name="search_post_state"
                placeholder={t("common.select")}
                options={dbBoardNotice.provider_type}
                {...useSearch}
                {...useStated}
              />
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("eventBoard.title")}</FilterTitle>
              <InputForm type="text" name="search_title" placeholder={t("common.input")} {...useSearch} />
            </Column>
            <Row $gap="4px">
              <Column $gap="4px">
                <FilterTitle>{t("eventBoard.post_date")}</FilterTitle>
                <Row $gap="4px">
                  <SelectForm
                    name="search_date_state"
                    placeholder={t("common.select")}
                    options={dbBoardNotice.search_date_state}
                    {...useSearch}
                    {...useStated}
                  />
                  <CalendarFilter endName="end_date" date_period="date1" startName="start_date" {...useSearch} end />
                </Row>
              </Column>
            </Row>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
              {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
              {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>
        <Row $gap="12px">
          <Row className="total-count" $gap="4px" $pad="14px 0 0 0">
            <TextBox $font="var(--f-subB)">{t("eventBoard.total_events")}</TextBox>
            <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
              {useTG.error ? (
                <span>error</span>
              ) : (
                <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
              )}
            </TextBox>
            <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
          </Row>
        </Row>

        <Row $jus="space-between" $width="100%" $align="center" $pad="14px 0">
          <Row $gap="8px">
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => stopEvent()}>
              {t("eventBoard.stop_post")}
            </RedBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => deleteEvent()}>
              {t("common.delete")}
            </RedBtn>
          </Row>
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
              {t("eventBoard.register_event")}
            </MainBtn>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            {
              <Table className="table-box">
                <thead>
                  <tr className="table-title">
                    <TableHeader $width="34px">
                      <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                    </TableHeader>
                    <TableHeader $width="60px">
                      <TextBox>No.</TextBox>
                    </TableHeader>
                    <TableHeader $width="130px">{t("eventBoard.event_title")}</TableHeader>
                    <TableHeader $width="130px">{t("eventBoard.start_date")}</TableHeader>
                    <TableHeader $width="130px">{t("eventBoard.end_date")}</TableHeader>
                    <TableHeader $width="130px">{t("eventBoard.views")}</TableHeader>
                    <TableHeader>{t("eventBoard.post_state")}</TableHeader>
                    <TableHeader $width="130px">{t("eventBoard.author")}</TableHeader>
                    <TableHeader $width="130px">{t("eventBoard.manage_post")}</TableHeader>
                  </tr>
                </thead>
                {useTG && useTG.data?.length > 0 && (
                  <tbody>
                    {useTG.data &&
                      useTG.data.map((item, index) => {
                        const number = useTG.pagingInfo.offset - index;
                        return (
                          <TableRow key={number} className="table-content">
                            <TableDeps>
                              <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                            </TableDeps>
                            <TableDeps $width="60px">{number || "-"}</TableDeps>
                            <TableDeps $width="20%" $tAlign="left">
                              {item.title}
                            </TableDeps>
                            <TableDeps $width="130px">{getFormatDatetime(item.start_date)}</TableDeps>
                            <TableDeps $width="130px">{getFormatDatetime(item.end_date) ?? t("eventBoard.no_limit")}</TableDeps>
                            <TableDeps $width="130px" $tAlign="right">
                              {item.view_count?.toLocaleString()}
                            </TableDeps>
                            <TableDeps $width="130px">
                              {isActiveEvent(item) ? t("eventBoard.active") : t("eventBoard.inactive")}
                            </TableDeps>
                            <TableDeps $width="20%" $tAlign="left">
                              {`${item.nickname} (${item.create_no})` ?? "-"}
                            </TableDeps>
                            <TableDeps $width="60px">
                              <Row $width="100%" $jus="center" $gap="6px">
                                <ModifyBtn name={t("eventBoard.manage")} eventProps={() => showPopup(item, true)} />
                              </Row>
                            </TableDeps>
                          </TableRow>
                        );
                      })}
                  </tbody>
                )}
              </Table>
            }
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}

        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="space-between" $width="100%">
          <Row $gap="8px">
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => stopEvent()}>
              {t("eventBoard.stop_post")}
            </RedBtn>
            <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => deleteEvent()}>
              {t("common.delete")}
            </RedBtn>
          </Row>
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
              {t("eventBoard.register_event")}
            </MainBtn>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              top="top"
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};

const EventPopup = ({ item, isModify, getTableItem }) => {
  const { t } = useTranslation();
  const { closePopup } = usePopup();
  const useStated = useStateData();

  const [modifyState, setModifyState] = useState(false);

  const useSearch = useFormData({
    no: item?.no,
    title: item?.title ?? "",
    state: item?.state ?? "",
    contents: item?.contents ?? "",
    img_uuid: item?.img_uuid ?? "",
    img_data: {
      img_data: item?.img_data,
      img_uuid: item?.img_uuid,
      img_size: item?.img_size,
      img_exec: item?.img_exec,
      img_name: item?.img_name,
    },
    start_now: item?.start_date ? false : true,
    start_datetime: item?.start_date || null,
    endless: item?.end_date ? false : true,
    // CalendarFilter 에서 end_date 와 이름이 겹쳐서 오류 발생 때문에 end_datetime 으로 변경
    end_datetime: item?.end_date || null,
  });

  const initFormValue = {
    no: item?.no,
    title: item?.title ?? "",
    state: item?.state ?? "",
    contents: item?.contents ?? "",
    img_uuid: item?.img_uuid ?? "",
    img_data: {
      img_data: item?.img_data,
      img_uuid: item?.img_uuid,
      img_size: item?.img_size,
      img_exec: item?.img_exec,
      img_name: item?.img_name,
    },
    start_now: item?.start_date ? false : true,
    start_datetime: item?.start_date || null,
    endless: item?.end_date ? false : true,
    // CalendarFilter 에서 end_date 와 이름이 겹쳐서 오류 발생 때문에 end_datetime 으로 변경
    end_datetime: item?.end_date || null,
  };

  const startDateToggleOption = [
    {
      label: t("eventBoard.immediate"),
      value: true,
    },
    {
      label: t("eventBoard.scheduled"),
      value: false,
    },
  ];

  const endDateToggleOption = [
    {
      label: t("eventBoard.no_limit"),
      value: true,
    },
    {
      label: t("eventBoard.set_period"),
      value: false,
    },
  ];

  useEffect(() => {
    if (!isModify) {
      setModifyState(true);
    }
  }, []);

  const toggleModify = () => {
    if (modifyState) {
      useSearch.setFormValue(initFormValue);
    }
    setModifyState(!modifyState);
  };

  const confirmBtn = () => {
    if (!modifyState) {
      closePopup();
      return;
    }
    if (isModify) {
      api
        .put(`/v1/cs-management/event/modify/${item?.no}`, {
          ...useSearch.formValue,
          start_date: getFormatDatetime(useSearch.formValue.start_datetime),
          end_date: getFormatDatetime(useSearch.formValue.end_datetime),
        })
        .then((res) => {
          closePopup();
          getTableItem();
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            useStated.setErrorData(error.response.data.errors);
          }
          console.error(error);
        });
    } else {
      api
        .post(`/v1/cs-management/event`, {
          ...useSearch.formValue,
          start_date: getFormatDatetime(useSearch.formValue.start_datetime),
          end_date: getFormatDatetime(useSearch.formValue.end_datetime),
        })
        .then((res) => {
          closePopup();
          getTableItem();
        })
        .catch((error) => {
          if (error.response?.status === 400) {
            useStated.setErrorData(error.response.data.errors);
          }
          console.error(error);
        });
    }
  };

  const deleteEvent = () => {
    if (!isModify) return;
    api
      .delete(`/v1/cs-management/event`, { params: { no_list: [item?.no] } })
      .then((res) => {
        closePopup();
        getTableItem();
      })
      .catch((error) => {
        alert(t("eventBoard.error") + error);
        console.error(error);
      });
  };

  return (
    <>
      <Column $width="800px" $gap="30px" $pad="20px">
        <FormBox $gap="12px" $align="flex-start">
          <FormBox $flex="1">
            <AreaTitle>{t("eventBoard.title")} : </AreaTitle>
          </FormBox>
          <FormBox $flex="5">
            <InputForm
              name="title"
              width="100%"
              minWidth="100%"
              maxWidth="100%"
              readOnly={!modifyState}
              {...useSearch}
              {...useStated}
            />
          </FormBox>
        </FormBox>
        <FormBox $align="flex-start">
          <FormBox $flex="1">
            <AreaTitle>{t("eventBoard.contents")} : </AreaTitle>
          </FormBox>
          <FormBox $flex="5">
            <TextareaForm
              name="contents"
              width="100%"
              height="200px"
              minWidth="100%"
              maxWidth="100%"
              readOnly={!modifyState}
              {...useSearch}
              {...useStated}
            ></TextareaForm>
          </FormBox>
        </FormBox>
        <FormBox $align="flex-start">
          <FormBox $flex="1">
            <AreaTitle>{t("eventBoard.attachment")} :</AreaTitle>
          </FormBox>
          <FormBox $flex="5">
            <DisabledWrapper disabled={!modifyState}>
              <ImageForm name="img_uuid" groupNm="img_data" width="60px" height={"60px"} {...useSearch} {...useStated} />
            </DisabledWrapper>
          </FormBox>
        </FormBox>
        <FormBox $align="flex-start">
          <FormBox $flex="1">
            <AreaTitle>{t("eventBoard.post_type")} :</AreaTitle>
          </FormBox>
          <FormBox $flex="5">
            <DisabledWrapper disabled={!modifyState}>
              <ToggleButtonForm name="start_now" options={startDateToggleOption} {...useSearch} {...useStated} />
            </DisabledWrapper>
            <DisabledWrapper disabled={!modifyState || useSearch.formValue.start_now}>
              <CalendarFilter startName="start_datetime" top {...useSearch} start />
            </DisabledWrapper>
          </FormBox>
        </FormBox>
        <FormBox $align="flex-start">
          <FormBox $flex="1">
            <AreaTitle>{t("eventBoard.post_period")} :</AreaTitle>
          </FormBox>
          <FormBox $flex="5">
            <DisabledWrapper disabled={!modifyState}>
              <ToggleButtonForm name="endless" options={endDateToggleOption} {...useSearch} {...useStated} />
            </DisabledWrapper>
            <DisabledWrapper disabled={!modifyState || useSearch.formValue.endless}>
              <CalendarFilter startName="end_datetime" top {...useSearch} {...useStated} start />
            </DisabledWrapper>
          </FormBox>
        </FormBox>
        <FormBox $align="flex-start">
          <FormBox $flex="1">
            <AreaTitle>{t("eventBoard.post_state")} :</AreaTitle>
          </FormBox>
          <FormBox $flex="5">
            <DisabledWrapper disabled={!modifyState}>
              <SelectForm
                top="auto"
                name="state"
                placeholder={t("eventBoard.post_state")}
                options={dbBoardNotice.type}
                {...useSearch}
                {...useStated}
              />
            </DisabledWrapper>
          </FormBox>
        </FormBox>
        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <FormBox $gap="10px" $jus="right" $width="100%">
            {isModify ? (
              <>
                <WhiteBtn $size="var(--s-table)" $line="var(--l-table)" onClick={toggleModify}>
                  {modifyState ? t("common.cancel") : t("common.modify")}
                </WhiteBtn>
                <RedBtn $size="var(--s-table)" $line="var(--l-table)" onClick={deleteEvent}>
                  {t("common.delete")}
                </RedBtn>
                <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={confirmBtn}>
                  {modifyState ? t("common.save") : t("common.check")}
                </MainBtn>
              </>
            ) : (
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={confirmBtn}>
                {t("eventBoard.register_post")}
              </MainBtn>
            )}
          </FormBox>
        </PositionBox>
      </Column>
    </>
  );
};

export default EventBoard;
