import useFormData from "core/hooks/useFormData";
import { InputForm, TextareaForm } from "components/common/CommonForm";
import React from "react";
import { Column, FormBox, Row } from "styles/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { PositionBox } from "styles/useLayoutStyles";
import { TextBox } from "styles/useTextStyles";

import api from "core/services/api";
import { useAlert, usePopup } from "core/contexts/useWindow";
import useStateData from "core/hooks/useStateData";
import { t } from "i18next";

const AgentBoardQnaForm = ({ getTableItem }) => {
  const useStated = useStateData();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();

  const insertQna = () => {
    const apiUrl = "/v1/agent/qna";
    api
      .post(apiUrl, {
        ...useForm.formValue,
      })
      .then(() => {
        showFormSuccessAlert();
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        showFormFailAlert();
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  const showFormSuccessAlert = () => {
    openAlert({
      title: t("common.add_msg"),
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn:  t("common.check"),
    });
  };

  const showFormFailAlert = () => {
    openAlert({
      title: t("common.add_fail"), 
      message:  t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const useForm = useFormData({
    question_title: "",
    question_contents: "",
  });

  return (
    <>
      <Column className="area-box" $gap="20px" $width="650px">
        <FormBox $align="flex-start">
          <TextBox $width="120px" $minWidth="120px">
            {t("agentQna.title")}
          </TextBox>
          <InputForm
            width="500px"
            maxWidth="100%"
            type="text"
            name="question_title"
            placeholder={t("common.enter_title_plh")}
            maxLength="255"
            {...useForm}
            {...useStated}
          />
        </FormBox>

        <FormBox $align="flex-start">
          <TextBox $width="0px" $minWidth="120px">
          {t("agentQna.contents")}
          </TextBox>
          <Column $width="100%" $gap="4px">
            <TextareaForm
              height="250px"
              width="500px"
              type="text"
              name="question_contents"
              placeholder=  {t("common.enter_value")} 
              maxLength="255"
              {...useForm}
              {...useStated}
            />
          </Column>
        </FormBox>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={insertQna}>
             {t("common.add")} 
            </MainBtn>

            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.close")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentBoardQnaForm;
