import React from "react";

import { Outlet, Navigate } from "react-router-dom";
import { dbAuth } from "constants/enum/db/useAuthEnum";
import { t } from "i18next";

function PrivateRoute() {
  let isLogin = false;

  const info = JSON.parse(localStorage.getItem("auth-admin"));
  try {
    const envProfileType = process.env.REACT_APP_TYPE?.trim();

    const hasPermission = () => {
      const type = info.type;
      switch (envProfileType) {
        case "ADMIN":
          return type === dbAuth.type.A.value;
        case "AGENT":
          return type === dbAuth.type.G.value || type === dbAuth.type.D.value;
        case "ALL":
          return type === dbAuth.type.A.value || type === dbAuth.type.G.value || type === dbAuth.type.D.value;
        default:
          // TODO  envProfile 없으면 접근 못하게 막기
          return type === dbAuth.type.A.value || type === dbAuth.type.G.value;
        // return false;
      }
    };

    if (!hasPermission()) {
      alert(t("error.noAdminAccess"));
      localStorage.removeItem("auth-admin");
      isLogin = false;
    } else {
      isLogin = true;
    }
  } catch (error) {
    localStorage.removeItem("auth-admin");
    isLogin = false;
    // 토큰이 올바르지 않거나 해독 중 오류가 발생한 경우
    return <Navigate to='/login' />;
  }

  return isLogin ? <Outlet /> : <Navigate to='/login' />;
}

export default PrivateRoute;
