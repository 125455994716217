import React from "react";

// 공용
import api from "core/services/api";

import useTableData from "core/hooks/useTableData";

import { useCommonState } from "core/contexts/useCommonState";
// style import
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { PositionBox } from "styles/useLayoutStyles";
import { TextBox } from "styles/useTextStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { Column, FilterBox, Row } from "styles/custom/useCustomLayout";
import { getFormatDatetime } from "utils/date/DateUtil";
import { FilterTitle } from "styles/custom/useCustomText";
import { SelectForm } from "components/common/CommonForm";
import useFormData from "core/hooks/useFormData";
import Pagination from "utils/Pagination";
import { isEmpty } from "lodash";
import CalendarFilter from "components/common/CalendarFilter";
import { usePopup } from "core/contexts/useWindow";
import { addAllFromEnum, getLogCommissionUpdateMeaningByAgentAdmin } from "utils/common/EnumUtil";
import { dbLogCommissionUpdate } from "constants/enum/db/useLogEnum";
import { useCountPerPage } from "constants/select/useValue";
import NoDataTable from "components/common/NoDataTable";
import { t } from "i18next";

const LogCommission = ({ agentNo }) => {
  const { setLoading } = useCommonState();

  const { closePopup } = usePopup();

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/agent/info/log-commission/${agentNo}`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.searchValue,
          ...useTG.extendData.pagingData,
          sortNm: "no-desc",
        },
      })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        useTG.setError("");
      })
      .catch((err) => {
        useTG.setError(true);
        useTG.setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useTG = useTableData(true);

  const useSearch = useFormData(
    {
      search_updated_type: "",
      start_date: "",
      end_date: "",
      date_period: "",

    },
    getTableItem,
    useTG
  );

  // 조회 버튼

  return (
    <>
      <Column $width="900px">
        <FilterBox $pad="12px">
          <Row $gap="12px" $align="center">
            <Column $gap="4px">
              <FilterTitle>{t("agent.modify_at")}</FilterTitle>
              <Row $gap="4px">
                <CalendarFilter {...useSearch} end />
              </Row>
            </Column>
            <Column $gap="4px">
              <FilterTitle>{t("agent.modity_list")}</FilterTitle>
              <Row $gap="4px">
                <Row $gap="4px">
                  <SelectForm
                    name="search_updated_type"
                    placeholder={t("common.all")}
                    options={addAllFromEnum(dbLogCommissionUpdate.updated_type)}
                    {...useSearch}
                  />
                </Row>
              </Row>
            </Column>
          </Row>

          <Row $gap="8px">
            <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
            {t("filter.search")}
            </MainBtn>
            <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
            {t("filter.refresh")}
            </WhiteBtn>
          </Row>
        </FilterBox>

        <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
          <Row $gap="10px" $align="center">
            <Row className="total-count" $gap="4px">
              <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
              <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                {useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords.toLocaleString() : 0}
              </TextBox>
              <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
            </Row>
          </Row>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
            />
          </Row>
        </Row>

        {useTG.error ? (
          <span>error</span>
        ) : (
          <>
            <Table className="table-box">
              <thead>
                <tr className="table-title">
                  <TableHeader $width="60px">{t("common.no")}</TableHeader>
                  <TableHeader $width="150px">{t("agent.modify_at")}</TableHeader>
                  <TableHeader>{t("agent.modity_admin")}</TableHeader>
                  <TableHeader>{t("agent.modity_list")}</TableHeader>
                  <TableHeader>{t("agent.modify_before")}</TableHeader>
                  <TableHeader>{t("agent.modify_after")}</TableHeader>
                </tr>
              </thead>
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => (
                    <TableRow key={item.no} className="table-content">
                      <TableDeps>{useTG?.pagingInfo?.offset - index}</TableDeps>
                      <TableDeps>
                        <TextBox $size="var(--s-sub)" $line="var(--l-sub)" $pad="2px">
                          {getFormatDatetime(item.update_time)}
                        </TextBox>
                      </TableDeps>
                      <TableDeps $tAlign="left">{`${item.nickname}(${item.auth_no})`}</TableDeps>
                      <TableDeps $tAlign="left">
                        {dbLogCommissionUpdate.updated_type[item.updated_type]?.label || item.updated_type}
                      </TableDeps>
                      <TableDeps>{getLogCommissionUpdateMeaningByAgentAdmin(item.updated_type, item.before_update)}</TableDeps>
                      <TableDeps>{getLogCommissionUpdateMeaningByAgentAdmin(item.updated_type, item.after_update)}</TableDeps>
                    </TableRow>
                  ))}
              </tbody>
            </Table>
            {useTG && useTG.data?.length === 0 && <NoDataTable />}
          </>
        )}
        <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
          <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
            {useTG && useTG.data?.length !== 0 && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
          </PositionBox>

          <Row $gap="8px">
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useCountPerPage.value}
              top="top"
            />
          </Row>
        </Row>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default LogCommission;
