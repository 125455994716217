import { t } from "i18next";
import React from "react";
import { NoSearchTable } from "styles/custom/useCustomLayout";
import { NoSearchText } from "styles/custom/useCustomText";

const NoDataTable = ({ title }) => {
  return (
    <NoSearchTable>
      <NoSearchText className="non-search-text">{title ? title : t("common.noData")}</NoSearchText>
    </NoSearchTable>
  );
};

export default NoDataTable;
