import styled from "styled-components";
const { TextBox } = require("styles/useTextStyles");

const SideTitle = styled(TextBox)``;
const SideMenu = styled(TextBox)``;
const FilterTitle = styled(TextBox)`
  color: var(--c-gray-700);
  font-size: var(--s-caption);
  line-height: var(--l-caption);
`;

const NoSearchText = styled(TextBox)`
  color: var(--c-gray-700);
`;

const HoverTableText = styled(TextBox)`
  color: var(--c-mint-600);
  font-size: var(--s-table);
  cursor: pointer;
  position: relative;

  &:hover {
    text-decoration: underline;
  }
`;

export { SideMenu, SideTitle, FilterTitle, NoSearchText, HoverTableText };
