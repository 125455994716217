import { format } from "date-fns";
import React, { useState } from "react";
import { Row } from "styles/custom/useCustomLayout";
import { TextBox } from "styles/useTextStyles";

import styled from "styled-components";

const MonthConstant = [
  { id: 1, name: "1월" },
  { id: 2, name: "2월" },
  { id: 3, name: "3월" },
  { id: 4, name: "4월" },
  { id: 5, name: "5월" },
  { id: 6, name: "6월" },
  { id: 7, name: "7월" },
  { id: 8, name: "8월" },
  { id: 9, name: "9월" },
  { id: 10, name: "10월" },
  { id: 11, name: "11월" },
  { id: 12, name: "12월" },
];

const Table = styled.table`
  border-spacing: 0;
`;

const WhiteLayoutBox = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  padding: 20px;
  z-index: 999;
  background-color: var(--c-white);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);

  width: fit-content;
  user-select: none;

  margin-top: 6px;
  gap: 16px;
  display: flex;
  flex-direction: column;

  &.top {
    bottom: ${(props) => props.$bottom};
    top: initial;
    margin-bottom: 6px;
  }
`;

const TableHeader = styled.th`
  font-family: var(--f-subB);
  font-size: var(--s-sub);
  color: var(--c-black-op);
  width: 36px;
  height: 36px;
  vertical-align: middle;
  border: none;

  text-align: center;

  &.sunday {
    color: var(--c-red);
  }
  &.saturday {
    color: var(--c-mint-600);
  }
`;

const CalendarDay = styled.td`
  font-family: var(--f-text);
  font-size: var(--s-text);
  line-height: var(--l-text);
  color: var(--c-gray-900);
  width: 36px;
  height: 36px;
  min-width: 36px;
  vertical-align: middle;
  border: none;
  text-align: center;

  &.impossible_select:hover {
    background-color: transparent;
  }

  &:hover {
    background-color: var(--c-gray-200);
    border-radius: 4px;
    cursor: pointer;

    & > div {
      color: var(--c-gray-900);
    }
  }

  &.impossible_select div {
    color: var(--c-gray-300);
    cursor: initial;
  }

  &.start-date,
  &.end-date {
    background-color: var(--c-mint-600);
    color: var(--c-white);

    border-radius: 4px;

    & > div {
      color: var(--c-white);
    }
  }
`;

const Day = styled.div`
  font-family: var(--f-text);
  font-size: var(--s-text);
  line-height: var(--l-text);

  &.in-range {
    background-color: var(--c-mint-50);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

const MonthMove = styled.div`
  background: url(/assets/svg/month_move.svg);
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--c-input);
    border-radius: 4px;
  }

  &.next-month {
    rotate: 180deg;
  }
  &.prev-month {
    rotate: 0deg;
  }
  &.not-found-day:hover {
    background-color: transparent;
  }
  &.not-found-day {
    cursor: initial;

    opacity: 0.3;
  }
`;

const YearMove = styled.div`
  background: url(/assets/svg/year_move.svg);
  width: 24px;
  height: 24px;
  cursor: pointer;

  &:hover {
    background-color: var(--c-input);
    border-radius: 4px;
  }

  &.prev-year {
    rotate: 0deg;
  }
  &.next-year {
    rotate: 180deg;
  }

  &.not-found-day:hover {
    background-color: transparent;
  }

  &.not-found-day {
    cursor: initial;
    opacity: 0.3;
  }
`;

const Calendar = ({ endDate, dateType, onToggle, startDate, setStartDate, setEndDate, top, options = "false" }) => {
  const currentDate = new Date();
  const defaultDate = new Date(2023, 0, 1);
  const [today, setToday] = useState(currentDate);

  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
  const prevMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
  const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
  const prevYear = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());

  const monthToggle = (newDate) => {
    if (options && newDate >= defaultDate) {
      setToday(newDate);
    } else {
      if (newDate >= defaultDate && newDate <= currentDate) setToday(newDate);
    }
  };

  const yearToggle = (newDate) => {
    if (newDate >= defaultDate && newDate <= currentDate) {
      setToday(newDate);
    }
  };

  const nextYearToggle = () => {
    if (nextYear <= currentDate) {
      yearToggle(nextYear);
    } else {
      yearToggle(currentDate);
    }
  };

  const prevYearToggle = () => yearToggle(prevYear);
  const nextMonthToggle = () => monthToggle(nextMonth);
  const prevMonthToggle = () => monthToggle(prevMonth);

  // 연월일 시간 비교 같은 날짜 클릭 시 클래스 삭제
  const isSameDate = (clickedDate, selectDate) => clickedDate === selectDate;

  const isDisabledDate = (clickedDate) => {
    if (options) return false;
    return clickedDate > currentDate;
  };

  const updateDates = (newStartDate, newEndDate) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const dateClick = (clickedDateNumber) => {
    const clickedDate = createClickedDate(clickedDateNumber);
    const formattedClickedDate = format(clickedDate, "yyyy-MM-dd");

    if (isDisabledDate(clickedDate)) {
      return;
    }

    if (dateType === "start") {
      updateDates(formattedClickedDate, endDate);
      if (formattedClickedDate === startDate) {
        updateDates(null, endDate);
      } else {
        if (!endDate || formattedClickedDate < endDate) {
          updateDates(formattedClickedDate, endDate);
        } else {
          updateDates(endDate, formattedClickedDate);
        }
      }
    } else if (dateType === "end") {
      if (!startDate || formattedClickedDate > startDate) {
        updateDates(startDate, formattedClickedDate);
      } else if (formattedClickedDate === endDate) {
        updateDates(startDate, null);
      } else {
        updateDates(formattedClickedDate, startDate);
      }
    }
  };

  const createClickedDate = (dayToRender) => {
    return new Date(today.getFullYear(), today.getMonth(), dayToRender);
  };

  const createCal = () => {
    let calendarDate = 1;
    const calendarRows = [];

    const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const daysInMonth = lastDate.getDate();
    const firstDayOfWeek = firstDate.getDay();

    for (let week = 0; calendarDate <= daysInMonth; week++) {
      const calendarRow = [];

      for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
        if (week === 0 && dayOfWeek < firstDayOfWeek) {
          calendarRow.push(<td key={`empty-${week}-${dayOfWeek}`} />);
        } else if (calendarDate > daysInMonth) {
          calendarRow.push(<td key={`empty-${week}-${dayOfWeek}`} />);
        } else {
          const dayToRender = calendarDate;

          const nextDate = new Date(today.getFullYear(), today.getMonth(), calendarDate);
          const clickedDate = new Date(today.getFullYear(), today.getMonth(), dayToRender);

          const clickFormats = format(clickedDate, "yyyy-MM-dd");

          const isFuture = options ? false : currentDate < nextDate;
          const isEndDate = endDate && isSameDate(endDate, clickFormats);
          const isStartDate = startDate && isSameDate(startDate, clickFormats);
          const isInRange = startDate && endDate && clickFormats > startDate && clickFormats < endDate;

          let classNames = [];
          let rangeClassed = [];

          if (isFuture) classNames.push("impossible_select");
          if (isStartDate) classNames.push("start-date");
          if (isEndDate) classNames.push("end-date");
          if (isInRange) rangeClassed.push("in-range");

          const className = classNames.join(" ");
          const rangeClass = rangeClassed.join(" ");

          calendarRow.push(
            <CalendarDay
              key={`day-${dayToRender}`}
              className={className}
              onClick={() => {
                dateClick(dayToRender);
                onToggle(false);
              }}
              disabled={isFuture}
            >
              <Day className={`day_select ${rangeClass}`}>{dayToRender}</Day>
            </CalendarDay>
          );
          calendarDate++;
        }
      }
      calendarRows.push(<tr key={`week-${week}`}>{calendarRow}</tr>);
    }

    return (
      <Table className="calendar-table">
        <thead className="calendar_weekly">
          <tr className="weekly_day-box">
            <TableHeader className="sunday">일</TableHeader>
            <TableHeader>월</TableHeader>
            <TableHeader>화</TableHeader>
            <TableHeader>수</TableHeader>
            <TableHeader>목</TableHeader>
            <TableHeader>금</TableHeader>
            <TableHeader className="saturday">토</TableHeader>
          </tr>
        </thead>
        <tbody>{calendarRows}</tbody>
      </Table>
    );
  };
  const MONTHS = MonthConstant.map((month, index) => {
    return { [`month${index + 1}`]: month };
  });

  const currentMonthArr = MONTHS[today.getMonth()];
  const currentMonth = currentMonthArr[`month${today.getMonth() + 1}`];

  return (
    <>
      <WhiteLayoutBox className={`calendar_box ${top ? "top" : ""}`} $bottom="110%">
        <Row className="move-box" $gap="8px" $align="center" $jus="space-between" $width="100%">
          <Row $align="center">
            <YearMove
              className={`prev-year ${prevYear >= defaultDate ? "" : "not-found-day"}`}
              onClick={prevYearToggle}
            ></YearMove>
            <MonthMove className={`prev-month `} onClick={prevMonthToggle}></MonthMove>
          </Row>

          <TextBox className="month" key={currentMonth.id} $size="var(--s-title)" $font="var(--f-title)" $line="var(--l-title)">
            {`${today.getFullYear()}년 ${currentMonth.name}`}
          </TextBox>

          <Row $align="center">
            <MonthMove
              className={`next-month ${options || nextMonth <= currentDate ? "" : "not-found-day"}`}
              onClick={nextMonthToggle}
            ></MonthMove>
            <YearMove
              className={`next-year ${options || today.getFullYear() < currentDate.getFullYear() ? "" : "not-found-day"}`}
              onClick={nextYearToggle}
            ></YearMove>
          </Row>
        </Row>
        {createCal()}
      </WhiteLayoutBox>
    </>
  );
};

export default Calendar;
