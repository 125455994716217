import { t } from "i18next";
import i18n from "constants/locales/i18n";

const dbSiteAccount = {
  account_yn: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  user_state1: {
    S: {
      label: "정상회원",
      value: "S",
      key: "S",
    },
    B: {
      label: "차단회원",
      value: "B",
      key: "B",
    },
    O: {
      label: "탈퇴회원",
      value: "O",
      key: "O",
    },
    W: {
      label: "승인대기",
      value: "W",
      key: "W",
    },
    T: {
      label: "테스트",
      value: "T",
      key: "T",
    },
    E: {
      label: "나머지",
      value: "E",
      key: "E",
    },
  },
};

const dbSiteConfig = {
  api_type: {
    S: {
      label: "Seamless",
      value: "S",
      key: "S",
    },
    T: {
      label: "Transfer",
      value: "T",
      key: "T",
    },
  },
  auto_logout: {
    10: {
      label: "10분",
      value: 10,
      key: "10",
    },
    20: {
      label: "20분",
      value: 20,
      key: "20",
    },
    30: {
      label: "30분",
      value: 30,
      key: "30",
    },
    40: {
      label: "40분",
      value: 40,
      key: "40",
    },
    50: {
      label: "50분",
      value: 50,
      key: "50",
    },
    60: {
      label: "60분",
      value: 60,
      key: "60",
    },
  },
  plan_inspec_yn: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  inspec_yn: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  inspec_time_unlimited_yn: {
    Y: {
      label: "무제한",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "시간설정",
      value: "N",
      key: "N",
    },
  },
  user_level_yn: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  user_max_level: {
    0: {
      label: "1단계 (Lv.0)",
      value: 0,
      key: "0",
    },
    1: {
      label: "2단계 (Lv.0~Lv.1)",
      value: 1,
      key: "1",
    },
    2: {
      label: "3단계 (Lv.0~Lv.2)",
      value: 2,
      key: "2",
    },
    3: {
      label: "4단계 (Lv.0~Lv.3)",
      value: 3,
      key: "3",
    },
    4: {
      label: "5단계 (Lv.0~Lv.4)",
      value: 4,
      key: "4",
    },
    5: {
      label: "6단계 (Lv.0~Lv.5)",
      value: 5,
      key: "5",
    },
    6: {
      label: "7단계 (Lv.0~Lv.6)",
      value: 6,
      key: "6",
    },
    7: {
      label: "8단계 (Lv.0~Lv.7)",
      value: 7,
      key: "7",
    },
    8: {
      label: "9단계 (Lv.0~Lv.8)",
      value: 8,
      key: "8",
    },
    9: {
      label: "10단계 (Lv.0~Lv.9)",
      value: 9,
      key: "9",
    },
  },
  user_auto_level_yn: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  site_multi_account_yn: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  site_max_account: {
    1: {
      label: "1개",
      value: 1,
      key: "1",
    },
    2: {
      label: "2개",
      value: 2,
      key: "3",
    },
    3: {
      label: "3개",
      value: 3,
      key: "3",
    },
    4: {
      label: "4개",
      value: 4,
      key: "4",
    },
    5: {
      label: "5개",
      value: 5,
      key: "5",
    },
    6: {
      label: "6개",
      value: 6,
      key: "6",
    },
    7: {
      label: "7개",
      value: 7,
      key: "7",
    },
    8: {
      label: "8개",
      value: 8,
      key: "8",
    },
    9: {
      label: "9개",
      value: 9,
      key: "9",
    },
    10: {
      label: "10개",
      value: 10,
      key: "10",
    },
  },
  account_in_limit_time_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  account_out_limit_time_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  account_out_limit_rate_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  operation_yn: {
    Y: {
      label: "활성화",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "비활성화",
      value: "N",
      key: "N",
    },
  },
  allow_ip_yn: {
    Y: {
      label: "활성화",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "비활성화",
      value: "N",
      key: "N",
    },
  },
  is_exchange_time_limit_agent: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  is_exchange_time_limit_user: {
    Y: {
      label: "사용",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "미사용",
      value: "N",
      key: "N",
    },
  },
  signup_wait_limit: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  popup_location_type: {
    C: {
      label: "중앙 정렬",
      value: "C",
      key: "C",
    },
    F: {
      label: "위치 지정",
      value: "F",
      key: "F",
    },
  },
  alarm_delay_time: {
    1: {
      label: "1초",
      value: 1,
      key: "1",
    },
    3: {
      label: "3초",
      value: 3,
      key: "3",
    },
    5: {
      label: "5초",
      value: 5,
      key: "5",
    },
    10: {
      label: "10초",
      value: 10,
      key: "10",
    },
    30: {
      label: "30초",
      value: 30,
      key: "30",
    },
    60: {
      label: "60초",
      value: 60,
      key: "60",
    },
  },
};

const dbSitePointConfig = {
  sign_up_bonus_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  sign_up_bonus_change: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  charge_bonus_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  member_rolling_point_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  member_losing_point_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  referrer_rolling_point_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  referrer_losing_point_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  point_change_time_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  point_to_cash_time_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  exchange_first_charge_yn: {
    Y: {
      label: i18n.t("common.pay"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.unpay"),
      value: "N",
      key: "N",
    },
  },
  exchange_every_charge_yn: {
    Y: {
      label: i18n.t("common.pay"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.unpay"),
      value: "N",
      key: "N",
    },
  },
  hold_first_charge_yn: {
    Y: {
      label: i18n.t("common.pay"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.unpay"),
      value: "N",
      key: "N",
    },
  },
  hold_every_charge_yn: {
    Y: {
      label: i18n.t("common.pay"),
      value: "Y",
      key: "Y",
    },
    N: {
      label: i18n.t("common.unpay"),
      value: "N",
      key: "N",
    },
  },
};

const dbSitePopupMng = {
  guest_view_yn: {
    Y: {
      label: "ON",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "OFF",
      value: "N",
      key: "N",
    },
  },
  state: {
    O: {
      label: "제한 없음",
      value: "O",
      key: "O",
    },
    A: {
      label: "기간 설정",
      value: "A",
      key: "A",
    },
  },
  location_type: {
    C: {
      label: "중앙",
      value: "C",
      key: "C",
    },
    F: {
      label: "위치 고정",
      value: "F",
      key: "F",
    },
  },
};

const dbSiteBannerMng = {
  use_yn: {
    Y: {
      label: "노출 함",
      value: "Y",
      key: "Y",
    },
    N: {
      label: "노출 안함",
      value: "N",
      key: "N",
    },
  },
  link_type: {
    U: {
      label: "URL 링크",
      value: "U",
      key: "U",
    },
    G: {
      label: "게임 링크",
      value: "G",
      key: "G",
    },
    NONE: {
      label: "배너 링크 없음",
      value: "",
      key: "",
    },
  },
};

const dbSiteEventMng = {
  active_yn: {
    all: {
      label: t("common.all"),
      value: "",
      key: 0,
    },
    active: {
      label: t("common.use_y"),
      value: "Y",
      key: 1,
    },
    inactive: {
      label: t("common.use_n"),
      value: "N",
      key: 2,
    },
  },

  deposit_type: {
    DF: {
      label: t("event.daily_first_charge"),
      value: "F",
      key: "F"
    },
    DD: {
      label: t("event.daily_multiple_charge"),
      value: "E",
      key: "E"
    },
    CF: {
      label: t("event.join_first_charge"),
      value: "I",
      key: "I"
    },
  },

  // 보너스 지급 방식: 조건 지급 비율 지급
  bonus_payment_type: {
    C: {
      label: t("event.condition"),
      value: "C",
      key: "C"
    },
    R: {
      label: t("event.ratio"),
      value: "R",
      key: "R"
    }
  },

  // 요일
  day: {
    0: {
      label: t("days.every_day"),
      key: "0"
    },
    1: {
      label: t("days.sun"),
      key: "1"
    },
    2: {
      label: t("days.mon"),
      key: "2"
    },
    3: {
      label: t("days.tue"),
      key: "3"
    },
    4: {
      label: t("days.wed"),
      key: "4"
    },
    5: {
      label: t("days.thu"),
      key: "5"
    },
    6: {
      label: t("days.fri"),
      key: "6"
    },
    7: {
      label: t("days.sat"),
      key: "7"
    }
  }


}

export { dbSiteAccount, dbSiteConfig, dbSitePointConfig, dbSitePopupMng, dbSiteBannerMng, dbSiteEventMng };
