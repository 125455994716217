import api from "core/services/api";
import React, { useEffect, useState } from "react";
import { Column, Row } from "styles/custom/useCustomLayout";
import { FilterTitle } from "styles/custom/useCustomText";
import { EachCheckForm, GroupSelectForm, InputForm, SelectForm, SelectForm2 } from "./CommonForm";
import { agentFilterCondition } from "constants/enum/agent/userAgentUserEnum";
import { checkboxYNEnum } from "constants/enum/useCommonEnum";
import { t } from "i18next";

const AgentSearchFilter = ({ useSearch, useStated, $mar }) => {

  const [agentTypeList, setAgentTypeList] = useState([]);
  const [agentNameList, setAgentNameList] = useState([]);
  const [allAgentNameList, setAllAgentNameList] = useState([]);

  const searchSubAgentYn = useSearch.formValue?.search_sub_agent_yn;


  useEffect(() => {
    if (useSearch.formValue.search_agent_type) {

      const labelType = agentTypeList.find(item => item.value == useSearch.formValue.search_agent_type)?.label
      if (labelType) {
        setAgentNameList({
          [labelType]: allAgentNameList[labelType]
        })
        useSearch.setFormValue({
          ...useSearch.formValue,
          search_agent_name: "",
        });
      }
    } else {
      setAgentNameList(allAgentNameList)
    }
  }, [useSearch.formValue.search_agent_type])


  // 에이전트 타입
  const getAgentTypeList = () => {
    api.get("/v1/agent/info/agent-types")
      .then((res) => {
        const agentType = res.data.content.reduce((acc, item) => {
          acc.push({
            label: item.name,
            value: item.type,
            key: item.depth
          });
          return acc;
        },
          [{
            label: t("common.all"),
            value: "",
            key: 0,
          }]);
        setAgentTypeList(agentType)
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })
  };

  // 에이전트 정보
  const getAgentInfoList = () => {
    api.get("/v1/agent/info/list")
      .then((res) => {
        let result = {};
        res.data.content.forEach(item => {
          const typeKey = `${t(agentTypeList.find(item2 => item2.value == item.type).label)}`;
          // 해당 타입 키가 없으면 새 객체 생성
          if (!result[typeKey]) {
            result[typeKey] = {};
          }
          // name을 키로 객체 저장
          result[typeKey][item.name] = null
        });

        setAgentNameList(result)
        setAllAgentNameList(result)
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  useEffect(() => {
    getAgentTypeList();
  }, []);


  useEffect(() => {
    if (agentTypeList.length == 0) return
    getAgentInfoList();
  }, [agentTypeList]);



  return (
    <Row $mar={$mar || ""} $gap="12px">
      {searchSubAgentYn !== undefined && (
        <Column $gap="4px">
          <FilterTitle>{t("agent.search_agent")}</FilterTitle>
          <Row $gap="4px" $align="center">
            <SelectForm
              name="search_agent_category"
              placeholder={t("common.select")}
              options={agentFilterCondition}
              {...useSearch}
              {...useStated}
            />
            <InputForm type="text" name="search_agent_val" placeholder={t("common.input")} {...useSearch} {...useStated} />
            <EachCheckForm
              $size="var(--s-caption)"
              name="search_sub_agent_yn"
              labelFor="search_sub_agent_yn"
              options={checkboxYNEnum}
              labelName={t("agent.include_sub_agent")}
              {...useSearch}
            />
          </Row>
        </Column>
      )}
      <Column $gap="4px" >
        <FilterTitle>{t("agent.type")}</FilterTitle>
        <Row $gap="4px"  >
          {agentTypeList && (
            <SelectForm2
              name="search_agent_type"
              placeholder={t("common.all")}
              options={agentTypeList}
              {...useSearch}
              {...useStated}
              isfilter={false}
              width={'120px'}
            />
          )}
        </Row>
      </Column>
      <Column $gap="4px">
        <FilterTitle>{t("agent.name")}</FilterTitle>
        <Row $gap="4px">
          {agentNameList && (
            <GroupSelectForm
              name="search_agent_name"
              options={agentNameList}
              {...useSearch}
              {...useStated}
            />
          )}
        </Row>
      </Column>
    </Row>
  );
};

export default AgentSearchFilter;
