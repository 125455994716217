import i18n from "constants/locales/i18n";

const DETAIL_PAGE_CATEGORY = [
  {
    title: "기본정보",
    key: 1,
  },
  {
    title: "계좌정보",
    key: 2,
  },
  {
    title: "커미션정보",
    key: 3,
  },
  {
    title: "게임정보",
    key: 4,
  },
  {
    title: "쪽지내역",
    key: 5,
  },
];

const AGENT_INFO_CATEGORY = [
  {
    title: i18n.t("menu.agent_info"),
    key: 1,
  },
  {
    title: i18n.t("menu.admin"),
    key: 2,
  },
  // {
  //   title: i18n.t("menu.payment_history"),
  //   key: 3,
  // },
  {
    title: i18n.t("menu.user_list"),
    key: 4,
  },
  {
    title: i18n.t("menu.qna_history"),
    key: 5,
  },
  {
    title: i18n.t("menu.commission_history"),
    key: 6,
  },
  {
    title: i18n.t("gameManagement.agent_comp_setting"),
    key: 7,
  }
];

const AGENT_INFO_CATEGORY_AGENT = [
  {
    title:  i18n.t("menu.agent_info"),
    key: 1,
  },
  {
    title: i18n.t("menu.admin"), 
    key: 2,
  },

  {
    title: i18n.t("menu.user_list"), 
    key: 3,
  },
];

const AGENT_AGENT_INFO_CATEGORY = [
  {
    title: i18n.t("menu.admin_info"), 
    key: 1,
  },
  {
    title:  i18n.t("menu.access_list"), 
    key: 2,
  },
];

const USER_INFO_CATEGORY = [
  {
    title: i18n.t("menu.inout_list"),
    key: 1,
  },
  {
    title: i18n.t("menu.point_list"),
    key: 2,
  },
  {
    title: i18n.t("menu.betting_list"),
    key: 3,
  },
  {
    title: i18n.t("menu.Send_Message"),
    key: 10,
  },
  {
    title: i18n.t("menu.message_list"),
    key: 4,
  },
  {
    title: i18n.t("menu.qna_history"),
    key: 5,
  },
  {
    title: i18n.t("menu.access_list"),
    key: 6,
  },
  {
    title: i18n.t("menu.block_ip_list"),
    key: 7,
  },
  {
    title: i18n.t("gameManagement.user_comp_setting"),
    key: 8,
  },
  {
    title: i18n.t("gameManagement.refer_comp_setting"),
    key: 9,
  }
];

const USER_DETAIL_BETTING_CATEGORY = [
  {
    title: i18n.t("betting.betting_detail"),
    key: 1,
  },
  {
    title: i18n.t("betting.game_info"),
    key: 2,
  },

  {
    title: i18n.t("betting.transaction_flow"),
    key: 3,
  },
  {
    title: i18n.t("betting.user_betting"),
    key: 4,
  },
  {
    title: i18n.t("betting.game_result"),
    key: 5,
  },
  {
    title: i18n.t("betting.raw_data"),
    key: 6,
  },
];

const USER_BETTING_CATEGORY = [
  {
    title: i18n.t("betting.game_info"),
    key: 2,
  },

  {
    title: i18n.t("betting.transaction_flow"),
    key: 3,
  },
  {
    title: i18n.t("betting.user_betting"),
    key: 4,
  },
  {
    title: i18n.t("betting.game_result"),
    key: 5,
  },
  {
    title: i18n.t("betting.raw_data"),
    key: 6,
  },
];

export {
  DETAIL_PAGE_CATEGORY,
  AGENT_INFO_CATEGORY,
  AGENT_AGENT_INFO_CATEGORY,
  USER_INFO_CATEGORY,
  AGENT_INFO_CATEGORY_AGENT,
  USER_BETTING_CATEGORY,
  USER_DETAIL_BETTING_CATEGORY,
};
