import useFormData from "core/hooks/useFormData";
import useStateData from "core/hooks/useStateData";
import { SelectForm, TextareaForm } from "components/common/CommonForm";
import { useCommonState } from "core/contexts/useCommonState";
import { useAlert, usePopup } from "core/contexts/useWindow";
import { dbWithdrawal } from "constants/enum/db/useWithdrawalEnum";
import api from "core/services/api";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Column, FormBox, Row } from "styles/custom/useCustomLayout";
import { MainBtn, WhiteBtn } from "styles/useButtonStyles";
import { IconLink, PositionBox } from "styles/useLayoutStyles";
import { TextBox } from "styles/useTextStyles";

const WithdrawalRejectForm = ({ historyNo, nickName, userNo, userInfo, getTableItem }) => {
  const useStated = useStateData();

  const { setLoading } = useCommonState();

  const { closePopup } = usePopup();
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showRejectWithdrawalRequestFail(item.msg.code);
        if (item.msg.values === "notExist") {
          closePopup(); // 출금 불가 처리 팝업 닫기
          closePopup(); // 출금 관리 팝업 닫기
          getTableItem();
        }
      }
    });
  }, [useStated.errors]);

  const showRejectWithdrawalRequestFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const showRejectWithdrawalRequestAlert = (isSuccess) => {
    const msg = isSuccess ? t("withdrawal.withdrawal_reject_complete_msg") : t("withdrawal.withdrawal_reject_fail_msg")
    openAlert({
      title: isSuccess ? t("withdrawal.withdrawal_reject_complete") : t("withdrawal.withdrawal_reject_fail"),
      message: msg,
      iconURL: isSuccess ? false : true,
    });
  };

  /**
   * 출금 불가처리
   */
  const rejectWithdrawalRequest = () => {
    // 샤유를 입력항목이 비어있으면 경고창 띄움
    if (useForm.formValue.message_title == '' && useForm.formValue.message_content == '') {
      openAlert({
        title: t("withdrawal.withdrawal_reject_fail"),
        message: t("withdrawal.withdrawal_reject_reason_empty"),
        iconURL: true,
      });
      return;
    }

    setLoading(true);
    const apiUrl = `/v1/inout/withdrawal/reject`;
    api
      .put(apiUrl, {
        history_arr: Array.isArray(historyNo) ? historyNo : [historyNo],
        user_arr: Array.isArray(userInfo) ? userInfo : [userInfo],
        message: useForm.formValue.message_title ? useForm.formValue.message_title : useForm.formValue.message_content,
      })
      .then((res) => {
        useStated.setErrorData([], true);
        showRejectWithdrawalRequestAlert(true);
        closePopup(); // 출금 불가 처리 팝업 닫기
        closePopup(); // 출금 관리 팝업 닫기
        getTableItem();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        showRejectWithdrawalRequestAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const useForm = useFormData({
    message_title: "",
    message_content: "",
  });

  return (
    <Column className="area-box" $gap="20px" $height="300px">
      <FormBox $align="flex-start">
        <IconLink $url={"/assets/svg/warnicon.svg"} $width="24px" $height="24px"></IconLink>
        {
          <TextBox>
            {Array.isArray(nickName) ?
              t("withdrawal.withdrawal_reject_confirm1", { nickName: nickName.join(', ') })
              :
              t("withdrawal.withdrawal_reject_confirm2", { nickName: nickName || "-", userNo: userNo || "-" })}
          </TextBox>
        }
      </FormBox>
      <FormBox $align="flex-start">
        <Column $width="100%" $gap="4px">
          {/* 사유 선택 드롭박스 */}
          <SelectForm
            name="message_title"
            placeholder={t("withdrawal.none")}
            options={dbWithdrawal.reject_type}
            {...useForm}
            {...useStated}
          />

          {/* 본문 */}
          <TextareaForm
            type="text"
            name="message_content"
            placeholder={useForm.formValue.message_title == "" ? t("withdrawal.withdrawal_reject_reason_placeholder") : ""}
            height="200px"
            disabled={useForm.formValue.message_title !== ""}
            {...useForm}
            {...useStated}
          />
        </Column>
      </FormBox>

      <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
        <Row $gap="10px" $jus="right" $width="100%">
          <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
            {t("common.cancel")}
          </WhiteBtn>
          <MainBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={rejectWithdrawalRequest}>
            {t("common.check")}
          </MainBtn>
        </Row>
      </PositionBox>
    </Column>
  );
};

export default WithdrawalRejectForm;
