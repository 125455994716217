import React from "react";
import { Row } from "styles/custom/useCustomLayout";
import styled, { css } from "styled-components";

const PaginationNum = styled.button`
  color: var(--c-gray-900);
  font-size: var(--s-text);
  font-family: var(--f-text);
  border: 1px solid var(--c-gray-300);

  padding: 2px 10px;
  height: 28px;

  &.active {
    color: var(--c-mint-600);
    border: 1px solid var(--c-mint-600);
    pointer-events: none;
  }
`;
const createPaging = ({ $url }) => css`
  color: var(--c-gray-900);
  font-size: var(--s-text);
  font-family: var(--f-text);
  border: 1px solid var(--c-gray-300);
  background: ${`url(${$url}) no-repeat center`};
  width: 28px;
  height: 28px;

  &:disabled {
    opacity: 0.5;
  }
`;

const PagingBtn = styled.button`
  ${createPaging}
`;

const Pagination = ({ pagingInfo, onPageChange }) => {
  const pageChange = (page) => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  const generatePageNumbers = () => {
    const totalPages = pagingInfo.totalPages;
    const current = pagingInfo.current;
    const pagingNum = 10;
    const currentPageGroup = Math.floor((current - 1) / pagingNum);

    const startPage = currentPageGroup * pagingNum + 1;
    const endPage = Math.min(startPage + pagingNum - 1, totalPages);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <Row $gap="8px">
      <PagingBtn
        onClick={() => pageChange(pagingInfo.firstPage)}
        disabled={pagingInfo.current === pagingInfo.firstPage}
        $url="/assets/svg/totalprev.svg"
      />
      <PagingBtn
        onClick={() => pageChange(pagingInfo.current - 1)}
        disabled={pagingInfo.current === pagingInfo.firstPage}
        $url="/assets/svg/pageprev.svg"
      />

      {pageNumbers.map((label) => (
        <PaginationNum
          key={label}
          className={label === pagingInfo.current && "active"}
          onClick={() => pageChange(label)}>
          {label}
        </PaginationNum>
      ))}

      <PagingBtn
        onClick={() => pageChange(pagingInfo.current + 1)}
        disabled={pagingInfo.current === pagingInfo.lastPage}
        $url="/assets/svg/pagenext.svg"
      />
      <PagingBtn
        onClick={() => pageChange(pagingInfo.lastPage)}
        disabled={pagingInfo.current === pagingInfo.lastPage}
        $url="/assets/svg/totalnext.svg"
      />
    </Row>
  );
};

export default Pagination;
