import { useState, useCallback } from "react";
import { TooltipContainer, TooltipText } from "styles/useLayoutStyles";

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  function isHTMLUsingDOMParser(str) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

  const handleMouseEnter = useCallback((e) => {
    const element = e.currentTarget;
    const rect = element.getBoundingClientRect();

    // 뷰포트 크기
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // 툴팁 위치 계산 (스크롤 위치 제외)
    let x = rect.left;
    let y = rect.top;

    // 툴팁이 화면 밖으로 나가는지 확인
    if (rect.top + 100 > viewportHeight) { // 100은 예상 툴팁 높이
      // 요소 위에 표시
      y = rect.top - 20 - 10; // 10은 여백
    } else {
      // 요소 아래에 표시
      y = rect.bottom + 5;
    }

    // 가로 위치 조정
    if (rect.left + 100 > viewportWidth) { // 300은 예상 툴팁 너비
      x = rect.right - 100;
    }
    // 최소값 보정
    // x = Math.max(10, x);
    // y = Math.max(10, y);

    setPosition({ x, y });
    setIsVisible(true);
  }, []);

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setIsVisible(false)}
    >
      {children}
      <TooltipText
        isVisible={isVisible}
        style={{
          left: `${position.x}px`,
          top: `${position.y}px`,
          textAlign: 'left'
        }}
      >
        {isHTMLUsingDOMParser(text) ? 
          <div 
            dangerouslySetInnerHTML={{ __html: text }} 
            style={{ textAlign: 'left' }}
          />
          : 
          text
        }
      </TooltipText>
    </TooltipContainer>
  );
};

export default Tooltip;