import React, { useEffect } from "react";

import useFormData from "core/hooks/useFormData";
import { AreaTitle } from "styles/useTextStyles";
import { MainBtn, WhiteBtn, RedBtn } from "styles/useButtonStyles";
import { TextBox } from "styles/useTextStyles";
import { LineSection, PositionBox } from "styles/useLayoutStyles";
import { InputForm, TextareaForm } from "components/common/CommonForm";
import { Column, OptionBox, Row, SubTitleBox } from "styles/custom/useCustomLayout";
import api from "core/services/api";
import { useAlert, useConfirm, usePopup } from "core/contexts/useWindow";
import { dateFormat, getFormatDatetime } from "utils/date/DateUtil";
import useStateData from "core/hooks/useStateData";
import { useCommonState } from "core/contexts/useCommonState";
import { dbAgentCashChargeHistory } from "constants/enum/db/useAgentEnum";
import { useTranslation } from "react-i18next";
import { validationTypeEnum } from "constants/enum/useCommonEnum";

const AgentChargeInfo = ({ no, getTableItem }) => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();
  const { openAlert } = useAlert();
  const { closePopup } = usePopup();
  const { openConfirm } = useConfirm();
  const { t } = useTranslation();

  useEffect(() => {
    useStated.setErrorData([], true);

    getChargeInfo();
  }, []);

  useEffect(() => {
    useStated.errors.forEach((item) => {
      if (item.path === "useAlert") {
        showUpdateStateConditionFail(item.msg.code);
      }
    });
  }, [useStated.errors]);

  const showUpdateStateConditionFail = (code) => {
    openAlert({
      title: t(`error.${code}`),
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const getChargeInfo = () => {
    setLoading(true);

    const apiUrl = `v1/agent/cash-charge/detail/${no}`;
    api
      .get(apiUrl, {})
      .then((res) => {
        useForm.setFormValue({
          ...res.data.content,
          actual_amount: res.data.content.actual_amount || 0,
        });
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showCompeteStateSuccessAlert = () => {
    openAlert({
      title: t("common.update_state_complete"),
      iconURL: false,
      mainBtn: t("common.check"),
    });
  };

  const showCompleteStateFailAlert = (conf = false, err = null) => {
    openAlert({
      title: t("common.update_state_fail"),
      message: (err.path === "memo" ? t("charge.state_block_reason") : t("charge.actual_amount_compelete")) + ' ' + (conf ? t(`error.${err.code === "M001" ? "A022" : err.code}`) : t("common.try_later")), 
      iconURL: true,
      mainBtn: t("common.check"),
    });
  };

  const updateState = (stateVal) => {
    setLoading(true);
    const apiUrl = `v1/agent/cash-charge/state`;
    api
      .put(apiUrl, {
        ...useForm.formValue,
        state: stateVal,
      })
      .then((res) => {
        showCompeteStateSuccessAlert();
        getTableItem();
        closePopup();
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
          // 배열로 넘어올 경우 검토학.
          Array.isArray(err.response.data.errors) ? 
          showCompleteStateFailAlert(true, err.response.data.errors[0].msg) : showCompleteStateFailAlert();
        } else {
          showCompleteStateFailAlert();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const stateConfig = {
    [dbAgentCashChargeHistory.state.C.value]: {
      title: dbAgentCashChargeHistory.state.C.label,
      message: t("charge.state_update_complete_confirm"),
    },
    [dbAgentCashChargeHistory.state.B.value]: {
      title: dbAgentCashChargeHistory.state.B.label,
      message: t("charge.state_update_block_confirm"),
    },
  };

  const updateStateConfirm = (stateVal) => {
    const config = stateConfig[stateVal];
    if (config) {
      openConfirm({
        title: config.title,
        message: config.message,
        iconURL: false,
        mainBtn: t("common.check"),
        Func: () => updateState(stateVal),
      });
    }
  };

  const useForm = useFormData({});

  return (
    <>
      <Column $gap="48px" $width="1500px">
        <Column className="area-box">
          <SubTitleBox>
            <Row $gap="6px" $align="center">
              <AreaTitle>{t("agent.info")}</AreaTitle>
            </Row>
          </SubTitleBox>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.code")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.code}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.parent_agent")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.parent_code}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.name")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.name}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.type")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.type_name}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("common.phone")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.phone}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("agent.join_code")}
              </TextBox>

              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.join_code}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("charge.demand_amount")}
              </TextBox>

              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {dateFormat(useForm.formValue.demand_date)}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>

            <OptionBox $flex="1" $align="center">
              {(() => {
                let label = "";
                let datetime = "";
                const { state, actual_date, modify_at } = useForm.formValue;

                const { C, B } = dbAgentCashChargeHistory.state;

                switch (state) {
                  case C.value:
                    label = `${C.label}${t("common.date")}`;
                    datetime = getFormatDatetime(actual_date);
                    break;
                  case B.value:
                    label = `${B.label}${t("common.date")}`;
                    datetime = getFormatDatetime(modify_at);
                    break;
                  default:
                    break;
                }

                return (
                  <>
                    <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                      {label}
                    </TextBox>
                    <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                      {datetime}
                    </TextBox>
                  </>
                );
              })()}
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("charge.demand_amount_amount")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.demand_amount ? useForm.formValue.demand_amount?.toLocaleString() : 0}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("charge.actual_amount_compelete")}
              </TextBox>

              {dbAgentCashChargeHistory.state[useForm.formValue.state] === dbAgentCashChargeHistory.state.D ? (
                <InputForm
                  maxWidth="100%"
                  name="actual_amount"
                  maxLength={19}
                  validationType={validationTypeEnum.NUMBER}
                  {...useForm}
                  {...useStated}
                />
              ) : (
                <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                  {useForm.formValue.actual_amount ? useForm.formValue.actual_amount?.toLocaleString() : 0}
                </TextBox>
              )}
            </OptionBox>
          </Row>

          <Row>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("charge.state")}
              </TextBox>

              <TextBox
                $font="var(--f-subB)"
                $line="var(--l-sub)"
                className={
                  useForm.formValue.state === dbAgentCashChargeHistory.state.C.value
                    ? "complete"
                    : useForm.formValue.state === dbAgentCashChargeHistory.state.B.value
                    ? "warn"
                    : "wait"
                }
              >
                {dbAgentCashChargeHistory.state[useForm.formValue.state]?.label}
              </TextBox>
            </OptionBox>

            <LineSection $borL="1px solid var(--c-gray-300)"></LineSection>
            <OptionBox $flex="1" $align="center">
              <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                {t("charge.charge_admin")}
              </TextBox>
              <TextBox $font="var(--f-subB)" $line="var(--l-sub)">
                {useForm.formValue.admin_name || "-"}
              </TextBox>
            </OptionBox>
          </Row>

          <Row>
            <Row $flex="1" $gap="16px">
              <Row $pad="14px 20px">
                <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                  {t("charge.state_block_reason")}
                </TextBox>

                <Column $width="100%" $gap="4px">
                  <TextareaForm
                    width="500px"
                    height="200px"
                    name="memo"
                    disabled={dbAgentCashChargeHistory.state[useForm.formValue.state] !== dbAgentCashChargeHistory.state.D}
                    {...useForm}
                    {...useStated}
                  />
                  <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                    {t("charge.state_block_reason_plh")}
                  </TextBox>
                </Column>
              </Row>
            </Row>
          </Row>
        </Column>

        <PositionBox $position="fixed" $top="100%" $left="0" $pad="12px 20px" $backColor="var(--c-white)" $width="100%">
          <Row $gap="10px" $jus="right" $width="100%">
            {dbAgentCashChargeHistory.state[useForm.formValue.state] === dbAgentCashChargeHistory.state.D ? (
              <>
                <MainBtn
                  $width="80px"
                  $size="var(--s-table)"
                  $line="var(--l-table)"
                  onClick={() => updateStateConfirm(dbAgentCashChargeHistory.state.C.value)}
                >
                  {t("charge.complete_state")}
                </MainBtn>
                <RedBtn
                  $width="80px"
                  $size="var(--s-table)"
                  $line="var(--l-table)"
                  onClick={() => updateStateConfirm(dbAgentCashChargeHistory.state.B.value)}
                >
                  {t("charge.block_state")}
                </RedBtn>
              </>
            ) : null}

            <WhiteBtn $width="69px" $size="var(--s-table)" $line="var(--l-table)" onClick={closePopup}>
              {t("common.cancel")}
            </WhiteBtn>
          </Row>
        </PositionBox>
      </Column>
    </>
  );
};

export default AgentChargeInfo;
