import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import useAllRoutes from "./useAllRoutes";
import Login from "components/login/Login";
import Loading from "components/common/Loading";
import AdminSide from "components/admin/AdminSide";
import AdminHeader from "components/admin/AdminHeader";
import PrivateRoute from "components/login/PrivateRouter";
import { ContentArea, IconLink } from "styles/useLayoutStyles";
import { useCommonState } from "core/contexts/useCommonState";
import Oauth from "components/login/Oauth";
import SiteNameSetter from "components/common/SiteNameSetter";
import AlarmConfigSetter from "components/common/AlarmConfigSetter";

const Wrap = styled.div`
  min-height: 100vh;
  background: #fffdfd;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const MainLayOut = styled.div`
  flex: 1;
  display: flex;

  width: 100%;
  height: 100%;
  border-top: 1px solid var(--c-gray-300);
`;

const SideIcon = styled(IconLink)`
  background: url("/assets/svg/side_toggle.svg") no-repeat center;
  width: 12px;
  height: 12px;
`;

const SideBtn = styled.button`
  position: absolute;
  width: 14px;
  height: 46px;
  top: 0;
  left: 0;

  border: 1px solid var(--c-gray-300);
  border-left: none;
`;

const Routers = () => {
  const [favorites, setFavorites] = useState([]);
  const [toggleAside, setToggleAside] = useState(true);

  const asideToggle = () => setToggleAside(!toggleAside);

  const addToFavorites = (menu) => {
    // 로컬 스토리지에서 저장된 즐겨찾기 목록 가져오기
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];

    const existingMenuIndex = favorites.findIndex((fav) => fav.enum === menu.enum);

    if (existingMenuIndex !== -1) {
      favorites[existingMenuIndex].count += 1;
    } else {
      favorites.push({
        ...menu,
        count: 1,
      });
    }

    favorites.sort((a, b) => b.count - a.count);

    localStorage.setItem("favorites", JSON.stringify(favorites));
  };

  useEffect(() => {
    // 로컬 스토리지에서 저장된 즐겨찾기 목록 가져오기
    const favorites = JSON.parse(localStorage.getItem("favorites")) || [];

    // 횟수를 기준으로 정렬 (가장 많이 누른 순서대로)
    favorites.sort((a, b) => b.count - a.count);

    // 상위 3개의 메뉴만 선택
    const top3Favorites = favorites.slice(0, 3);

    // state에 상위 3개의 메뉴를 업데이트
    setFavorites(top3Favorites);
  }, []);


  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/oauth/redirect" element={<Oauth />} />

        <Route element={<PrivateRoute />}>
          <Route
            path="/*"
            element={
              <RouteContent
                favorites={favorites}
                asideToggle={asideToggle}
                toggleAside={toggleAside}
                addToFavorites={addToFavorites}
              />
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

const RouteContent = ({ favorites, asideToggle, toggleAside, addToFavorites }) => {
  const { contentRoute } = useAllRoutes();
  const { roleType, subRole } = useCommonState();
  const [contentKey, setContentKey] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  const movePage = (url) => {
    if (location.pathname === url) {
      setContentKey((prevKey) => prevKey + 1); // contentKey를 업데이트하여 content를 새로고침
    } else {
      navigate(url);
    }
  };

  return (
    <Wrap>
      <SiteNameSetter /> {/* Call API and set site name */}
      <AlarmConfigSetter /> {/* Call API and set alarm config */}
      <AdminHeader favorites={favorites} addToFavorites={addToFavorites} movePage={movePage} />
      <MainLayOut>
        <AdminSide toggleAside={toggleAside} addToFavorites={addToFavorites} />

        <Suspense fallback={<Loading />}>
          <ContentArea key={contentKey} border={toggleAside && "1px solid var(--c-gray-300)"}>
            <SideBtn onClick={asideToggle}>
              <SideIcon className={!toggleAside && "active"} $trans="rotate(-180deg)"></SideIcon>
            </SideBtn>
            <Routes>
              {contentRoute.map((route) => {
                // 역할권한
                let shouldDisplay = route.type ? route.type === roleType : true;
                
                // 부역할권한
                if (shouldDisplay) {
                  shouldDisplay = route.subRole ? route.subRole === subRole : true;
                }

                return shouldDisplay ? <Route key={route.path} path={route.path} element={<route.component />} exact /> : null;
              })}
            </Routes>
          </ContentArea>
        </Suspense>
      </MainLayOut>
    </Wrap >
  );
};

export default Routers;
