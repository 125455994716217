import api from "core/services/api";
import Pagination from "utils/Pagination";
import { useEffect } from "react";
import UserCompAdd from "./UserCompAdd";
import { t } from "i18next";
import useFormData from "core/hooks/useFormData";
import { useCountPerPage } from "constants/select/useValue";
import { useCommonState } from "core/contexts/useCommonState";
import { usePopup } from "core/contexts/useWindow";
import useStateData from "core/hooks/useStateData";
import useTableData from "core/hooks/useTableData";
import UserCompSetting from "components/gamemanagement/comp/UserCompSetting";
import { NoLabelCheckForm, SelectForm } from "components/common/CommonForm";
import NoDataTable from "components/common/NoDataTable";
import { isEmpty } from "lodash";
import { Column, Row } from "styles/custom/useCustomLayout";
import { MainBtn, RedBtn } from "styles/useButtonStyles";
import { PositionBox } from "styles/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { TextBox } from "styles/useTextStyles";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";

export default function UserComp({userNo}) {
  const useTG = useTableData(true);
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();

  const userCompPopup = () => {
    openPopup({
      title: t("gameManagement.add_user_comp"),
      content: <UserCompAdd userNo={userNo} getTableItem={getCompUserList} />,
      onClose: getCompUserList,
    });
  };

  const getCompUserList = () => {
    setLoading(true);
    const apiUrl = `/v1/user/all/comp-user/${userNo}`;
    api
    .get(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
      },
    })
    .then((res) => {
      useTG.setData(res.data.content);
      if (res.data.pageHelperDto) {
        useTG.setPagingInfo(res.data.pageHelperDto);
      }
      setLoading(false);
      useStated.setSuccessData([res.data.content]);
    })
    .catch((err) => {
      console.error(err);
    });
  };

  const userCompSetting = (gameNo) => {
    openPopup({
      title: t("gameManagement.update_user_comp"),
      content: <UserCompSetting gameNo={gameNo} userNo={userNo} type="U"/>,
      onClose: getCompUserList,
    });
  };

  const deleteCompUser = ({ gameNo = null }) => {
    const apiUrl = `/v1/user/all/comp-user/${userNo}`;
    // game_no_list 생성
    const gameNoList = gameNo ? [gameNo] : useTG.data.filter((item) => item.isChecked).map((item) => item.no);
    // URLSearchParams 객체 생성
    const params = new URLSearchParams();
    gameNoList.forEach((gameNo) => params.append("game_no_list", gameNo));
    api.delete(`${apiUrl}?${params.toString()}`)
    .then(() => {
      getCompUserList();
    })
    .catch((err) => {
      console.error(err);
      if (err.response?.status === 400) {
        useStated.setErrorData(err.response.data.errors, true);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const useSearch = useFormData(
    {
      keyword: "",
      start_date: "",
      end_date: "",
    },
    getCompUserList,
    useTG
  );

  // 검색 조건이 변경되면 검색어 초기화 (TextBox 에서 SelectForm 으로 변경될 때 오류 방지)
  useEffect(() => {
    useSearch.setFormValue({
      ...useSearch.formValue,
      keyword: "",
    });
  }, [useSearch.formValue?.search_state]);

  return (
    <Column className="area-box" $gap="15px">
      <Row className="total-count" $gap="4px">
        <TextBox $font="var(--f-subB)">{t("gameManagement.total_comp_users")}</TextBox>
        <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
          {useTG.error ? (
            <span>error</span>
          ) : (
            <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>
          )}
        </TextBox>
        <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
      </Row>
      <Row $jus="space-between" $width="100%" $align="center" $pad="0 0 14px">
        <RedBtn onClick={deleteCompUser}>{t("gameManagement.remove_user_comp")}</RedBtn>
        <Row $gap="10px" $align="center">
          <Row $gap="8px">
            <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={userCompPopup}>
              {t("gameManagement.add_user_comp")}
            </MainBtn>
          </Row>
          <SelectForm
            {...useTG}
            name="countPerPage"
            placeholder={useCountPerPage[0].label}
            size={"var(--s-table)"}
            line={"var(--l-table)"}
            options={useCountPerPage}
            formValue={useTG.extendData}
          />
        </Row>
      </Row>

      {useTG.error ? (
        <span>error</span>
      ) : (
        <>
          <Table className="table-box">
            <thead>
              <tr className="table-title">
                <TableHeader $width="34px" rowspan="2">
                  <NoLabelCheckForm $mar="0 0 0 !important" name="all" base={useTG} />
                </TableHeader>
                <TableHeader $minWidth="50px" $width="60px" rowspan="2">
                  <TextBox>{t("common.no")}</TextBox>
                </TableHeader>
                <TableHeader width="80px" rowspan="2">{t("banner.game_name")}</TableHeader>
                <TableHeader width="150px" colspan="4">{t("userCommission.rolling_comp")}</TableHeader>
                <TableHeader width="150px" colspan="4">{t("userCommission.losing_comp")}</TableHeader>
                <TableHeader $width="80px" rowspan="2">{t("common.manage")}</TableHeader>
              </tr>
              <tr>
                <TableHeader $width="50px">{t("gameStatistics.casino")}</TableHeader>
                <TableHeader $width="50px">{t("gameStatistics.slot")}</TableHeader>
                <TableHeader $width="50px">{t("gameStatistics.mini_game")}</TableHeader>
                <TableHeader $width="50px">{t("gameStatistics.sports")}</TableHeader>
                <TableHeader $width="50px">{t("gameStatistics.casino")}</TableHeader>
                <TableHeader $width="50px">{t("gameStatistics.slot")}</TableHeader>
                <TableHeader $width="50px">{t("gameStatistics.mini_game")}</TableHeader>
                <TableHeader $width="50px">{t("gameStatistics.sports")}</TableHeader>
              </tr>
            </thead>
            {useTG && useTG.data?.length > 0 && (
              <tbody>
                {useTG.data &&
                  useTG.data.map((item, index) => {
                    const number = useTG.pagingInfo?.offset - index;
                    return (
                      <TableRow key={number} className="table-content">
                        <TableDeps>
                          <NoLabelCheckForm keys={item.no} base={useTG} eachData={item} />
                        </TableDeps>
                        <TableDeps $width="60px">{number || "-"}</TableDeps>
                        <TableDeps>{item.game_name}</TableDeps>
                        <TableDeps>{`${item.casino_rolling}%`}</TableDeps>
                        <TableDeps>{`${item.slot_rolling}%`}</TableDeps>
                        <TableDeps>{`${item.mini_game_rolling}%`}</TableDeps>
                        <TableDeps>{`${item.sports_rolling}%`}</TableDeps>
                        <TableDeps>{`${item.casino_losing}%`}</TableDeps>
                        <TableDeps>{`${item.slot_losing}%`}</TableDeps>
                        <TableDeps>{`${item.mini_game_losing}%`}</TableDeps>
                        <TableDeps>{`${item.sports_losing}%`}</TableDeps>
                        <TableDeps>
                          <Row $width="100%" $jus="space-evenly">
                            <ModifyBtn
                              $size="var(--s-table)"
                              $line="var(--l-table)"
                              name={t("sendMessage.modify")}
                              eventProps={() => userCompSetting(item.no)}
                            />
                            <i style={{paddingLeft: "5px"}}/>
                            <DeleteBtn
                              $size="var(--s-table)"
                              $line="var(--l-table)"
                              name={t("common.unlock")}
                              eventProps={() => deleteCompUser({gameNo: item.no})}
                            />
                          </Row>
                        </TableDeps>
                      </TableRow>
                    );
                  })}
              </tbody>
            )}
          </Table>
          {useTG && useTG.data?.length === 0 && <NoDataTable />}
        </>
      )}
      <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%" $position="relative">
        <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
          {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
        </PositionBox>
        <Row $jus="space-between" $width="100%" $align="center" $pad="0 0 14px">
          <RedBtn onClick={deleteCompUser}>{t("gameManagement.remove_user_comp")}</RedBtn>
          <Row $gap="10px" $align="center">
            <Row $gap="8px">
              <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={userCompPopup}>
                {t("gameManagement.add_user_comp")}
              </MainBtn>
            </Row>
            <SelectForm
              {...useTG}
              name="countPerPage"
              placeholder={useCountPerPage[0].label}
              size={"var(--s-table)"}
              line={"var(--l-table)"}
              options={useCountPerPage}
              formValue={useTG.extendData}
            />
          </Row>
        </Row>
      </Row>
    </Column>
  );
}