import styled, { css } from "styled-components";

const createRedBtn = ({ $pad, $width, $size, $font, $line }) => css`
  width: ${$width};
  min-width: 40px;

  min-height: 36px;
  padding: ${$pad || "0 12px"};

  white-space: nowrap;
  color: var(--c-red);
  background-color: var(--c-white);

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};

  border: 1px solid var(--c-gray-300);
`;

const createGrayBtn = ({ $pad, $mar, $size, $font, $line, $width }) => css`
  color: var(--c-white);
  background-color: var(--c-gray-600);

  width: ${$width};
  margin: ${$mar};

  padding: ${$pad || "0 12px"};

  width: ${$width};
  white-space: nowrap;

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};
`;

const createMenuToggleBtn = ({ $pad, $mar, $size, $font, $line, $width, $color, $bcolor, $height }) => css`
  color: ${$color || " var(--c-gray-900)"};
  background-color: ${$bcolor || " var(--c-gray-300)"};

  width: ${$width};
  margin: ${$mar};

  padding: ${$pad || "4px 12px"};
  height: ${$height};

  white-space: nowrap;

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};
`;

const createMainBtn = ({ $pad, $size, $font, $line, $width, $height, $minWidth, $minHeight, $backColor }) => css`
  width: ${$width};
  min-width: ${$minWidth ? $minWidth : "40px"};

  padding: ${$pad || "0 12px"};

  height: ${$height};
  min-height: ${$minHeight ? $minHeight : "34px"};

  white-space: nowrap;
  color: var(--c-white);
  background-color: ${$backColor || "var(--c-mint-600)"};

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};
`;

const createWhiteBtn = ({ $pad, $size, $font, $line, $width, $height, $minHeight }) => css`
  width: ${$width};
  min-width: 30px;

  padding: ${$pad || "0 12px"};

  height: ${$height};
  min-height: ${$minHeight ? $minHeight : "34px"};

  white-space: nowrap;
  background-color: var(--c-white);

  font-size: ${$size};
  font-family: ${$font};
  line-height: ${$line};
  border: 1px solid var(--c-gray-300);
`;

const RedBtn = styled.button`
  ${createRedBtn}
`;

const GrayBtn = styled.button`
  ${createGrayBtn}
`;

const MainBtn = styled.button`
  ${createMainBtn}
`;

const WhiteBtn = styled.button`
  ${createWhiteBtn}
`;

const MenuToggleBtn = styled.button`
  ${createMenuToggleBtn}
`;

const ResetBtn = styled.button`
  width: 80px;
  padding: 0 12px;
  border-radius: 4px;
  white-space: nowrap;
  height: 40px;
  border: 1px solid var(--c-gray-700);
  font-size: var(--s-caption);
  font-family: var(--f-caption);

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &[disabled] + label {
    opacity: 0.5;
  }
`;

const CategoryMenu = styled.button`
  min-width: 120px;
  padding: 12px 0;
  text-align: center;
  white-space: nowrap;
  color: var(--c-gray-700);
  background-color: rgba(95, 158, 160, 0.2);

  font-size: var(--s-caption);
  font-family: var(--f-caption);
  &:hover {
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }
  transition: 0.2s ease-in-out;
  &.active {
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }
  &:first-of-type {
    border-top-left-radius: 8px;
  }
  &:last-of-type {
    border-top-right-radius: 8px;
  }
`;

const CheckBtn = styled.button`
  width: 80px;
  padding: 6px 0;
  font-size: var(--s-sub);
  line-height: var(--l-sub);

  border: 1px solid var(--c-gray-300);
`;

const ToggleBtn = styled.button`
  border: 1px solid var(--c-gray-300);
  background-color: var(--c-white);

  width: ${(props) => props.$width || "60px"};
  height: 34px;
  text-align: center;
  white-space: nowrap;

  &.active {
    border: none;
    color: var(--c-white);
    background-color: var(--c-mint-600);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`;

export { RedBtn, GrayBtn, MainBtn, ToggleBtn, WhiteBtn, ResetBtn, CheckBtn, CategoryMenu, MenuToggleBtn };
