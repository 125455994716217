const dbQnA = {
  type: {
    E: {
      label: "에이전트 문의",
      value: "E",
      key: "E",
    },
    A: {
      label: "계좌 문의",
      value: "A",
      key: "A",
    },
    O: {
      label: "1:1 문의",
      value: "O",
      key: "O",
    },
  },
};

export { dbQnA };
