import React, { useState, useRef, useEffect } from "react";
import { StateMsgForm, findStateByName, getFormatChars, getMask, isValidInput } from "utils/FormStateUtil";

import { ToggleBtn } from "styles/useButtonStyles";
import { Column, Row } from "styles/custom/useCustomLayout";
import { SortIcon } from "styles/custom/useCustomButton";
import { CheckInput, InputTextBox, RadioInput, Selects, Textarea, TextareaEditor } from "styles/useInputStyles";
import { checkboxOnclick, fileToBase64, getData, parseFormData } from "utils/FormUtils";
import { useTranslation } from "react-i18next";
import { Label, TextBox } from "styles/useTextStyles";
import { DeleteBtn, ModifyBtn } from "./TableStatus";
import { t } from "i18next";
import styled from "styled-components";


const IconImg = styled.img`
  display: ${(src) => (src ? 'block' : 'none')};
  height: 30px;
`;

const DivPad = styled.div`
  padding: 36px 0px 0px 0px;
`;

const DropdownList = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 7px;
  background-color: white;
  border: 1px solid var(--c-gray-300);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow: auto;
`;

const ComboWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input.attrs({ autoComplete: 'off' })`
  flex: 1;
  padding: 0.35rem;
  outline: none;
  border: none;
  font-size: 13px;
  height: 32px;
  min-height: 32px;
  background-color: #ffffff;
  width: 100%;

  &::-webkit-input-placeholder {
    color: black;
  }
  &::-moz-placeholder {
    color: black;
  }
  &:-ms-input-placeholder {
    color: black;
  }
  &::placeholder {
    color: black;
  }
`;

// text input
const InputForm = ({
  type,
  name,
  errors,
  padding,
  maskType,
  disabled,
  onChange,
  onKeyDown,
  formValue,
  successes,
  placeholder,
  readOnly,
  maxLength,
  validationType,
  // 스타일 조작 props
  width,
  border,
  height,
  tAlign,
  maxWidth,

  groupNm,
  index,
}) => {
  // error if문도 FormStateUtil에 넣었습니다.
  const error = findStateByName(name, errors, groupNm, index);
  const success = findStateByName(name, successes, groupNm, index);

  const mask = getMask(maskType);
  const maskChar = " ";
  const formatChars = getFormatChars(maskType);

  const onChanged = (event) => {
    const newValue = event.target.value;
    if (!mask && maxLength && newValue.length > maxLength) {
      return;
    }
    if (isValidInput(newValue, validationType)) {
      onChange(name, newValue, index, groupNm);
    }
  };

  return (
    <Column $gap="4px" $width={width}>
      <InputTextBox
        type={type}
        name={name}
        $padL={padding}
        disabled={disabled}
        onChange={onChanged}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        value={getData(formValue, name, index, groupNm)}
        // 마스킹
        mask={mask}
        maskChar={maskChar}
        formatChars={formatChars}
        // 스타일 조작
        $width={width}
        $height={height}
        $border={border}
        $maxWidth={maxWidth}
        $tAlign={tAlign}
      />
      {error && <StateMsgForm stateJson={error} stateType="error" />}
      {success && <StateMsgForm stateJson={success} stateType="success" />}
    </Column>
  );
};

// textareaEdit form
const TextareaEditorForm = ({
  name,
  width,
  height,
  errors,
  onChange,
  formValue,
  successes,
  placeholder,
  maxLength,
  groupNm,
  index,
}) => {
  const error = findStateByName(name, errors);
  const success = findStateByName(name, successes);

  const onChanged = (event) => {
    const newValue = event
    if (maxLength && newValue.length > maxLength) {
      return;
    }
    onChange(name, newValue, index, groupNm);
  };

  return (
    <Column $gap="4px" $width="100%">
      <TextareaEditor
        value={getData(formValue, name, index, groupNm)}
        onChange={onChanged}
        placeholder={placeholder}
        width={width}
        height={height}
        modules={{
          toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            // ["link", "image"],
          ],
        }}
      />
      <DivPad>
        {error && <StateMsgForm stateJson={error} stateType="error" />}
        {success && <StateMsgForm stateJson={success} stateType="success" />}
      </DivPad>
    </Column>
  );
};

// textarea form
const TextareaForm = ({
  type,
  name,
  width,
  border,
  height,
  errors,
  disabled,
  onChange,
  onKeyDown,
  formValue,
  successes,
  placeholder,
  readOnly,
  maxLength,

  groupNm,
  index,
}) => {
  const error = findStateByName(name, errors);
  const success = findStateByName(name, successes);

  const onChanged = (event) => {
    const newValue = event.target.value;
    if (maxLength && newValue.length > maxLength) {
      return;
    }
    onChange(name, newValue, index, groupNm);
  };

  return (
    <Column $gap="4px" $width="100%">
      <Textarea
        type={type}
        name={name}
        width={width}
        height={height}
        $border={border}
        disabled={disabled}
        onChange={onChanged}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        value={getData(formValue, name, index, groupNm)}
      />
      {error && <StateMsgForm stateJson={error} stateType="error" />}
      {success && <StateMsgForm stateJson={success} stateType="success" />}
    </Column>
  );
};

// select box

const SelectForm = ({
  name,
  index,
  errors,
  groupNm,
  options,
  onChangeEx,
  onChange,
  disabled,
  formValue,
  successes,
  extendData,
  placeholder,
  // 스타일 조작
  font,
  size,
  line,
  width,
  top,
  maxMenuHeight,
}) => {
  const error = findStateByName(name, errors);
  const success = findStateByName(name, successes);

  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    // 폼 값이 변경될 때마다 선택된 옵션 업데이트
    const newSelectedOption = options.find(
      (option) => option.value === getData(formValue ? formValue : extendData.pagingData, name, index, groupNm)
    );
    setSelectedOption(newSelectedOption || "");
  }, [formValue ? formValue[name] : extendData.pagingData, options, name, index, groupNm]);

  const onChanged = (event, status) => {
    const newSelectedOption = options.find((option) => option.value === event.value);
    if (status.name === "countPerPage") {
      onChange(event, status);
    }

    setSelectedOption(newSelectedOption || "");

    onChange(name, event ? event.value : "", index, groupNm);

    if (onChangeEx) {
      onChangeEx(name, event ? event.value : "", index, groupNm);
    }
  };

  const selectStyles = {
    control: (provided) => ({
      ...provided,
      width: width ? width : "100%",
      height: "34px",
      minWidth: "120px",
      padding: "0 12px",
      borderRadius: "0",
      border: "1px solid var(--c-gray-300)",
      boxShadow: "none",
      minHeight: "34px",
      gap: "4px",
      color: "var(--c-gray-900)",
      whiteSpace: "nowrap",

      ":placeholder": {
        padding: "0",
        color: "var(--c-gray-900)",
      },
      ":hover": {
        borderColor: "var(--c-gray-300)",
      },
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      padding: "6px 12px",
      backgroundColor: "var(--c-white)",
      color: isSelected ? "var(--c-mint-600)" : "var(--c-gray-900)",
      cursor: "pointer",
      width: "100%",

      ":hover": { backgroundColor: "var(--c-mint-50)" },
    }),
    menu: (provided) => ({
      ...provided,

      borderRadius: "0",
      whiteSpace: "nowrap",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      background: "url(/assets/svg/select_icon.svg) no-repeat center",
      width: "18px",
      height: "18px",
    }),
  };

  if (options) {
    if (!Array.isArray(options)) {
      options = Object.values(options);
    }

    options = options.map((option) => ({
      value: option.value,
      label: t(option.label),
      key: option.key,
    }));
  }
  return (
    <Column $gap="4px">
      <Selects
        name={name}
        error={error}
        options={options}
        onChange={onChanged}
        isDisabled={disabled}
        styles={selectStyles}
        placeholder={placeholder}
        value={selectedOption || ""}
        // 스타일 조작
        size={size}
        font={font}
        line={line}
        menuPlacement={top}
        maxMenuHeight={maxMenuHeight}
      />
      {error && <StateMsgForm stateJson={error} stateType="error" />}
      {success && <StateMsgForm stateJson={success} stateType="success" />}
    </Column>
  );
};

const SelectForm2 = ({ options, onChange, name, formValue, width, index, groupNm, isfilter = true, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState(''); // 사용자 입력값
  const [selectedOption, setSelectedOption] = useState(null); // 선택된 옵션
  const wrapperRef = useRef(null);

  useEffect(() => {
    // 초기값 설정 (formValue에서 가져옴)
    const currentOption = options.find(
      (option) => option.value === getData(formValue, name, index, groupNm)
    );
    setSelectedOption(currentOption || null);
    setInputValue(currentOption?.label || '');
  }, [formValue, name, index, groupNm, options]);

  const handleSelectChange = (option) => {
    // 옵션 선택 시 선택값과 입력값 업데이트
    setSelectedOption(option);
    setInputValue(option.label);
    setIsOpen(false); // 드롭다운 닫기
    onChange(name, option.value, index, groupNm); // 부모로 선택된 값 전달
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // 입력값 업데이트
    setIsOpen(true); // 드롭다운 열기
    const matchWord = options.filter((data) => data.label == value)[0]?.value
    if (matchWord) {
      formValue[name] = options.filter((data) => data.label == value)[0]?.value
    } else {
      formValue[name] = value
    }
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false); // 드롭다운 닫기
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const filteredOptions = isfilter ? options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  ) : options

  return (
    <ComboWrapper ref={wrapperRef}>
      <Row
        $border={'1px solid var(--c-gray-300)'}
        $backColor={'white'}
        $pad={'0 8px'}
        $width={width}
      >
        <StyledInput
          name={name}
          type="text"
          value={inputValue}
          placeholder={placeholder}
          onChange={handleInputChange} // 입력값 변경 처리
          onFocus={() => setIsOpen(true)}
        />
        <button onClick={() => setIsOpen(!isOpen)}>
          <TextBox $size={'16px'} $color={'gray'} $pad={'0 8px'}>
            {'▾'}
          </TextBox>
        </button>
      </Row>
      {isOpen && (
        <DropdownList>
          {filteredOptions.length ? filteredOptions.map((option) => (
            <Row
              key={option.key}
              style={{
                padding: '8px',
                cursor: 'pointer',
                background: selectedOption?.value === option.value ? '#f0f0f0' : 'white',
              }}
              onClick={() => handleSelectChange(option)}
            >
              {option.label}
            </Row>
          ))
            :
            (
              <Row $color={'var(--c-gray-300)'} $pad='10px'
              >{t('common.noData')}
              </Row>)}
        </DropdownList>
      )}
    </ComboWrapper>
  );
};

const GroupSelectForm = ({ options, onChange, errors, successes, name, formValue, extendData, index, groupNm, onChangeEx
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPath, setSelectedPath] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [closeFirstCombo, setCloseFirstCombo] = useState(false);
  const comboRef = useRef(null);

  const error = findStateByName(name, errors);
  const success = findStateByName(name, successes);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (comboRef.current && !comboRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    setSearch(formValue[name])
  }, [formValue, name, index, groupNm, options]);

  const filterTree = (data, filter, path = '') => {
    if (!isTyping || !filter) return data;

    const filtered = {};
    Object.entries(data).forEach(([key, value]) => {
      const currentPath = path ? `${path}.${key}` : key;
      if (key.toLowerCase().includes(filter.toLowerCase())) {
        filtered[key] = value;
      } else if (value && typeof value === 'object') {
        const filteredChildren = filterTree(value, filter, currentPath);
        if (Object.keys(filteredChildren).length > 0) {
          filtered[key] = filteredChildren;
        }
      }
    });
    return filtered;
  };
  const HighlightText = ({ text, level }) => {
    if (isTyping || !search) return <TextBox $weight={level == 0 ? 'bold' : null} $color={level == 0 ? 'var(--c-blue)' : null}>{text}</TextBox>;
    const parts = text.toLowerCase().split(search.toLowerCase());
    const lastIndex = parts.length - 1;

    return (
      <Row >
        {parts.map((part, i) => {
          const start = text.slice(0, part.length);
          const highlightPart = text.slice(part.length, part.length + search.length);
          text = text.slice(part.length + search.length);
          return (
            <React.Fragment key={i}>
              <TextBox>{start}</TextBox>
              {i < lastIndex && (
                <TextBox $color='var(--c-mint-600)'>{highlightPart}</TextBox>
              )}
            </React.Fragment>
          );
        })}
      </Row>
    );
  };

  const TreeNode = ({ data, path = '', level = 0 }) => {
    const [isNodeOpen, setIsNodeOpen] = useState(isOpen);
    const hasChildren = data && typeof data === 'object';
    const isSelected = !hasChildren && selectedPath === path;

    useEffect(() => {
      setIsNodeOpen(isOpen);
    }, [isOpen]);

    return (
      <Column>
        <Row
          $color={isSelected ? '#FFD700' : 'inherit'}
          $pad={'5px'}
          $backColor={isSelected ? "var(--c-mint-50)" : 'transparent'}
          $cursor={'pointer'}
          onClick={(e) => {
            e.stopPropagation();
            if (hasChildren) {
              setIsNodeOpen(!isNodeOpen);
            } else {
              const nodeName = path.split('.').pop();
              setSelectedPath(path);
              setSearch(nodeName)
              setIsTyping(false);
              setIsOpen(false);
            }
          }}
        >
          {hasChildren ? (
            <Row >
              {isNodeOpen ?
                <Row $pad={'0 5px'}>-</Row>
                :
                <Row $pad={'0 5px'}>+</Row>}
            </Row>
          ) : (
            <Row $pad={'0 10px'} />
          )}
          <HighlightText text={path.split('.').pop()} level={level}></HighlightText>
        </Row>

        {hasChildren && isNodeOpen && Object.entries(data).map(([key, value]) => (
          <TreeNode
            key={key}
            data={value}
            path={path ? `${path}.${key}` : key}
            level={level + 1}>
          </TreeNode>
        ))}
      </Column>
    );
  };
  const [search, setSearch] = useState('')

  var searchFilteredData = filterTree(options, search)
  useEffect(() => {
    if (search != '' && options) {
      onChange(name, search);
      searchFilteredData = filterTree(options, search)
    }
  }, [search])

  const handleSecondComboClick = () => {
    setCloseFirstCombo(true);
  };

  return (
    <Column $gap={'4px'} onClick={(e) => e.stopPropagation()}>
      <ComboWrapper style={{ position: 'relative', }} ref={comboRef} onClick={handleSecondComboClick}>
        <Row
          $border={'1px solid var(--c-gray-300)'}
          $backColor={'white'}
          $pad={'0 8px'}
        >
          <StyledInput
            name={name}
            type="text"
            value={search}
            placeholder={t('common.all')}
            onChange={(e) => {
              setIsTyping(true);
              setSearch(e.target.value)
              setIsOpen(true);
            }}
            onFocus={() => {
              setIsOpen(true);
              setIsTyping(false);
            }}
          />
          <button onClick={() => setIsOpen(!isOpen)}>
            <TextBox $size={'16px'} $color={'gray'} $pad={'0 8px'}>
              {'▾'}
            </TextBox>
          </button>
        </Row>

        {isOpen && (
          <DropdownList>
            {Object.keys(searchFilteredData).length > 0 ? (
              Object.entries(searchFilteredData).map(([key, value]) => (
                <TreeNode key={key} data={value} path={key} ></TreeNode>
              ))
            ) : (
              <Row $color={'var(--c-gray-300)'} $pad='10px'
              >{t('common.noData')}
              </Row>
            )}
          </DropdownList>
        )}
      </ComboWrapper>
      {error && <StateMsgForm stateJson={error} stateType="error" />}
      {success && <StateMsgForm stateJson={success} stateType="success" />}
    </Column >
  );
};



const NoLabelCheckForm = ({ keys, name, base, disabled, eachData, $mar }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const allChecked = name === "all";

  // 해당 체크는 에러 / 성공 메세지를 보낼 수 없는 구조에 사용됩니다.
  const eventHandle = (key) => {
    let newData;
    if (allChecked) {
      newData = base.data.map((item) => ({
        ...item,
        isChecked: !checkedAll,
      }));
      setCheckedAll(!checkedAll);
    } else {
      newData = base.data.map((item) => ({
        ...item,
        isChecked: item.no === key ? !(item.isChecked || false) : item.isChecked,
      }));
    }
    base.setData(newData);
  };

  const allItemsChecked = base.data?.every((item) => item.isChecked);
  useEffect(() => {
    if (allItemsChecked) {
      setCheckedAll(true);
    } else {
      setCheckedAll(false);
    }
  }, [allItemsChecked]);

  const isChecked = allChecked && base?.data?.length !== 0 ? checkedAll : (eachData && eachData.isChecked) || false;

  return (
    <>
      <CheckInput
        id={keys}
        $margin={$mar}
        checked={isChecked}
        disabled={disabled}
        onClick={eventHandle}
        onChange={() => eventHandle(keys)}
      />
    </>
  );
};
const RadioForm = ({
  name,
  errors,
  onChange,
  disabled,
  options,
  formValue,
  successes,
  groupNm,
  index,
  uniqueKey = null, // 한페이지에 같은 name, options 으로 여러개의 라디오버튼이 있을 때 구분하기 위한 key
}) => {
  const error = findStateByName(name, errors);
  const success = findStateByName(name, successes);

  const tempVal = getData(formValue, name, index, groupNm);

  const onChanged = (value) => {
    onChange(name, value, index, groupNm);
  };

  return (
    <Column $gap="4px">
      <Row $gap="12px" $align="center">
        {Object.keys(options).map((radio) => {
          const radioKey = `${name}_${options[radio].label}_${uniqueKey}`;
          return (
            <Row $gap="4px" $align="center" key={radioKey}>
              <RadioInput
                value={radio}
                disabled={disabled}
                id={radioKey}
                name={radioKey}
                checked={tempVal === options[radio].value}
                onChange={() => onChanged(options[radio].value)}
              />
              <Label htmlFor={radioKey}>{options[radio].label}</Label>
            </Row>
          );
        })}
      </Row>
      {error && <StateMsgForm stateJson={error} stateType="error" />}
      {success && <StateMsgForm stateJson={success} stateType="success" />}
    </Column>
  );
};
const EachCheckFormCustom = ({
  name,
  errors,
  labelFor,
  labelName,
  onChange,
  disabled,
  formValue,

  options,
  successes,

  groupNm,
  index,
}) => {
  const error = findStateByName(name, errors);
  const success = findStateByName(name, successes);

  const tempVal = getData(formValue, name, index, groupNm);

  let isChecked;

  let trueValue = "Y";
  let falseValue = "N";

  if (options) {
    [options, trueValue, falseValue] = parseFormData(options);
  }

  if ((trueValue || true) == tempVal) {
    isChecked = true;
  } else {
    isChecked = false;
  }

  const onChanged = (event) => {
    checkboxOnclick(
      name,
      event.target.checked === true ? trueValue || true : falseValue || false,
      index,
      groupNm,
      formValue,
      onChange,
      trueValue,
      falseValue
    );
  };

  return (
    <Column $gap="4px">
      <Row $gap="4px" $align="center">
        <CheckInput
          name={name}
          id={labelFor}
          disabled={disabled}
          checked={isChecked}
          onChange={onChanged}
          // 스타일
          margin={"0 !important"}
        />
        <Label htmlFor={labelFor}>{labelName}</Label>
      </Row>
      {error && <StateMsgForm stateJson={error} stateType="error" />}
      {success && <StateMsgForm stateJson={success} stateType="success" />}
    </Column>
  );
};

const EachCheckForm = ({
  name,
  errors,
  labelFor,
  onChange,
  disabled,

  reverse, // true <-> false 변경 (미사용 버튼 등 표시 시 사용)
  options,
  labelName,
  formValue,

  successes,

  groupNm,
  index,

  $size,
}) => {
  const error = findStateByName(name, errors);
  const success = findStateByName(name, successes);

  const tempVal = getData(formValue, name, index, groupNm);

  let isChecked;

  let trueValue = reverse ? "N" : "Y";
  let falseValue = reverse ? "Y" : "N";

  if (options) {
    if (reverse) {
      // reverse == true 일 때 trueValue <-> falseValue 변경
      [options, falseValue, trueValue] = parseFormData(options);
    } else {
      [options, trueValue, falseValue] = parseFormData(options);
    }
  }

  if ((trueValue || true) === tempVal) {
    isChecked = true;
  } else {
    isChecked = false;
  }

  const onChanged = (event) => {
    // console.log(formValue);
    onChange(name, event.target.checked === true ? trueValue || true : falseValue || false, index, groupNm);
  };
  return (
    <Column $gap="4px">
      <Row $gap="4px" $align="center">
        <CheckInput
          name={name}
          id={labelFor}
          disabled={disabled}
          checked={isChecked}
          onChange={onChanged}
          // 스타일
          margin={"0 !important"}
        />
        <Label htmlFor={labelFor} size={$size}>
          {labelName}
        </Label>
      </Row>
      {error && <StateMsgForm stateJson={error} stateType="error" />}
      {success && <StateMsgForm stateJson={success} stateType="success" />}
    </Column>
  );
};

const ToggleButtonForm = ({
  name,
  trueDisplay,
  falseDisplay,
  options,
  onChange,
  formValue,

  groupNm,
  index,
  width,

  disabled = false,
}) => {
  const onChanged = (chgVal) => {
    onChange(name, chgVal, index, groupNm);
  };

  let trueValue = "Y";
  let falseValue = "N";

  let trueDisplayValue;
  let falseDisplayValue;

  if (options) {
    [options, trueValue, falseValue, trueDisplayValue, falseDisplayValue] = parseFormData(options);
    trueDisplay = trueDisplay || trueDisplayValue;
    falseDisplay = falseDisplay || falseDisplayValue;
  }

  return (
    <Row>
      <ToggleBtn
        $width={width}
        className={getData(formValue, name, index, groupNm) === trueValue ? "active" : null}
        onClick={() => onChanged(trueValue)}
        disabled={disabled}
      >
        {trueDisplay ?? "ON"}
      </ToggleBtn>
      <ToggleBtn
        $width={width}
        className={getData(formValue, name, index, groupNm) !== trueValue ? "active" : null}
        onClick={() => onChanged(falseValue)}
        disabled={disabled}
      >
        {falseDisplay ?? "OFF"}
      </ToggleBtn>
    </Row>
  );
};

const ImageForm = ({
  name,
  formValue,

  groupNm,
  index,

  width,
  height,
}) => {

  let fileInputRefs = useRef();
  let imageRefs = useRef();

  const handleFileChange = (index, groupNm) => async (event) => {
    try {
      let newFiles = event.target.files[0];
      const allowedExtensions = ["jpg", "jpeg", "png"];
      const fileExtension = newFiles.name.split(".").pop().toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        alert(t("common.image_file_extension_error"));
        fileInputRefs.value = "";
        return;
      }

      const base64String = await fileToBase64(newFiles);

      if (index !== undefined && index !== null) {
        formValue[groupNm][index]["img_data"] = base64String;
        imageRefs.src = formValue[groupNm][index]["img_data"];
        formValue[groupNm][index]["img_size"] = newFiles.size;
        formValue[groupNm][index]["img_name"] = newFiles.name;
        formValue[groupNm][index]["img_exec"] = newFiles.name.split(".")[newFiles.name.split(".").length - 1];
        formValue[groupNm][index]["img_proc"] = "U";
      } else {
        formValue[groupNm]["img_data"] = base64String;
        imageRefs.src = formValue[groupNm]["img_data"];
        formValue[groupNm]["img_size"] = newFiles.size;
        formValue[groupNm]["img_name"] = newFiles.name;
        formValue[groupNm]["img_exec"] = newFiles.name.split(".")[newFiles.name.split(".").length - 1];
        formValue[groupNm]["img_proc"] = "U";
      }
      // 여기에서 base64String을 상태에 저장하거나 다른 처리를 수행할 수 있습니다.
    } catch (error) {
      console.error("Error converting file to base64!", error);
    }
  };

  const handleFileDelete = (index, groupNm) => {
    if (index !== undefined && index !== null) {
      delete formValue[groupNm][index]["img_data"];
      delete formValue[groupNm][index]["img_size"];
      delete formValue[groupNm][index]["img_name"];
      delete formValue[groupNm][index]["img_exec"];
      imageRefs.src = "";
      fileInputRefs.value = "";
      formValue[groupNm][index]["img_proc"] = "D";
    } else {
      delete formValue[groupNm]["img_data"];
      delete formValue[groupNm]["img_size"];
      delete formValue[groupNm]["img_name"];
      delete formValue[groupNm]["img_exec"];
      imageRefs.src = "";
      fileInputRefs.value = "";
      formValue[groupNm]["img_proc"] = "D";
    }
  };

  let img = getData(formValue, name, index, groupNm);

  return (
    <>
      <Row $gap="10px" $align="center">
        <IconImg src={img == null || img == '' ? "" : process.env.REACT_APP_IMG_URL + img}
          ref={(el) => (imageRefs = el)}>
        </IconImg>
        <input
          type="file"
          ref={(el) => (fileInputRefs = el)}
          onChange={handleFileChange(index, groupNm)}
          style={{ display: "none" }}
        />
        <ModifyBtn name="선택" eventProps={() => fileInputRefs.click()} />
        <DeleteBtn eventProps={() => handleFileDelete(index, groupNm)} />
      </Row>
    </>
  );
};

const SortForm = ({ colNm, sortNm, onSortClick }) => {
  let newSort = "";
  const handleChange = () => {
    if (colNm == null || colNm == "" || colNm != sortNm.split("-")[0]) {
      newSort = `${colNm}-desc`;
    } else {
      switch (sortNm) {
        case "":
          newSort = `${colNm}-desc`;
          break;
        case `${colNm}-desc`:
          newSort = `${colNm}-asc`;
          break;
        case `${colNm}-asc`:
          newSort = "";
          break;
      }
    }

    onSortClick(newSort);
  };

  return (
    <Column $gap="2px" onClick={handleChange}>
      <SortIcon
        className={sortNm.includes("-asc") && "active"}
        $url="/assets/svg/sort_up.svg"
        $width="18px"
        $height="8px"
      ></SortIcon>
      <SortIcon
        className={sortNm.includes("-desc") && "active"}
        $url="/assets/svg/sort_down.svg"
        $width="18px"
        $height="8px"
      ></SortIcon>
    </Column>
  );
};

export {
  RadioForm,
  SortForm,
  InputForm,
  NoLabelCheckForm,
  SelectForm,
  SelectForm2,
  GroupSelectForm,
  EachCheckForm,
  EachCheckFormCustom,
  ToggleButtonForm,
  TextareaForm,
  ImageForm,
  TextareaEditorForm,
};
