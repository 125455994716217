import React from "react";
import Routers from "routes/Routers";
import { RecoilRoot } from "recoil";

import { CommonWindowProvider } from "core/contexts/useWindow";
import { CommonStateProvider } from "core/contexts/useCommonState";
import { SWR } from "core/hooks/swr";

import GlobalStyle from "styles/GlobalStyle";
import SiteNameSetter from "components/common/SiteNameSetter";
import AlarmConfigSetter from "components/common/AlarmConfigSetter";

const SiteInfo = ({ children }) => {
  const info = localStorage.getItem("auth-admin");
  console.log(info);
  return (
    <>
      {!info && <>
        <SiteNameSetter />
        <AlarmConfigSetter />
      </>
      }
      {children}
    </>
  );
};

function App() {

  return (
    <>
      <GlobalStyle />
      <CommonStateProvider>
        <CommonWindowProvider>
          <RecoilRoot>
            <SWR>
              <SiteInfo>
                <Routers />
              </SiteInfo>
            </SWR>
          </RecoilRoot>
        </CommonWindowProvider>
      </CommonStateProvider>
    </>
  );
}

export default App;
