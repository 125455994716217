import styled from "styled-components";
import { MainBtn } from "styles/useButtonStyles";
import { IconLink } from "styles/useLayoutStyles";

const MainRadiusBtn = styled(MainBtn)`
  padding: 2px 6px;
  border-radius: 999px;
  min-width: auto;
  min-height: auto;
  height: fit-content;
  color: var(--c-white);
  font-size: var(--s-caption);
  line-height: var(--l-caption);
  font-family: var(--f-captionB);
`;

const SortIcon = styled(IconLink)`
  opacity: 0.5;
  &.active {
    opacity: 1;
  }
`;

export { SortIcon, MainRadiusBtn };
