// 공용
import api from "core/services/api";
import Pagination from "utils/Pagination";

import useTableData from "core/hooks/useTableData";
import SiteOperationalForm from "./SiteOperationalForm";

import { InputForm, SelectForm, SortForm } from "components/common/CommonForm";
import { useAlert, useConfirm, usePopup } from "core/contexts/useWindow";
import { getFormatDatetime } from "utils/date/DateUtil";
// 셀렉트 벨류 constant
import { useCountPerPage } from "constants/select/useValue";
// 공용 팝업
// style import
import useFormData from "core/hooks/useFormData";
import CalendarFilter from "components/common/CalendarFilter";
import NoDataTable from "components/common/NoDataTable";
import { DeleteBtn, ModifyBtn } from "components/common/TableStatus";
import { useCommonState } from "core/contexts/useCommonState";
import { isEmpty } from "lodash";
import { AreaHeader, Column, FilterBox, Row, SubTitleBox } from "styles/custom/useCustomLayout";
import { FilterTitle } from "styles/custom/useCustomText";
import { MainBtn, MenuToggleBtn, WhiteBtn } from "styles/useButtonStyles";
import { BorderBox, IconLink, PositionBox } from "styles/useLayoutStyles";
import { Table, TableDeps, TableHeader, TableRow } from "styles/useTableStyles";
import { AreaTitle, SubText, TextBox } from "styles/useTextStyles";
import useStateData from "core/hooks/useStateData";
import { t } from "i18next";
import SiteBannerMngForm from "./SiteBannerMngForm";
import { dbSiteBannerMng } from "constants/enum/db/useSiteEnum";
import { useState } from "react";

const SiteOperational = () => {
  const useStated = useStateData();
  const { setLoading } = useCommonState();

  const { openPopup } = usePopup();
  const { openConfirm } = useConfirm();
  const { openAlert } = useAlert();

  const [dropdownState, setDropdownState] = useState({});

  const showAlert = (item) => {
    openAlert({
      title: item ? t("popup.delete_complete") : t("popup.delete_fail"),
      message: item ? null : t("common.try_later"),
      iconURL: item ? false : true,
    });
  };

  const showConfirm = (no, uuid) => {
    openConfirm({
      title: t("popup.delete"),
      message: t("popup.delete_confirm"),
      iconURL: true,
      Func: () => deletePopup(no, uuid),
      subBtn: t("common.cancel"),
    });
  };

  const showPopup = (item) => {
    openPopup({
      title: item ? t("popup.modify") : t("popup.add"),
      content: SiteOperationalForm,
      props: {
        item,
        getTableItem,
      },
    });
  };

  const deletePopup = (no, uuid) => {
    setLoading(true);

    const apiUrl = `/v1/site/operational/popup/1/${no}/${uuid}`;
    api
      .delete(apiUrl)
      .then(() => {
        getTableItem();
        setLoading(false);
        showAlert(true);
      })
      .catch((err) => {
        setLoading(false);
        showAlert(false);
      });
  };

  const showBannerPopup = (item) => {
    openPopup({
      title: item ? t("common.modify") : t("common.add"),
      content: SiteBannerMngForm,
      props: {
        item,
        getTableItem: getBannerTableItem,
      },
    });
  };

  const showDeleteBannerConfirm = (no) => {
    openConfirm({
      title: t("banner.delete"),
      message: t("banner.delete_confirm"),
      iconURL: true,
      Func: () => deleteBanner(no),
      subBtn: t("common.cancel"),
    });
  };

  const showDeleteAlert = (item) => {
    openAlert({
      title: item ? t("banner.delete_complete") : t("banner.delete_fail"),
      message: item ? null : t("common.try_later"),
      iconURL: item ? false : true,
    });
  };

  const deleteBanner = (no) => {
    setLoading(true);

    const apiUrl = `/v1/site/operational/banners/${no}`;
    api
      .delete(apiUrl)
      .then(() => {
        getBannerTableItem();
        showDeleteAlert(true);
      })
      .catch((err) => {
        showDeleteAlert(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/site/operational/popup-list/1";
    apiType(apiUrl, {
      params: {
        ...useSearch.searchValue,
        ...useTG.extendData.pagingData,
        sortNm: useTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useTG.setError(true);
        setLoading(false);
        useTG.setData([]);
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors);
        }
      });
  };

  const useTG = useTableData(true);
  const useSearch = useFormData(
    {
      title: "",
      start_date: "",
      end_date: "",
    },
    getTableItem,
    useTG
  );

  const getBannerTableItem = () => {
    setLoading(true);

    const apiType = api.get;
    const apiUrl = "/v1/site/operational/banners";
    apiType(apiUrl, {
      params: {
        ...useBannerSearch.searchValue,
        ...useBannerTG.extendData.pagingData,
        sortNm: useBannerTG.extendData.sortNm,
      },
    })
      .then((res) => {
        useBannerTG.setData(res.data.content);
        if (res.data.pageHelperDto) {
          useBannerTG.setPagingInfo(res.data.pageHelperDto);
        }
        setLoading(false);
        useBannerTG.setError("");
      })
      .catch((err) => {
        console.error(err);
        useBannerTG.setError(true);
        setLoading(false);
        useBannerTG.setData([]);
      });
  };

  const useBannerTG = useTableData(true);
  const useBannerSearch = useFormData(
    {
      search_name: "",
      start_date: "",
      end_date: "",
    },
    getBannerTableItem,
    useBannerTG
  );

  const handleDropdownToggle = (state) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [state]: !prevState[state],
    }));
  };

  const openAllDropdowns = () => {
    setDropdownState({
      1: true,
      2: true,
    });
  };

  const closeAllDropdowns = () => {
    setDropdownState({
      1: false,
      2: false,
    });
  };
  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.site_config_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="28px"></IconLink>
        <AreaTitle>{t("menu.operational_config")}</AreaTitle>

        <Row $gap="8px" style={{ marginLeft: "auto" }}>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            $line="20px"
            onClick={openAllDropdowns}
          >
            {t("common.unfold_all_menu")}
          </MenuToggleBtn>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            onClick={closeAllDropdowns}
          >
            {t("common.fold_all_menu")}
          </MenuToggleBtn>
        </Row>
      </AreaHeader>

      <Column $gap="64px">
        <Column>
          <SubTitleBox onClick={() => handleDropdownToggle(1)} $cursor="pointer">
            <Row $gap="6px" $align="center">
              <AreaTitle>1. {t("popup.popup_config")}</AreaTitle>
            </Row>

            <BorderBox>
              <IconLink
                className={dropdownState[1] && "active"}
                $trans="rotate(-180deg)"
                $url="/assets/svg/arrow_toggle.svg"
                $width="32px"
                $height="32px"
              ></IconLink>
            </BorderBox>
          </SubTitleBox>
          {dropdownState[1] && (
            <Column $mar="16px 0 0 0">
              <FilterBox $pad="12px">
                <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
                  <Column $gap="4px">
                    <FilterTitle>{t("popup.title")}</FilterTitle>
                    <Row $gap="4px">
                      <InputForm type="text" name="title" placeholder={t("common.input")} {...useSearch} />
                    </Row>
                  </Column>

                  <Column $gap="4px">
                    <FilterTitle>{t("popup.submit_at")}</FilterTitle>
                    <Row $gap="4px">
                      <CalendarFilter endName="end_date" date_period="date1" startName="start_date" {...useSearch} end />
                    </Row>
                  </Column>
                </Row>
                <Row $gap="8px">
                  <MainBtn $width="61px" $height="34px" onClick={() => useSearch.formSearch()}>
                    {t("filter.search")}
                  </MainBtn>
                  <WhiteBtn $width="61px" $height="34px" onClick={() => useSearch.resetForm()}>
                    {t("filter.refresh")}
                  </WhiteBtn>
                </Row>
              </FilterBox>

              <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
                <Row $gap="10px" $align="center">
                  <Row className="total-count" $gap="4px">
                    <TextBox $font="var(--f-subB)">{t("common.total")}</TextBox>
                    <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                      {useTG.error ? <span>error</span> : <>{useTG && useTG.pagingInfo ? useTG.pagingInfo.totalRecords?.toLocaleString() : 0}</>}
                    </TextBox>
                    <TextBox $font="var(--f-subB)">{t("common.count")}</TextBox>
                  </Row>
                </Row>

                <Row $gap="8px">
                  <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
                    {t("popup.add")}
                  </MainBtn>
                  <SelectForm
                    {...useTG}
                    name="countPerPage"
                    placeholder={useCountPerPage[0].label}
                    size={"var(--s-table)"}
                    line={"var(--l-table)"}
                    options={useCountPerPage}
                  />
                </Row>
              </Row>

              {useTG.error ? (
                <span>error</span>
              ) : (
                <>
                  <Table className="table-box">
                    <thead>
                      <tr className="table-title">
                        <TableHeader $width="60px">{t("common.no")}</TableHeader>
                        <TableHeader>{t("popup.title")}</TableHeader>
                        <TableHeader>{t("popup.submit_at")}</TableHeader>
                        <TableHeader>{t("popup.publish_at")}</TableHeader>
                        <TableHeader $width="200px">{t("common.manage")}</TableHeader>
                      </tr>
                    </thead>
                    {useTG && useTG.data?.length > 0 && (
                      <tbody>
                        {useTG.data &&
                          useTG.data.map((item, index) => {
                            const number = useTG.pagingInfo.offset - index;
                            return (
                              <TableRow key={number} className="table-content">
                                <TableDeps $width="60px">{number || "-"}</TableDeps>
                                <TableDeps $tAlign="left">
                                  {item?.title?.length > 20 ? `${item.title.slice(0, 20)}...` : `${item.title}` || "-"}
                                </TableDeps>
                                <TableDeps>{item?.create_at ? getFormatDatetime(item.create_at) : "-"}</TableDeps>
                                <TableDeps>
                                  {item?.publish_start && item?.publish_end ? (
                                    <>
                                      <TextBox as="p">
                                        {getFormatDatetime(item.publish_start)} {t("popup.from")}
                                      </TextBox>
                                      <TextBox as="p">
                                        {getFormatDatetime(item.publish_end)} {t("popup.at")}
                                      </TextBox>
                                    </>
                                  ) : (
                                    t("popup.no_limit")
                                  )}
                                </TableDeps>
                                <TableDeps $width="120px">
                                  <Row $width="100%" $jus="center" $gap="6px">
                                    {item.no ? (
                                      <>
                                        <ModifyBtn eventProps={() => showPopup(item.no)} />
                                        <DeleteBtn eventProps={() => showConfirm(item.no, item.img_uuid)} />
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </Row>
                                </TableDeps>
                              </TableRow>
                            );
                          })}
                      </tbody>
                    )}
                  </Table>
                  {useTG && useTG.data?.length === 0 && <NoDataTable />}
                </>
              )}

              <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
                <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                  {!useTG.loading && !isEmpty(useTG.pagingInfo) && <Pagination {...useTG} />}
                </PositionBox>

                <Row $gap="8px">
                  <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showPopup()}>
                    {t("popup.add")}
                  </MainBtn>
                  <SelectForm
                    {...useTG}
                    name="countPerPage"
                    placeholder={useCountPerPage[0].label}
                    size={"var(--s-table)"}
                    line={"var(--l-table)"}
                    options={useCountPerPage}
                  />
                </Row>
              </Row>
            </Column>
          )}
        </Column>

        <Column>
          <SubTitleBox onClick={() => handleDropdownToggle(2)} $cursor="pointer">
            <Row $gap="6px" $align="center">
              <AreaTitle>2. {t("banner.regis")}</AreaTitle>
            </Row>
            <BorderBox>
              <IconLink
                className={dropdownState[2] && "active"}
                $trans="rotate(-180deg)"
                $url="/assets/svg/arrow_toggle.svg"
                $width="32px"
                $height="32px"
              ></IconLink>
            </BorderBox>
          </SubTitleBox>

          {dropdownState[2] && (
            <Column $mar="16px 0 0 0">
              <FilterBox $pad="12px">
                <Row $gap="12px" $align="flex-start" $flexWrap="wrap">
                  <Column $gap="4px">
                    <FilterTitle>베너 명</FilterTitle>
                    <Row $gap="4px">
                      <InputForm type="text" name="search_name" placeholder="입력" {...useBannerSearch} />
                    </Row>
                  </Column>

                  <Column $gap="4px">
                    <FilterTitle>등록 일시</FilterTitle>
                    <Row $gap="4px">
                      <CalendarFilter endName="end_date" date_period="date1" startName="start_date" {...useBannerSearch} end />
                    </Row>
                  </Column>
                </Row>
                <Row $gap="8px">
                  <MainBtn $width="61px" $height="34px" onClick={() => useBannerSearch.formSearch()}>
                    검색
                  </MainBtn>
                  <WhiteBtn $width="61px" $height="34px" onClick={() => useBannerSearch.resetForm()}>
                    초기화
                  </WhiteBtn>
                </Row>
              </FilterBox>

              <Row $jus="space-between" $width="100%" $align="center" $pad="20px 0 14px">
                <Row $gap="10px" $align="center">
                  <Row className="total-count" $gap="4px">
                    <TextBox $font="var(--f-subB)">총</TextBox>
                    <TextBox $color="var(--c-mint-600)" $font="var(--f-subB)">
                      {useBannerTG.error ? (
                        <span>error</span>
                      ) : (
                        <>{useBannerTG && useBannerTG.pagingInfo ? useBannerTG.pagingInfo.totalRecords : 0}</>
                      )}
                    </TextBox>
                    <TextBox $font="var(--f-subB)">건</TextBox>
                  </Row>
                </Row>

                <Row $gap="8px">
                  <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showBannerPopup()}>
                    {t("banner.add")}
                  </MainBtn>
                  <SelectForm
                    {...useBannerTG}
                    name="countPerPage"
                    placeholder={useCountPerPage[0].label}
                    size={"var(--s-table)"}
                    line={"var(--l-table)"}
                    options={useCountPerPage}
                  />
                </Row>
              </Row>

              {useBannerTG.error ? (
                <span>error</span>
              ) : (
                <>
                  <Table className="table-box">
                    <thead>
                      <tr className="table-title">
                        <TableHeader $width="60px">{t("common.no")}</TableHeader>
                        <TableHeader>{t("banner.banner_no")}</TableHeader>
                        <TableHeader>{t("banner.name")}</TableHeader>
                        <TableHeader $width="200px">
                          <Row $gap="2px" $jus="center" $align="center">
                            <TextBox $size="var(--s-table)" $line="var(--l-table)" $font="var(--f-tableB)">
                              {t("banner.create_at")}
                            </TextBox>
                            <SortForm
                              colNm="create_at"
                              sortNm={useBannerTG.extendData.sortNm}
                              onSortClick={useBannerTG.onSortClick}
                            />
                          </Row>
                        </TableHeader>
                        <TableHeader $width="160px">{t("banner.display_type")}</TableHeader>
                        <TableHeader $width="160px">{t("banner.use_yn")}</TableHeader>
                        <TableHeader $width="150px">{t("common.manage")}</TableHeader>
                      </tr>
                    </thead>
                    {useBannerTG && useBannerTG.data?.length > 0 && (
                      <tbody>
                        {useBannerTG.data &&
                          useBannerTG.data.map((item, index) => {
                            const number = useBannerTG.pagingInfo.offset - index;
                            return (
                              <TableRow key={number} className="table-content">
                                <TableDeps $width="60px">{number || "-"}</TableDeps>
                                <TableDeps $width="60px">{item?.banner_no || "-"}</TableDeps>

                                <TableDeps $tAlign="left">
                                  {item?.name?.length > 110 ? `${item.name.slice(0, 110)}...` : `${item.name}` || "-"}
                                </TableDeps>
                                <TableDeps>{item?.create_at ? getFormatDatetime(item.create_at) : "-"}</TableDeps>
                                <TableDeps>
                                  {[item?.use_yn_pc === "Y" && t("common.web"), item?.use_yn_mobile === "Y" && t("common.mobile")]
                                    .filter(Boolean)
                                    .join(", ")}
                                </TableDeps>
                                <TableDeps>{dbSiteBannerMng.use_yn[item?.use_yn]?.label}</TableDeps>

                                <TableDeps $width="120px">
                                  <Row $width="100%" $jus="center" $gap="6px">
                                    {item.no ? (
                                      <>
                                        <ModifyBtn eventProps={() => showBannerPopup(item.no)} />
                                        <DeleteBtn eventProps={() => showDeleteBannerConfirm(item.no)} />
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </Row>
                                </TableDeps>
                              </TableRow>
                            );
                          })}
                      </tbody>
                    )}
                  </Table>
                  {useBannerTG && useBannerTG.data?.length === 0 && <NoDataTable />}
                </>
              )}

              <Row $gap="8px" $pad="14px 0 0" $align="center" $jus="flex-end" $width="100%">
                <PositionBox $position="absolute" $left="50%" $trans="translateX(-50%)">
                  {!useBannerTG.loading && !isEmpty(useBannerTG.pagingInfo) && <Pagination {...useBannerTG} />}
                </PositionBox>

                <Row $gap="8px">
                  <MainBtn $size="var(--s-table)" $line="var(--l-table)" onClick={() => showBannerPopup()}>
                    {t("banner.add")}
                  </MainBtn>
                  <SelectForm
                    {...useBannerTG}
                    name="countPerPage"
                    placeholder={useCountPerPage[0].label}
                    size={"var(--s-table)"}
                    line={"var(--l-table)"}
                    options={useCountPerPage}
                  />
                </Row>
              </Row>
            </Column>
          )}
        </Column>
      </Column>
    </>
  );
};

export default SiteOperational;
