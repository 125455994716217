import api from "core/services/api";

import { default as useSWR, SWRConfig } from "swr";
import { useCommonState } from "core/contexts/useCommonState";
/**
 * SWR 설정
 * @param {object} children - 자식 컴포넌트
 * @returns {object} SWRConfig - SWR 설정
 */
export const SWR = ({ children }) => {
  const info = localStorage.getItem("auth-admin");
  const { setLoading } = useCommonState();

  const fetcher = async (url) => {
    setLoading(true);
    try {
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  }

  const { data, error } = useSWR(info ? '/v1/admin/session' : null, fetcher);
  if (data) console.log(data.content.message);
  if (error) console.log(error.response.data.errors);

  return (
    <SWRConfig value={{ 
      fetcher,
      shouldRetryOnError: false,            // 에러 발생시 재시도 여부
    }}>
      {children}
    </SWRConfig>
  )
}
