import api from "core/services/api";
import React, { useEffect, useState } from "react";
import { Column, Row } from "styles/custom/useCustomLayout";
import { FilterTitle } from "styles/custom/useCustomText";
import { SelectForm, GroupSelectForm, SelectForm2 } from "./CommonForm";
import { t } from "i18next";

const GameSearchFilter = ({ useSearch, useStated, gameType = null }) => {

  const [type, setType] = useState([])
  useEffect(() => {
    if (type.length) {
      getVendor();
      getGame();
    }
  }, [type])

  const selectType = (selectType) => {
    if (selectType) {
      useSearch.setFormValue({
        ...useSearch.formValue,
        search_gmae_name: '',
        search_vendor: '',
      });

      const labelType = t(type.find(item => item.value == selectType).label)
      setVendor({
        [labelType]: allVendor[labelType]
      })
    } else {
      setVendor(allVendor)
    }
  }

  const [vendor, setVendor] = useState([]);

  const [allVendor, setAllVendor] = useState([]);
  const [game, setGame] = useState([]);
  const [allGame, setAllGame] = useState([]);



  const selectVendor = (vendor) => {
    if (vendor) {
      setGame(
        allGame.filter((data) => {
          return data.vendor == vendor
        })
      )
    } else {
      setGame(allGame)
    }
  }
  // 게임사 조회
  const getVendor = () => {
    api.get("/v1/game/vendor", {
      params: {
        game_type: gameType
      }
    })
      .then((res) => {
        let result = {};
        res.data.forEach(item => {
          const typeKey = `${t(type.find(item2 => item2.value == item.type).label)}`;
          // 해당 타입 키가 없으면 새 객체 생성
          if (!result[typeKey]) {
            result[typeKey] = {};
          }

          // name을 키로 객체 저장
          result[typeKey][item.name] = null
        });
        setVendor(result)
        setAllVendor(result)
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  // 게임타입
  const getType = () => {
    api.get("/v1/game/gameType")
      .then((res) => {
        setType([{ label: t("common.all"), value: "" }, ...res.data])
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      })

  };

  // 게임 조회
  const getGame = () => {
    api.get("/v1/game/game-list", {
      params: {
        game_type: gameType
      }
    })
      .then((res) => {
        const agentName = res.data.reduce((acc, item) => {
          acc.push({
            label: item.title,
            value: item.title,
            vendor: item.vendor
          });
          return acc;
        }, []);

        setGame(agentName)
        setAllGame(agentName)
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
      });
  };

  useEffect(() => {
    getType();
  }, []);

  useEffect(() => {
    selectType(useSearch.formValue.search_game_type)
    useSearch.setFormValue({
      ...useSearch.formValue,
      search_vendor: "",
      search_gmae_name: "",
    });
  }, [useSearch.formValue.search_game_type])

  useEffect(()=>{
      useSearch.setFormValue({
        ...useSearch.formValue,
        search_vendor: useSearch.formValue.search_vendor,
        search_gmae_name: "",
      });
      selectVendor(useSearch.formValue.search_vendor); // 선택된 벤더로 필터링
  },[useSearch.formValue.search_vendor])

  return (
    <Row $mar="0 24px 0 0" $gap="12px">
      <Row $gap="4px">
        {
          !gameType && <Column $gap="4px">
            <FilterTitle>{t("menuCategory.type")}</FilterTitle>
            <SelectForm
              name="search_game_type"
              placeholder={t("menuCategory.type")}
              options={type}
              {...useSearch}
              {...useStated}
              width={'150px'}
            />
          </Column>
        }
        <Column $gap="4px">
          <FilterTitle>{t("gameManagement.game_vendor")}</FilterTitle>
          <GroupSelectForm
            name="search_vendor"
            options={vendor}
            {...useSearch}
            {...useStated}
          />

        </Column>
        <Column $gap="4px">
          <FilterTitle>{t("gameManagement.game_name")}</FilterTitle>
          <SelectForm2
            name="search_gmae_name"
            placeholder={t("gameManagement.game_name")}
            options={game}
            {...useSearch}
            {...useStated}
            width={'350px'}
          />
        </Column>
      </Row>
    </Row>
  );
};

export default GameSearchFilter;
