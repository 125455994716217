import React, { useEffect, useState } from "react";

// 공용
import api from "core/services/api";

import { useAlert, useConfirm, usePopup } from "core/contexts/useWindow";
// style import
import { MainBtn, MenuToggleBtn, WhiteBtn } from "styles/useButtonStyles";
import { BorderBox, IconLink } from "styles/useLayoutStyles";
import { AreaTitle, SubText, TextBox } from "styles/useTextStyles";
import { AreaHeader, Column, FormBox, Row, SubTitleBox } from "styles/custom/useCustomLayout";
import { dbAdmin } from "constants/enum/db/useAdminEnum";
import { InputForm, TextareaForm, ToggleButtonForm } from "components/common/CommonForm";
import useFormData from "core/hooks/useFormData";
import useStateData from "core/hooks/useStateData";
import { useCommonState } from "core/contexts/useCommonState";
import { t } from "i18next";

const AccountConfig = () => {
  const useStated = useStateData();
  const { setLoading, isAdmin } = useCommonState();
  const { openAlert } = useAlert();
  const [dropdownState, setDropdownState] = useState({});


  const showSuccessAlert = () => {
    const title = t("popup.popup_modify_complete_msg");
    openAlert({
      title,
      iconURL: false, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const showBlockStateFailAlert = () => {
    const title = t("popup.popup_modify_fail_msg");
    openAlert({
      title,
      message: t("common.try_later"),
      iconURL: true, // 경고성 아이콘 true - 알림성 아이콘 false
      mainBtn: t("common.check"),
    });
  };

  const getTableItem = () => {
    setLoading(true);

    const apiUrl = `/v1/admin/account`;

    api
      .get(apiUrl, {
        params: {
          ...useSearch.useSearch,
        },
      })
      .then((res) => {
        useSearch.setFormValue(res.data.content);
      })
  };


  const useSearch = useFormData(
    {
      // 1. 계정 설정
      nickname: "",
      password: "",
      re_password: "",
      allow_ip_yn: "",
      ip_arr: "",

      // 2. 상단 통계 설정
      total_user_YN: "Y",
      today_user_YN: "Y",
      today_charge_YN: "Y",
      today_withdrawal_YN: "Y",
      today_charge_withdrawal_YN: "Y",
      deposit_request_YN: "Y",
      withdrawal_request_YN: "Y",
      join_request_YN: "Y",
      inquiry_YN: "Y",

    },
  );

  useEffect(() => {
    getTableItem();
  }, []);

  const handleDropdownToggle = (state) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [state]: !prevState[state],
    }));
  };

  const openAllDropdowns = () => {
    setDropdownState({
      1: true,
    });
  };

  const closeAllDropdowns = () => {
    setDropdownState({
      1: false,
    });
  };

  const checkDuplicated = (key) => {
    const apiUrl = `/v1/agent/info/duplicate/${key}`;

    const data = {
      ...useSearch.formValue,
    };
    api
      .get(apiUrl, {
        params: data,
      })
      .then((res) => {
        useStated.setSuccessData([res.data.content]);
      })
      .catch((err) => {
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });
  };

  const saveConfig1 = () => {
    const apiUrl = `/v1/admin/account`;

    api
      .post(apiUrl, {
        nickname: useSearch.formValue.nickname,
        password: useSearch.formValue.password,
        re_password: useSearch.formValue.re_password,
        allow_ip_yn: useSearch.formValue.allow_ip_yn,
        ip_arr: useSearch.formValue.ip_arr,
      })
      .then((res) => {
        showSuccessAlert();
        useStated.setSuccessData([], true);
        getTableItem()
      })
      .catch((err) => {
        showBlockStateFailAlert()
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });

  };

  const saveConfig2 = () => {
    const apiUrl = `/v1/admin/top-statistics`;

    api
      .post(apiUrl, {
        total_user_YN: useSearch.formValue.total_user_YN || 'Y',
        today_user_YN: useSearch.formValue.today_user_YN || 'Y',
        today_charge_YN: useSearch.formValue.today_charge_YN || 'Y',
        today_withdrawal_YN: useSearch.formValue.today_withdrawal_YN || 'Y',
        today_charge_withdrawal_YN: useSearch.formValue.today_charge_withdrawal_YN || 'Y',
        deposit_request_YN: useSearch.formValue.deposit_request_YN || 'Y',
        withdrawal_request_YN: useSearch.formValue.withdrawal_request_YN || 'Y',
        join_request_YN: useSearch.formValue.join_request_YN || 'Y',
        inquiry_YN: useSearch.formValue.inquiry_YN || 'Y',
      })
      .then((res) => {
        showSuccessAlert();
        useStated.setSuccessData([], true);
        getTableItem()
      })
      .catch((err) => {
        showBlockStateFailAlert()
        if (err.response?.status === 400) {
          useStated.setErrorData(err.response.data.errors, true);
        }
        console.error(err);
      });

  };

  return (
    <>
      <AreaHeader>
        <SubText>{t("menu.site_config_manager")}</SubText>
        <IconLink $url="/assets/svg/next_arrow.svg" $width="14px" $height="14px"></IconLink>
        <AreaTitle>{t("menu.account_config")}</AreaTitle>

        <Row $gap="8px" style={{ marginLeft: "auto" }}>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            $line="20px"
            onClick={openAllDropdowns}
          >
            {t("common.unfold_all_menu")}

          </MenuToggleBtn>
          <MenuToggleBtn
            $bcolor="var(--c-gray-300)"
            $size="var(--s-table)"
            $font="var(--f-table)"
            $height="28px"
            onClick={closeAllDropdowns}
          >
            {t("common.fold_all_menu")}
          </MenuToggleBtn>
        </Row>
      </AreaHeader>
      <Column $gap="64px">
        {/* 1. 계정 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(1)} $cursor="pointer">
            <Row $gap="6px" $align="center">
              <AreaTitle>1. {t("account.account_config")}</AreaTitle>
            </Row>

            <Row $gap="6px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig1();
                }}
              >
                {t("common.go_save")}
              </MainBtn>

              <BorderBox>
                <IconLink
                  className={dropdownState[1] && "active"}
                  $trans="rotate(-180deg)"
                  $url="/assets/svg/arrow_toggle.svg"
                  $width="32px"
                  $height="32px"
                ></IconLink>
              </BorderBox>
            </Row>

          </SubTitleBox>
          {dropdownState[1] && (
            <Column $mar="16px 0 0 0" $gap="16px">

              <Row $gap="6px">
                <FormBox $align="flex-start">
                  <TextBox $width="120px" $minWidth="120px">
                    {t("admin.nickname")}
                  </TextBox>

                  <Column $gap="4px" $width="100%">
                    <Row $gap="8px" $width="100%">
                      <InputForm maxWidth="100%" type="text" name="nickname" {...useSearch} {...useStated} />
                      <WhiteBtn
                        $minWidth="80px"
                        $height="30px"
                        $size="var(--s-table)"
                        $line="var(--l-table)"
                        onClick={() => checkDuplicated("admin-nickname")}
                      >
                        {t("common.check_duplicate")}
                      </WhiteBtn>
                    </Row>
                    <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                      * 4~50 {t("common.valid_length")}
                    </TextBox>
                  </Column>
                </FormBox>
              </Row>

              <Row $gap="6px">
                <FormBox $flex="1">
                  <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                    {t("common.password")} <TextBox $color="red">*</TextBox>
                  </TextBox>
                  <InputForm maxWidth="100%" type="password" name="password" {...useSearch} {...useStated} />
                </FormBox>
              </Row>


              <Row $gap="6px">
                <FormBox $flex="1">
                  <TextBox $width="120px" $minWidth="120px" $line="var(--l-sub)">
                    {t("common.re_password")} <TextBox $color="red">*</TextBox>
                  </TextBox>
                  <InputForm maxWidth="100%" type="password" name="re_password" {...useSearch} {...useStated} />
                </FormBox>
              </Row>

              <Row $gap="6px">
                <FormBox $align="flex-start">
                  <TextBox $width="120px" $minWidth="120px">
                    {t("admin.block_ip")}
                  </TextBox>

                  <ToggleButtonForm name="allow_ip_yn" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                </FormBox>
              </Row>

              <Row $gap="6px">
                <FormBox $align="flex-start">
                  <TextBox $width="120px" $minWidth="120px">
                    {t("admin.regis_ip")}
                  </TextBox>
                  <Column $width="100%" $gap="4px">
                    <TextareaForm
                      type="text"
                      name="ip_arr"
                      placeholder={t("admin.regis_ip_plh")}
                      {...useSearch}
                      {...useStated}
                    />
                    <TextBox $size="var(--s-caption)" $color="var(--c-gray-500)" $line="var(--l-caption)">
                      {t("admin.regis_msg")}
                    </TextBox>
                  </Column>
                </FormBox>
              </Row>

            </Column>
          )}
        </Column>

        {/* 2. 상단 통계 설정 */}
        <Column className="area-box">
          <SubTitleBox onClick={() => handleDropdownToggle(2)} $cursor="pointer">
            <Row $gap="6px" $align="center">
              <AreaTitle>2. {t("account.top_statistics_config")}</AreaTitle>
            </Row>

            <Row $gap="6px">
              <MainBtn
                $pad="6px 12px"
                onClick={(e) => {
                  e.stopPropagation(); // 저장하기 누를땐 탭이 접히지 않게
                  saveConfig2();
                }}
              >
                {t("common.go_save")}
              </MainBtn>

              <BorderBox>
                <IconLink
                  className={dropdownState[2] && "active"}
                  $trans="rotate(-180deg)"
                  $url="/assets/svg/arrow_toggle.svg"
                  $width="32px"
                  $height="32px"
                ></IconLink>
              </BorderBox>
            </Row>

          </SubTitleBox>
          {dropdownState[2] && (
            <Column $mar="16px 0 0 0" $gap="16px">
              {
                // 어드민 경우
                isAdmin ?
                  <>
                    {/* 전체회원 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.total_user")}
                        </TextBox>
                        <ToggleButtonForm name="total_user_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 금일 가입자 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_user")}
                        </TextBox>
                        <ToggleButtonForm name="today_user_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 금일 충전 금액 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_charge")}
                        </TextBox>
                        <ToggleButtonForm name="today_charge_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 금일 환전 금액 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_withdrawal")}
                        </TextBox>
                        <ToggleButtonForm name="today_withdrawal_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 금일 출-환 금액 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_charge_withdrawal")}
                        </TextBox>
                        <ToggleButtonForm name="today_charge_withdrawal_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 입금 요청 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.deposit_request")}
                        </TextBox>
                        <ToggleButtonForm name="deposit_request_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 출금 요청 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.withdrawal_request")}
                        </TextBox>
                        <ToggleButtonForm name="withdrawal_request_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 가입 요청 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.join_request")}
                        </TextBox>
                        <ToggleButtonForm name="join_request_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 문의 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.inquiry")}
                        </TextBox>
                        <ToggleButtonForm name="inquiry_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>
                  </>
                  :
                  // 에이전트 경우
                  <>
                    {/* 전체회원 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.total_user")}
                        </TextBox>
                        <ToggleButtonForm name="total_user_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>
                    {/* 금일 가입자 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_user")}
                        </TextBox>
                        <ToggleButtonForm name="today_user_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 금일 충전 금액 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_charge")}
                        </TextBox>
                        <ToggleButtonForm name="today_charge_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 금일 환전 금액 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_withdrawal")}
                        </TextBox>
                        <ToggleButtonForm name="today_withdrawal_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>

                    {/* 금일 충-환 금액 */}
                    <Row $gap="6px">
                      <FormBox $align="flex-start">
                        <TextBox $width="120px" $minWidth="120px">
                          {t("account.today_charge_withdrawal")}
                          
                        </TextBox>
                        <ToggleButtonForm name="today_charge_withdrawal_YN" options={dbAdmin.allow_ip_yn} {...useSearch} {...useStated} />
                      </FormBox>
                    </Row>
                  </>
              }
            </Column>
          )}
        </Column>
      </Column>
    </>
  );
};

export default AccountConfig;
